/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SearchFeedsRequest,
  SearchFeedsResponse,
  SearchMessageResponse,
  SearchMessagesRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class WorkspaceId<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name FeedsCreate
   * @summary searchFeeds
   * @request POST:/{workspaceId}/search/feeds
   */
  feedsCreate = (workspaceId: string, body: SearchFeedsRequest, params: RequestParams = {}) =>
    this.request<SearchFeedsResponse, any>({
      path: `/${workspaceId}/search/feeds`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name MessagesCreate
   * @summary searchMessages
   * @request POST:/{workspaceId}/search/messages
   */
  messagesCreate = (workspaceId: string, body: SearchMessagesRequest, params: RequestParams = {}) =>
    this.request<SearchMessageResponse, any>({
      path: `/${workspaceId}/search/messages`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
