import { createContext, useState, useRef, MutableRefObject } from "react";
import { VListHandle } from "virtua";
import { SetStateType } from "@/utils";

type FeedStatusMessage = {
  message: string;
  severity: "error" | "success" | "info";
};

export type FeedState = {
  vListRef?: MutableRefObject<VListHandle>;
  fileUploadModal?: boolean;
  status?: FeedStatusMessage;
  templatesModal?: boolean;
  itemTranscriptState?: Record<string, boolean>;
  itemMessageDetailsState?: Record<string, boolean>;
  scrollToBottomOfFeed?: () => void;
  setFileUploadModal: SetStateType<boolean>;
  setItemMessageDetailsState?: SetStateType<Record<string, boolean>>;
  setItemTranscriptState: SetStateType<Record<string, boolean>>;
  setTemplatesModal: SetStateType<boolean>;
  setStatus: SetStateType<FeedStatusMessage>;
};

export const FeedContext = createContext<FeedState>({
  scrollToBottomOfFeed: () => { },
  setFileUploadModal: () => { },
  setTemplatesModal: () => { },
  setItemMessageDetailsState: () => { },
  setItemTranscriptState: () => { },
  setStatus: () => { },
});

const FeedContextProvider = ({ children }) => {
  // context use to persist state in the feed virtualized list until refactor occurs
  const [templatesModal, setTemplatesModal] = useState<boolean>(false);
  const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);
  const [itemTranscriptState, setItemTranscriptState] = useState<
    Record<string, boolean>
  >({});
  const [itemMessageDetailsState, setItemMessageDetailsState] = useState<
    Record<string, boolean>
  >({});
  const [status, setStatus] = useState<FeedStatusMessage>(null);
  const vListRef = useRef<VListHandle>(null);

  const scrollToBottomOfFeed = () => {
    if (vListRef?.current) {
      vListRef.current?.scrollTo(vListRef.current?.scrollSize);
    }
  };

  const feedState: FeedState = {
    vListRef,
    fileUploadModal,
    status,
    templatesModal,
    itemTranscriptState,
    itemMessageDetailsState,
    scrollToBottomOfFeed,
    setItemMessageDetailsState,
    setItemTranscriptState,
    setFileUploadModal,
    setTemplatesModal,
    setStatus,
  };
  return (
    <FeedContext.Provider value={feedState}>{children}</FeedContext.Provider>
  );
};

export default FeedContextProvider;
