import CrownIcon from "@/components/Icons/Crown";
import ModalForm from "@/elements/ModalForm";
import { useContext, useState } from "react";
import BasicButtonStyles from "@/theme/BasicButtonStyles";
import { AccountInfo } from "@/models/accountInfo";
import { Feed } from "@/generated/client";
import { Box, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import Locator from "@/locator";
export default function DemoteOrPromoteMember({
  account,
  feed,
  closeContext,
}: {
  account: AccountInfo;
  feed: Feed;
  closeContext: () => void;
}) {
  const { demoteOwnerToMember, promoteMemberToOwner } =
    useContext(ActionContext);
  const { getWorkspaceRoleLabel } = useContext(DataContext);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [promoteMember, setPromoteMember] = useState<boolean>(false);
  const [demoteMember, setDemoteMember] = useState<boolean>(false);
  const { normalButtonStyles, normalIconStyles } = BasicButtonStyles();
  const handleAction = async (action: string) => {
    setDisabled(() => true);
    if (action === "demote") {
      await demoteOwnerToMember(
        feed.workspaceId,
        feed.id,
        account.id,
        account.workspaceMembership.id,
      );
    } else {
      await promoteMemberToOwner(feed.workspaceId, feed.id, account.id);
    }
    setDisabled(() => false);
    closeContext();
  };

  return (
    <>
      <ModalForm
        id={`${account.id}-demote-member`}
        open={demoteMember}
        onClose={() => {
          setDemoteMember(() => false);
          closeContext();
        }}
      >
        <Box>
          <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
            Demote to channel member
          </Typography>
          <Typography sx={{ fontSize: 16, mb: 2 }}>
            This owner will no longer be able to manage this channel’s settings
            or post in read-only mode. They will remain a member of the channel.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2.5,
            }}
          >
            <Button
              disabled={disabled}
              variant="outlined"
              color="primary"
              onClick={() => closeContext()}
              aria-label={Locator.feed.members.cancelDemote}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={disabled}
              variant="contained"
              color="error"
              sx={{ borderRadius: 6 }}
              onClick={() => handleAction("demote")}
              aria-label={Locator.feed.members.confirmDemote}
            >
              Demote
            </LoadingButton>
          </Box>
        </Box>
      </ModalForm>

      <ModalForm
        id={`${account.id}-promote-member`}
        open={promoteMember}
        onClose={() => {
          setPromoteMember(() => false);
          closeContext();
        }}
      >
        <Box>
          <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
            Promote to owner
          </Typography>
          <Typography sx={{ fontSize: 16, mb: 2 }}>
            This member will be able to manage this channel’s settings and
            continue to post when in read-only mode.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2.5,
            }}
          >
            <LoadingButton
              disabled={disabled}
              variant="contained"
              sx={{ borderRadius: 6 }}
              onClick={() => handleAction("promote")}
              aria-label={Locator.feed.members.confirmPromote}
            >
              Promote
            </LoadingButton>
            <Button
              disabled={disabled}
              aria-label={Locator.feed.members.cancelPromote}
              variant="outlined"
              color="primary"
              onClick={() => closeContext()}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </ModalForm>

      {account?.isAdmin ? (
        <Button
          sx={normalButtonStyles}
          aria-label={Locator.feed.members.list.context.demote}
          onClick={() => setDemoteMember(() => true)}
        >
          <Box sx={normalIconStyles}>
            <CrownIcon
              aria-label="Demote from Owner"
              fill="#fff"
              name="Feed Admin"
            />
          </Box>
          <Box>Demote to channel member</Box>
        </Button>
      ) : (
        <Button
          sx={normalButtonStyles}
          aria-label={Locator.feed.members.list.context.promote}
          onClick={() => setPromoteMember(() => true)}
        >
          <Box sx={normalIconStyles}>
            <CrownIcon
              aria-label="Promote to Owner"
              fill="#fff"
              name="Feed Admin"
            />
          </Box>
          <Box>Promote to owner</Box>
        </Button>
      )}
    </>
  );
}
