import { Electric } from "@/generated/client";
import { FeedWithStatus } from "@/models/types";
import cuid from "cuid";
import { Account, AccountEvent, Item } from "web-client/api/data-contracts";
import {
	AppPhoneNumber,
	UserInfoResponse,
} from "web-client/auth/data-contracts";

export async function upsertAccountEvent(db: Electric["db"], e: AccountEvent) {
	try {
		await db.account_event.create({
			data: {
				id: cuid(),
				accountId: e.accountId,
				feedId: e.feedId,
				createdAt: e.createdAt,
				name: e.name,
				contentId: e.uploadId,
				itemId: e.feedItemId,
			},
		});
	} catch (e) {
		console.error("Error upserting account event", e);
		let existing;
		let attempts = 0;
		while (!existing && attempts < 3) {
			attempts++;
			existing = await db.account_event.findFirst({
				where: {
					accountId: e.accountId,
					name: e.name,
				},
			});
			if (!existing) continue;
			await db.account_event.update({
				where: {
					id: existing.id,
				},
				data: {
					accountId: e.accountId,
					feedId: e.feedId,
					createdAt: e.createdAt,
					name: e.name,
					contentId: e.uploadId,
					itemId: e.feedItemId,
				},
			});
		}
	}
}

export async function upsertFeed(db: Electric["db"], feed: FeedWithStatus) {
	const newFeed = db.feed.upsert({
		where: {
			id: feed.id,
		},
		create: {
			id: feed.id,
			createdAt: feed.createdAt,
			updatedAt: feed.latestActivity,
			readOnly: feed.readOnly ? 1 : 0,
			loadingStatus: feed.loadingStatus,
			feedType: feed.feedType,
			title: feed.title,
		},
		update: {
			updatedAt: feed.latestActivity,
			readOnly: feed.readOnly ? 1 : 0,
			loadingStatus: feed.loadingStatus,
			feedType: feed.feedType,
			title: feed.title,
		},
	});

	for (const e of feed.events) {
		upsertAccountEvent(db, e);
	}

	for (const a of feed.accounts) {
		upsertFeedAccount(db, a, feed.id);
	}

	return newFeed;
}

export async function upsertFeedAccount(
	db: Electric["db"],
	a: Account,
	feedId: string,
) {
	db.account.upsert({
		where: {
			id: a.id,
		},
		create: {
			id: a.id,
			name: a.name,
			avatarColor: a.avatarColor,
			firstName: a.firstName,
			lastName: a.lastName,
		},
		update: {
			name: a.name,
			avatarColor: a.avatarColor,
			firstName: a.firstName,
			lastName: a.lastName,
		},
	});

	for (const p of a.FeedPermissions) {
		db.permission.upsert({
			where: {
				id: p.id,
			},
			create: {
				id: p.id,
				accountId: a.id,
				feedId: feedId,
				name: p.name,
				enabled: p.enabled ? 1 : 0,
			},
			update: {
				accountId: a.id,
				feedId: feedId,
				name: p.name,
				enabled: p.enabled ? 1 : 0,
			},
		});
	}
}

export async function upsertMyAccount(
	db: Electric["db"],
	userInfo: UserInfoResponse,
) {
	const accountId =
		userInfo?.session?.credentials?.[0].CredentialScopes?.[0].accountId;
	let account

	if (accountId) {
		account = db.account.upsert({
			where: {
				id: accountId,
			},
			create: {
				mine: 1,
				id: accountId,
				name: userInfo?.name,
				accountType: userInfo?.accountType,
				avatarColor: userInfo?.avatarColor,
				email: userInfo?.email,
			},
			update: {
				mine: 1,
				name: userInfo?.name,
				accountType: userInfo?.accountType,
				avatarColor: userInfo?.avatarColor,
				email: userInfo?.email,
			},
		});
	}

	for (const phone of userInfo?.appPhoneNumbers || []) {
		upsertAppPhoneNumber(db, phone);
	}

	return account;
}

export async function upsertAppPhoneNumber(
	db: Electric["db"],
	phone: AppPhoneNumber,
) {
	db.app_phone_number.upsert({
		where: {
			id: phone?.id,
		},
		create: {
			id: phone?.id,
			phoneNumber: phone?.phoneNumber,
			forwardingNumber: phone?.forwardingNumber,
			feedId: phone?.feedId,
		},
		update: {
			phoneNumber: phone?.phoneNumber,
			forwardingNumber: phone?.forwardingNumber,
			feedId: phone?.feedId,
		},
	});
}
