import React from "react";
import { throttle } from "throttle-typescript";

export type AudioControls = {
  onFinished?: () => void;
  onPause?: (time: number) => void;
  onPlay?: (time: number) => void;
  onSeeked?: (time: number) => void;
  onSeeking?: (time: number) => void;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  setEndTime: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPosition: React.Dispatch<React.SetStateAction<number>>;
  id: string;
  playing?: boolean;
};

export function setAudioSources({ sources, audioElement }) {
  audioElement.replaceChildren(...sources);
}

export function connectControlsToElement(
  controls: AudioControls,
  audioElement: HTMLAudioElement,
) {
  const {
    setCurrentTime,
    setPosition,
    onFinished,
    onPlay,
    onPause,
    onSeeked,
    onSeeking,
  } = controls;

  const startPlaying = () => {
    onPlay?.(audioElement.currentTime);
  };

  const pause = () => {
    if (audioElement.currentTime === audioElement.duration) {
      return;
    }
    onPause?.(audioElement.currentTime);
  };

  const finishPlaying = () => {
    onFinished?.();
  };

  const timeUpdate = throttle(() => {
    setCurrentTime(audioElement.currentTime);
    const { currentTime, duration } = audioElement;
    if (currentTime && duration) {
      setPosition((audioElement?.currentTime / audioElement?.duration) * 100);
    }
  }, 100);

  const seeked = () => {
    onSeeked?.(audioElement?.currentTime);
  };

  const seeking = () => {
    onSeeking?.(audioElement?.currentTime);
  };

  const onError = (e: any) => {
    console.error(e);
  };

  audioElement.addEventListener("playing", startPlaying);
  audioElement.addEventListener("timeupdate", timeUpdate);
  audioElement.addEventListener("seeked", seeked);
  audioElement.addEventListener("seeking", seeking);
  audioElement.addEventListener("pause", pause);
  audioElement.addEventListener("ended", finishPlaying);
  audioElement.addEventListener("error", onError);
  console.log("events attached");

  return () => {
    audioElement.removeEventListener("playing", startPlaying);
    audioElement.removeEventListener("timeupdate", timeUpdate);
    audioElement.removeEventListener("seeked", seeked);
    audioElement.removeEventListener("seeking", seeking);
    audioElement.removeEventListener("pause", pause);
    audioElement.removeEventListener("ended", finishPlaying);
    audioElement.removeEventListener("error", onError);
  };
}
