import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

interface ParsedResponseField {
  defaultValue_: undefined | string;
  deserializationConversionPermitted_: boolean;
  fieldType_: number;
  isPacked_: boolean;
  isRepeated_: boolean;
  isRequired_: boolean;
  name_: string;
  nativeType_: Function;
  parent_: Function;
  tag_: string;
}

interface ParsedResponse {
  deserializedFields_: null | string;
  fields_: Array<ParsedResponseField>;
  lazyDeserializer_: null | string;
  values_: {
    1: number;
    2: number;
  };
}

interface PhoneServiceInterface {
  phoneUtil: PhoneNumberUtil;
  transformPhoneNumber: (
    phoneNumber: string | number,
    countryCode: string,
    type: 'E164' | 'NATIONAL'
  ) => string | null;
  parse: (phoneNumber: string | number, countryCode: string) => ParsedResponse;
  isPossibleNumber: (
    phoneNumber: string | number,
    countryCode?: string
  ) => boolean | null;
  isValidNumber: (
    phoneNumber: string | number,
    countryCode?: string
  ) => boolean | null;
  isValidNumberForRegion: (
    phoneNumber: string | number,
    countryCode?: string
  ) => boolean | null;
}

/**
 * Phone Service ☎️ ➔
 * More friendly and typed version of google-libphonenumber
 * try{}catch{} prevents the service from failing if you enter an invalid number
 * @constructor
 */
export const PhoneService = (): PhoneServiceInterface => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const defaultCountry: string = 'us';

  const parse = (
    phoneNumber: string | number,
    country: string = defaultCountry
  ): ParsedResponse | null => {
    try {
      return phoneUtil.parse(phoneNumber, country);
    } catch {
      return null;
    }
  };

  const isPossibleNumber = (
    phoneNumber: string | number,
    countryCode: string = defaultCountry
  ): boolean | null => {
    try {
      return phoneUtil.isPossibleNumber(
        parse(phoneNumber, countryCode),
        countryCode
      );
    } catch {
      return null;
    }
  };

  const isValidNumber = (
    phoneNumber: string | number,
    countryCode: string = defaultCountry
  ): boolean | null => {
    try {
      return phoneUtil.isValidNumber(
        parse(phoneNumber, countryCode),
        countryCode
      );
    } catch {
      return null;
    }
  };

  const isValidNumberForRegion = (
    phoneNumber: string | number,
    countryCode: string = defaultCountry
  ): boolean | null => {
    try {
      return phoneUtil.isValidNumberForRegion(
        parse(phoneNumber, countryCode),
        countryCode
      );
    } catch {
      return null;
    }
  };

  const transformPhoneNumber = (
    phoneNumber: string | number,
    countryCode: string = defaultCountry,
    type: 'E164' | 'NATIONAL' = 'NATIONAL'
  ): string | null => {
    try {
      return phoneUtil.format(
        phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode),
        PhoneNumberFormat[type]
      );
    } catch {
      return null;
    }
  };

  return {
    phoneUtil,
    parse,
    transformPhoneNumber,
    isPossibleNumber,
    isValidNumber,
    isValidNumberForRegion
  };
};
