import { SVGProps } from "react";

export default function Send({ ...props }: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="39"
			height="33"
			viewBox="0 0 39 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<title>Send</title>
			<path
				d="M18.9434 31.873C18.1053 33.0736 16.3035 32.9963 15.5714 31.7282L11.5616 24.783C11.1254 24.0275 11.2292 23.0764 11.8182 22.4328L17.528 16.1935C18.843 14.7566 17.5371 12.479 15.6327 12.8879L7.32405 14.6717C6.47543 14.8539 5.60617 14.4679 5.17219 13.7162L1.18537 6.81086C0.452764 5.54195 1.28827 3.94199 2.74822 3.81803L36.5303 0.94984C38.2193 0.806438 39.3097 2.69752 38.3394 4.08746L18.9434 31.873Z"
				fill="currentColor"
			/>
		</svg>
	);
}
