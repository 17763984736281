import ChannelDial from "@/components/Icons/ChannelDial";
import { Feed } from "@/generated/client";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Box } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { SearchFeedsResult } from "../../../web-client/api/data-contracts";

export default function ChannelItem({
  active,
  activeFeeds,
  activeLinkIndex,
  channel,
  id,
  itemIndex,
  lastItem,
}: {
  active: boolean;
  activeFeeds: Feed[];
  activeLinkIndex: number;
  channel: SearchFeedsResult;
  id: string;
  itemIndex: number;
  lastItem: boolean;
}) {
  const { ampli } = useContext(TrackingContext);
  const { setGlobalSearchOpen } = useContext(UxContext);
  const linkRef = useRef<HTMLAnchorElement>();
  const url = `/workspaces/${channel.workspaceId}/feeds/${channel.feedId}`;
  const hasPermissions = !!activeFeeds?.find(
    (feed: Feed) => feed.id === channel.feedId,
  )?.id;
  const disabledPermissionsLink = !hasPermissions && "disabled";
  const activeLink = active && "active";
  const lastItemLink = lastItem && "last-item";
  const linkClassName = `search-item ${disabledPermissionsLink} ${activeLink} ${lastItemLink}`;

  const clickEvent = () => {
    setGlobalSearchOpen(() => false);
    ampli.searchLinkToMessage({ workspaceId: channel.workspaceId });
  };

  /**
   * Keyboard enter event handler
   */
  useEffect(() => {
    if (active && activeLinkIndex === itemIndex && linkRef.current) {
      clickEvent();
      linkRef.current.click();
    }
  }, [active, activeLinkIndex, active, itemIndex, clickEvent]);

  return (
    <Link
      ref={linkRef}
      id={id}
      to={url}
      className={linkClassName}
      onClick={() => clickEvent()}
    >
      <>
        <Box className="channel-icon">
          <ChannelDial />
        </Box>
        <Box
          dangerouslySetInnerHTML={{
            __html: channel.title,
          }}
        />
      </>
    </Link>
  );
}
