import { useElectric } from "@/electric/ElectricWrapper";
import { Item } from "@/generated/client";
import { DataContext } from "@/models/DataProvider";
import { AllPreferredLanguage } from "@/models/languages";
import { targetCodecForBrowser } from "@/utils/audio";
import { selectAudio } from "@/utils/contentSelection";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import React from "react";
import "regenerator-runtime/runtime";

export default function ItemInfoTable({ item }: { item: Item }) {
	const { preferredLanguage } = React.useContext(DataContext);
	const { db } = useElectric();
	const { results: audioContents } = useLiveQuery(() => {
		if (!item.contentId) return;
		return db.audio_encoding.liveMany({
			where: {
				contentId: item.contentId,
			},
		});
	}, [item.contentId]);
	const { results: transcriptions } = useLiveQuery(() => {
		if (!item.contentId) return;
		return db.transcription.liveMany({
			where: {
				contentId: item.contentId,
				transcriptionType: "full",
			},
		});
	}, [item.contentId]);

	const inputLanguage = transcriptions?.filter((t) => !t.translatedFrom)[0]
		?.language as AllPreferredLanguage;

	const targetCodec = targetCodecForBrowser();
	const suitableAudioContent = selectAudio(
		preferredLanguage,
		audioContents?.filter((a) => a.codec === targetCodec),
		inputLanguage,
	)[0];

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }}>
				<TableHead>
					<TableRow>
						<TableCell>Item ID</TableCell>
						<TableCell>Content ID</TableCell>
						<TableCell>Account ID</TableCell>
						<TableCell>Selected Audio Language</TableCell>
						<TableCell>Selected Audio Generated Service</TableCell>
						<TableCell>Selected Audio Generated Voice</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
						<TableCell>{item.id}</TableCell>
						<TableCell>{item.contentId}</TableCell>
						<TableCell>{item.accountId}</TableCell>
						<TableCell>{suitableAudioContent?.language === null ? `original (${inputLanguage})` : suitableAudioContent?.language}</TableCell>
						<TableCell>{suitableAudioContent?.generatedService}</TableCell>
						<TableCell>{suitableAudioContent?.generatedVoice}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}
