export const firebaseConfig = {
	apiKey: "AIzaSyCQz8WIv2fllaLOgz6mEOMxeqikduMVeB4",
	authDomain: "chat-by-storyboard.firebaseapp.com",
	projectId: "chat-by-storyboard",
	storageBucket: "chat-by-storyboard.appspot.com",
	messagingSenderId: "442351117028",
	appId: "1:442351117028:web:550a0a3b1d3863e8fa82f1",
	measurementId: "G-E8S7EX0SN7",
};
export const vapidKey =
	"BGIDYjszKdLcre0vRwJ_OHYljMxiPBScTLYVeBkeozRBSGWaozlxI1Us3ceT8b280H7I1jHQtyCTdKNgFPyH2e0";
