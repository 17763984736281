import React, { useContext, useState } from "react";
import ReactSpeechRecognition from "@/components/VoiceAssistants/ReactSpeechRecognition/Index";
import { Box, Button, FormLabel, List } from '@mui/material';
import { VoiceAssistantContext } from "@/models/VoiceAssistantContextProvider";
import { AppContext } from '@/models/AppStateProvider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton/IconButton';

export default function VoiceAssistants() {
	const { flags, setFlags } = React.useContext(AppContext);
	const { voiceAssistant, setCommand, setTranscript } = useContext(
		VoiceAssistantContext,
	);

	interface Assistant {
		key: string;
		name: string;
		component: any;
		active: boolean;
		enabled: boolean;
	}

	const assistantsList = [
		{
			key: "",
			name: "None",
			component: null,
			active: true,
			enabled: true,
		},
		{
			key: "react-speech-assistant",
			name: "React Speech Assistant",
			component: <ReactSpeechRecognition />,
			active: false,
			enabled: true,
		}
	]

	const [activeAssistant, setActiveAssistant] = useState<string>("");
	const [assistants, updateAssistants] =
		useState<Array<Assistant>>(assistantsList);

	const handleChangeAssistant = async (data: Assistant) => {
		setActiveAssistant(() => data.component);
		updateAssistants(() =>
			assistants.map((assistant) =>
				assistant.key === data.key
					? { ...assistant, active: true }
					: { ...assistant, active: false },
			),
		);
	};

	const disableVoiceAssistants = () => {
		setFlags({ ...flags, voiceAssistant: false });
	}

	return (
		voiceAssistant && (
			<Box
				sx={{
					position: "fixed",
					border: "solid 1px #555",
					right: "0.5rem",
					bottom: "0.5rem",
					background: "#333",
					borderRadius: "0.5rem",
					width: "300px",
					overflow: "hidden",
					zIndex: 99999,
				}}
			>
				<Box sx={{ background: "#222", padding: "0.5rem", display:'flex', alignItems:'center' }}>
					<Box sx={{flex:'1'}}>Voice Assistants</Box>
					<Box>
						<IconButton
							aria-label="close"
							color="primary"
							sx={{ p: 0 }}
							onClick={()=>disableVoiceAssistants()}
						>
							<CloseIcon role="img"  />
						</IconButton>
					</Box>
				</Box>
				<List>
					{assistants.map((assistant) => (
						<FormLabel
							sx={{
								display: "flex",
								cursor: "pointer",
								alignItems: "center",
							}}
							key={assistant.key}
						>
							<Box>
								<input
									type="radio"
									name="assistants"
									checked={assistant.active}
									value={assistant.key}
									onChange={() => handleChangeAssistant(assistant)}
								/>
							</Box>
							<Box>{assistant.name}</Box>
						</FormLabel>
					))}
				</List>
				{activeAssistant && <Box>{activeAssistant}</Box>}
			</Box>
		)
	);
}
