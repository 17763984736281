import { useEffect, useRef, useState } from "react";

/**
 * Set a timeout to trigger a refresh state
 * On first load the first refresh will be 250ms after loading
 * @param seconds
 * @constructor
 */
export default function UseTimeouts(seconds: number): { refresh: boolean } {
	const hasData = useRef(false);
	const [fetchData, setFetchData] = useState<boolean | null>(null);
	const [refresh, setRefresh] = useState<boolean>(false);

	async function setAsyncRefresh(): Promise<void> {
		if (fetchData === true) {
			return;
		}
		setRefresh(() => false);
		setFetchData(() => true);
		const dataInterval = hasData.current ? seconds * 1000 : 250; // default interval to get first api request
		await new Promise((resolve) => setTimeout(resolve, dataInterval));
		if (!hasData.current) {
			hasData.current = true;
		}
		setFetchData(() => null);
		setRefresh(() => true);
	}

	useEffect(() => {
		setAsyncRefresh();
	}, [fetchData]);

	return {
		refresh,
	};
}
