import { SVGProps } from "react";
export default function ScheduleCalendar({
	...props
}: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<title>schedule-calendar</title>
			<g id="calendar-clock-outline">
				<path
					id="Vector"
					d="M6 1.60059V3.60059H5C3.89 3.60059 3 4.49059 3 5.60059V19.6006C3 20.7006 3.89 21.6006 5 21.6006H11.1C12.36 22.8406 14.09 23.6006 16 23.6006C19.87 23.6006 23 20.4706 23 16.6006C23 14.6906 22.24 12.9606 21 11.7006V5.60059C21 4.50059 20.11 3.60059 19 3.60059H18V1.60059H16V3.60059H8V1.60059M5 5.60059H19V7.60059H5M5 9.60059H19V10.2706C18.09 9.84059 17.07 9.60059 16 9.60059C12.13 9.60059 9 12.7306 9 16.6006C9 17.6706 9.24 18.6906 9.67 19.6006H5M16 11.7506C18.68 11.7506 20.85 13.9206 20.85 16.6006C20.85 19.2806 18.68 21.4506 16 21.4506C13.32 21.4506 11.15 19.2806 11.15 16.6006C11.15 13.9206 13.32 11.7506 16 11.7506ZM15 13.6006V17.2906L18.19 19.1306L18.94 17.8306L16.5 16.4206V13.6006H15Z"
					fill="white"
				/>
			</g>
		</svg>
	);
}
