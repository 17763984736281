export const teamnames = [
  'Goonies',
  'A-Team',
  'Incredibles',
  'Reservoir Dogs',
  'ThunderCats',
  'Mighty Ducks',
  'Wild Bunch',
  'Sandlot Kids',
  'Rat Pack',
  'Bad News Bears',
  'Scooby-Doo Gang',
  'Cobra Kai',
  'Misfits',
  'Ninja Turtles',
  'Power Rangers',
  'Expendables',
  'Munsters',
  'Addams Family',
  'Mod Squad',
  'The Byrds',
  'Fleetwood Mac',
  'Jefferson Airplane',
  'Talking Heads',
  'ABBA',
  'The Cure',
  'New Order',
  'Joy Divison',
  'Gang of Four',
  'Depeche Mode',
  'The Human League',
  'The Clash',
  'Psychedelic Furs',
  'New Power Generation',
  'Parliament Funk',
  'The Cure',
  'The Breakfast Club',
  'Brady Bunch'
];

export const getRandomTeamname = () => {
  const randomIndex = Math.floor(Math.random() * teamnames.length);
  const randomTeamName = teamnames[randomIndex];
  return randomTeamName;
};
