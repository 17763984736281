import { Stack, Typography, useTheme } from "@mui/material";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import WorkflowScheduledItem from "./WorkflowScheduledItem";
import Pagination from "../Pagination";

export default function WorkflowItems() {
	const { db } = useElectric();
	const params = useParams();
	const workspaceId = params?.workspaceId as string;
	const theme = useTheme();
	const [activePage, setActivePage] = useState(1);
	const [templatesPerPage, setTemplatesPerPage] = useState(20);

	const { results: workflowItems } = useLiveQuery(() => {
		if (!workspaceId) return;
		return db.workflow_item.liveMany({
			where: {
				workspaceId,
				deletedAt: null,
			},
			orderBy: {
				createdAt: "desc",
			},
		});
	}, [workspaceId]);

	const { results: broadcasts } = useLiveQuery(() => {
		if (!workflowItems) return;
		return db.broadcast_action.liveMany({
			where: {
				workflowItemId: { in: workflowItems?.map((wi) => wi?.id) },
			},
		});
	}, [workflowItems]);

	const { results: scheduleTriggers } = useLiveQuery(() => {
		if (!broadcasts) return;
		return db.schedule_trigger.liveMany({
			where: {
				broadcastActionId: { in: broadcasts?.map((b) => b.id) },
				OR: [
					{
						oneTimeSchedule: { not: null, gte: new Date()?.toISOString() },
					},
					{
						cronSchedule: { not: null },
					},
				],
			},
		});
	}, [broadcasts]);

	const scheduledItems = workflowItems?.filter((wi) => {
		const broadcast = broadcasts?.find((b) => b?.workflowItemId === wi?.id);
		const schedules = scheduleTriggers?.find(
			(ots) => ots?.broadcastActionId === broadcast?.id,
		);

		if (schedules) {
			return wi;
		}
	});

	const handlePerPageChange = (page) => {
		setActivePage(page);
	};

	const handleTemplatesPageChange = (perPage) => {
		setTemplatesPerPage(perPage);
	};

	return (
		<Stack
			sx={{ width: "100%", height: "100%", position: "relative" }}
			className="scrollable-content"
		>
			{scheduledItems?.length > 0 ? (
				<>
					<Stack
						sx={{
							width: "100%",
							height: "auto",
							gap: 3,
							position: "relative",
							"& .scheduled-message-element:last-of-type": {
								marginBottom: "32px",
							},
							flexGrow: 1,
						}}
					>
						{scheduledItems
							?.slice(
								(activePage - 1) * templatesPerPage,
								activePage * templatesPerPage - 1,
							)
							?.map((scheduledItem) => (
								<WorkflowScheduledItem
									key={`${scheduledItem?.id}`}
									workflowItem={scheduledItem}
								/>
							))}
					</Stack>
					<Stack
						sx={{
							width: "100%",
							position: "sticky",
							bottom: 16,
							alignItems: "center",
						}}
					>
						<Pagination
							itemsCount={scheduledItems?.length || 0}
							onPageChange={handlePerPageChange}
							onRowsPerPageChange={handleTemplatesPageChange}
						/>
					</Stack>
				</>
			) : (
				<Stack
					sx={{
						width: "100%",
						py: 1.5,
						px: 2,
						background: theme.palette.secondary.main,
						borderRadius: 1.5,
						alignItems: "center",
					}}
				>
					<Typography sx={{ fontWeight: 600 }}>
						No scheduled messages
					</Typography>
				</Stack>
			)}
		</Stack>
	);
}
