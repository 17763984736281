import Folder from "@/components/Icons/Folder";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, ListItemButton, useTheme } from "@mui/material";
import { ReactNode } from "react";

interface GroupButtonProps {
  active: boolean;
  children: ReactNode;
  rightContent?: ReactNode;
  chipCount?: number | null;
  toggleActiveState?: (value: boolean) => void;
}

export default function ChannelGroupButton({
  children,
  active,
  toggleActiveState,
  rightContent,
}: GroupButtonProps) {
  const theme = useTheme();
  return (
    <ListItemButton
      sx={{ display: "flex" }}
      onClick={() => toggleActiveState(!active)}
    >
      <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
        <Box sx={{ mr: 1 }}>
          {active ? (
            <KeyboardArrowUp role="img" />
          ) : (
            <KeyboardArrowDown role="img" />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 1 }}>
            <Folder role="img" />
          </Box>
          <Box>{children}</Box>
        </Box>
      </Box>

      {rightContent && <Box sx={{ flex: "0 1 auto" }}>{rightContent}</Box>}
    </ListItemButton>
  );
}
