import { SVGProps } from "react";
export default function PaperAirplane({ ...props }: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="24"
			height="23"
			viewBox="0 0 24 23"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
			fill="currentColor"
		>
			<title>paper-airplane</title>
			<path d="M14.3105 22.7549C13.3115 22.7549 12.9785 21.96 12.6562 20.875L10.9482 15.2031C10.7441 14.4834 10.7764 14.1182 11.1953 13.6777L22.1631 1.85059C22.292 1.71094 22.2812 1.5498 22.1738 1.44238C22.0664 1.3457 21.9053 1.32422 21.7656 1.45312L9.98145 12.4639C9.51953 12.8936 9.13281 12.915 8.44531 12.7002L2.64453 10.9385C1.61328 10.627 0.861328 10.2939 0.861328 9.30566C0.861328 8.52148 1.55957 7.97363 2.46191 7.62988L20.9385 0.550781C21.4326 0.357422 21.873 0.25 22.2383 0.25C22.9365 0.25 23.3662 0.679688 23.3662 1.37793C23.3662 1.74316 23.2588 2.18359 23.0654 2.67773L16.0293 21.0576C15.6318 22.0889 15.084 22.7549 14.3105 22.7549Z" />
		</svg>
	);
}
