import { UppyFile } from "@uppy/core";
import { buildVersion } from "@/utils/environment";
import exifr from "exifr";

export interface HourMinuteSecondInterface {
	h: number;
	m: number;
	s: number;
}

/**
 * Convert seconds to total time
 * @param totalSeconds
 */
export const secondsToHourMinuteSecond = (
	totalSeconds: number,
): HourMinuteSecondInterface => {
	const totalMinutes = Math.floor(totalSeconds / 60);
	const seconds = totalSeconds % 60;
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;
	return { h: hours, m: minutes, s: seconds };
};

/**
 * Pad a number with a zero
 * @param val
 */
export const padNumber = (val: number): string =>
	val > 9 ? `${val}` : `0${val}`;

/**
 * Pad a time with a zero => 00:00
 * @param seconds
 */
export const getPaddedTime = (seconds: number) => {
	const time = secondsToHourMinuteSecond(Number(seconds));
	return `${padNumber(time.m)}:${padNumber(time.s)}`;
};

/**
 * Force a download for testing
 * @param data
 * @param filename
 * @param mime
 */
export const forceFileDownload = (
	data: any,
	filename: string,
	mime: string,
) => {
	const blob = new Blob([data], { type: mime || "application/octet-stream" });
	const blobURL = window.URL.createObjectURL(blob);
	const tempLink = document.createElement("a");
	tempLink.style.display = "none";
	tempLink.href = blobURL;
	tempLink.setAttribute("download", filename);
	if (typeof tempLink.download === "undefined") {
		tempLink.setAttribute("target", "_blank");
	}
	document.body.appendChild(tempLink);
	tempLink.click();
	document.body.removeChild(tempLink);
	setTimeout(() => {
		// For Firefox it is necessary to delay revoking the ObjectURL
		window.URL.revokeObjectURL(blobURL);
	}, 100);
};

export const debugLogger = (
	message: string,
	styles: string,
	debug?: boolean,
): void => {
	if (debug) {
		console.log(`%c${message}`, styles);
	}
};

export const clearIndexDB = async (
	sessionCoreId?: string,
	sessionCorePrefix?: string,
) => {
	if (sessionCoreId) {
		try {
			await window.indexedDB.deleteDatabase(sessionCoreId);
		} catch (e) {
			console.log(e, `Could Not Delete IndexDB database: ${sessionCoreId}`);
		}
	}

	if (sessionCorePrefix) {
		try {
			const dbs = await window.indexedDB.databases();
			if (dbs?.length) {
				await Promise.all(
					dbs.map(async (db) =>
						db?.name?.includes(sessionCorePrefix)
							? await window.indexedDB.deleteDatabase(db.name)
							: {},
					),
				);
			}
		} catch (e) {
			console.log("FIREFOX", { e });
		}
	}
};

/**
 * Requests mic access, then immediately stops, so follow up events are allowed
 */
export const requestMicAccess = async (): Promise<boolean> =>
	await navigator.mediaDevices
		.getUserMedia({ audio: true })
		.then((stream) => {
			stream.getTracks().forEach((track) => track.stop());
			return true;
		})
		.catch(() => Promise.reject(false));

export interface AudioContextInterface {
	sampleRate: string;
	currentTime: string;
	baseLatency: string;
	outputLatency: string;
}

export const fetchAudioMetaData = (
	context: AudioContext,
): AudioContextInterface => {
	try {
		return {
			sampleRate: context?.sampleRate.toString(),
			currentTime: context?.currentTime.toString(),
			baseLatency: context?.baseLatency.toString(),
			outputLatency: context?.outputLatency.toString(),
		};
	} catch (e) {
		return {
			sampleRate: "",
			currentTime: "",
			baseLatency: "",
			outputLatency: "",
		};
	}
};

export interface AudioNodeInterface {
	pcmData: any;
	analyserNode: any;
	mediaStream: MediaStream;
}

export interface AudioNode {
	analyserNode: AnalyserNode;
	pcmData: Float32Array;
}

/**
 * Create an Audio Node
 */
export const setupAudioNode = async (): Promise<AudioNodeInterface> => {
	const audioContext = new window.AudioContext();
	const mediaStream = await navigator.mediaDevices.getUserMedia({
		audio: true,
		video: false,
	});
	const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
	const analyserNode = audioContext.createAnalyser();
	mediaStreamSource.connect(analyserNode);
	const pcmData = new Float32Array(analyserNode.fftSize);
	return {
		pcmData,
		analyserNode,
		mediaStream,
	};
};

/**
 * Monitor the audio levels
 * If on mobile multiply levels for more reactivity
 * @param audioNode
 * @param isMobile
 */
export const monitorAudioLevels = (
	audioNode: AudioNode,
	isMobile: boolean,
): number => {
	if (audioNode?.pcmData && audioNode?.analyserNode) {
		audioNode.analyserNode.getFloatTimeDomainData(audioNode.pcmData);
		let sumSquares = 0.0;
		for (const amplitude of audioNode.pcmData) {
			sumSquares += amplitude * amplitude;
		}
		const level = Math.sqrt(sumSquares / audioNode.pcmData.length) * 1000;
		return isMobile ? level * 1.5 : level / 7;
	}
};

export const requestWakeLock = async () => {
	// @ts-ignore
	const wakeLockCheck = navigator?.wakeLock ?? null;
	if (!wakeLockCheck) {
		return false;
	}
	let wakeLock;
	try {
		wakeLock = await wakeLockCheck.request();
		wakeLock.addEventListener("release", () => {
			console.log("Screen Wake Lock released:", wakeLock.released);
		});
		return wakeLock;
	} catch (err) {
		console.error(`${err.name}, ${err.message}`);
	}
};

export const cancelWakeLock = async (wakeLock) => {
	try {
		await wakeLock.release();
	} catch (err) {
		console.error(`${err.name}, ${err.message}`);
	}
};

export const fetchUploadConfig = (config) => {
	const baseDomain = config?.baseDomain;
	const apiPrefix = baseDomain?.split(".")[0].split("//")[1];
	let stage;
	if (
		baseDomain === "https://api.storyboard.com" ||
		apiPrefix === "api" ||
		apiPrefix === "prod"
	) {
		stage = "prod";
	} else {
		stage = apiPrefix || "dev";
	}
	return {
		tusUrl: config.tusUrl,
		stage,
	};
};

export interface AudioBufferInfo {
	duration: string;
	length: string;
	numberOfChannels: string;
	sampleRate: string;
}

export const readFileAudioContextPromise = async (
	file,
): Promise<AudioBufferInfo> => {
	const readFile = (file) =>
		new Promise((resolve, reject) => {
			const fr = new FileReader();
			fr.onload = () => {
				resolve(fr.result);
			};
			fr.onerror = reject;
			fr.readAsArrayBuffer(file);
		});

	const audioContext = new AudioContext();
	const audioFileContext = await readFile(file).then((data) => data);

	const transformAudioBuffer = (buffer) => ({
		duration: buffer?.duration?.toString(),
		length: buffer?.length?.toString(),
		numberOfChannels: buffer?.numberOfChannels?.toString(),
		sampleRate: buffer?.sampleRate?.toString(),
	});
	return transformAudioBuffer(
		await audioContext.decodeAudioData(audioFileContext as ArrayBuffer),
	);
};

export const readExifData = async (image) => {
  try {
    const exifData = await exifr.parse(image);
    return {
      longitude: exifData?.longitude?.toString() ?? "",
      latitude: exifData?.latitude?.toString() ?? "",
      model: exifData?.Model?.toString() ?? "",
      gpsAltitude: exifData?.GPSAltitude?.toString() ?? "",
      createDate: exifData?.CreateDate?.toString() ?? "",
      orientation: exifData?.Orientation?.toString() ?? "",
    };

  } catch(e) {
    console.error('Error parsing exif data', e);
  }
  return {}
};

export const buildFileMetaData = async ({
	file,
	stage,
	feedId,
	itemId,
	myAccount,
	uniqueUploadId
}: {
	file: UppyFile<Record<string, unknown>, Record<string, unknown>>;
	stage: string;
	feedId: string;
	itemId: string;
	myAccount: string;
	uniqueUploadId: string;
}) => {
	const types = file?.type?.split("/") ?? ["audio", "wav"];
	const type = types[0];
	const codec = types[1];

	const exifData = file?.type?.includes("image")
		? await readExifData(file.data)
		: {};

	return {
		filename: file.name,
		filetype: file.type,
		mimeType: file.type,
		id: uniqueUploadId,
		feedItemId: itemId,
		codec,
		type,
		surface: "web",
		surfaceContext: window?.navigator?.userAgent,
		surfaceBuild: buildVersion(),
		stage: stage,
		feedId: feedId,
		accountId: myAccount,
		uploadId: uniqueUploadId,
		...exifData
	};
};
