/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PTTWakeUpRequest, UpdateAccountRequest, UpdatePushNotificationRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Accounts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name AccountsDelete
   * @summary deleteAccount
   * @request DELETE:/accounts/{accountId}
   */
  accountsDelete = (accountId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/accounts/${accountId}`,
      method: "DELETE",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name AccountsPartialUpdate
   * @summary updateAccount
   * @request PATCH:/accounts/{accountId}
   */
  accountsPartialUpdate = (accountId: string, body: UpdateAccountRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/accounts/${accountId}`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name DevicesWakeupCreate
   * @summary pttWakeup
   * @request POST:/accounts/{accountId}/devices/wakeup
   */
  devicesWakeupCreate = (accountId: string, body: PTTWakeUpRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/accounts/${accountId}/devices/wakeup`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name PushnotificationdevicesUpdate
   * @summary updatePushNotificationDevice
   * @request PUT:/accounts/{accountId}/pushnotificationdevices
   */
  pushnotificationdevicesUpdate = (
    accountId: string,
    body: UpdatePushNotificationRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/accounts/${accountId}/pushnotificationdevices`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      ...params,
    });
}
