interface StorageState {
  removeLocalStorage: (key: string) => void;
  getLocalStorage: (key: string) => any;
  setLocalStorage: ({
    key,
    value,
  }: { key: string; value: string | boolean | number | object | null }) => void;
  clearLocalStorage: () => void;
}

export default function UseStorage(type?: "local" | "session"): StorageState {
  const hasStorage = () =>
    typeof window?.localStorage === "object" ||
    typeof window?.sessionStorage === "object";

  if (!hasStorage()) {
    return {
      removeLocalStorage: () => {
        console.error("removeLocalStorage: No storage available");
      },
      getLocalStorage: () => {
        console.error("getLocalStorage: No storage available");
        return "";
      },
      setLocalStorage: () => {
        console.error("setLocalStorage: No storage available");
      },
      clearLocalStorage: () => {
        console.error("clearLocalStorage: No storage available");
      },
    };
  } else {
    const storageType =
      !type || type === "local" ? window.localStorage : window.sessionStorage;

    const setLocalStorage = ({ key, value }: { key: string; value: any }) => {
      storageType.setItem(key, JSON.stringify(value));
    };
    const getLocalStorage = (key: string) => {
      const localData = storageType.getItem(key);
      if (localData) {
        return JSON.parse(localData);
      }
    };

    const removeLocalStorage = (key: string) => {
      storageType.setItem(key, "");
      storageType.removeItem(key);
    };

    const clearLocalStorage = () => storageType.clear();

    return {
      removeLocalStorage,
      getLocalStorage,
      setLocalStorage,
      clearLocalStorage,
    };
  }
}
