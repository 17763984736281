import { Tags } from "@/utils";
import {
  Button,
  Box,
  Chip,
  Divider,
  styled as styledMUI,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useRef } from "react";

export interface FeedItemHeaderProps {
  dateId: string;
  onClick: () => void;
  dateLabel?: string;
  isStuck?: boolean;
  isUnreadHeader?: boolean;
  inline?: boolean;
  unreadCount?: number;
  showArrow?: boolean;
  unreadAbove?: boolean;
}

export default function FeedItemHeader({
  dateId,
  onClick,
  dateLabel,
  isStuck,
  isUnreadHeader,
  unreadCount,
  showArrow,
  unreadAbove,
}: FeedItemHeaderProps) {
  const headerRef = useRef<HTMLDivElement>();
  const itemHeaderRef = useRef<HTMLButtonElement>();

  const theme = useTheme();

  const UnreadLabel = () => {
    const icon = unreadAbove ? (
      <ArrowUpward role="img" sx={{ fontSize: "0.875rem" }} />
    ) : (
      <ArrowDownward role="img" sx={{ fontSize: "0.875rem" }} />
    );
    const message = isStuck
      ? `${unreadCount} new ${unreadCount === 1 ? "message" : "messages"}`
      : "New";

    return (
      <Stack
        sx={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: isStuck ? "center" : "flex-end",
          gap: 0.5,
          height: 34,
        }}
      >
        {showArrow ? icon : null}
        <Typography
          sx={{
            fontSize: "0.875rem",
            fontWeight: 500,
            color: isStuck
              ? theme.palette.primary.main
              : theme.palette.brand.secondary.main,
          }}
        >
          {message}
        </Typography>
      </Stack>
    );
  };

  const MUIHeader = styledMUI("div")({
    position: "relative",
    height: "34px",
    margin: "0 auto",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    boxSizing: "border-box",
    zIndex: 1100,
  });

  return (
    <MUIHeader ref={headerRef} as={Tags.h3} sx={{ width: "100%" }}>
      <Box
        ref={itemHeaderRef}
        sx={{
          width: "100%",
          maxWidth: 1300,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 34,
          borderBottom:
            !isStuck && isUnreadHeader && unreadCount > 0
              ? `2px solid ${theme.palette.brand.secondary.main}`
              : "none",
        }}
      >
        {dateLabel ? (
          <Box
            id={`date-header-${dateId}`}
            className="date-header"
            aria-label={`scroll to ${dateId}`}
            sx={{
              py: "5px",
              px: 1.5,
              fontSize: "0.875rem",
              fontWeight: "600",
              transition: ".1s",
              textTransform: "none",
              borderRadius: "100px",
              border: "none",
              minWidth: 110,
              maxWidth: "max-content",
              background: "transparent",
            }}
          >
            {dateLabel}
          </Box>
        ) : null}
        {isUnreadHeader && !isStuck && unreadCount > 0 ? (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              width: "100%",
              maxWidth: 1300,
            }}
          >
            <UnreadLabel />
          </Box>
        ) : null}
        {isUnreadHeader && isStuck ? (
          <Divider
            className="unread-header"
            sx={{
              display: "flex",
              boxSizing: "border-box",
              minWidth: 130,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              "&::before, &::after": {
                content: isStuck ? "none" : '""',
                borderColor: theme.palette.brand.secondary.main,
                borderWidth: 2,
              },
            }}
          >
            <Chip
              label={<UnreadLabel />}
              sx={{
                background: theme.palette.brand.secondary.main,
                height: 34,
                width: "100%",
                borderRadius: "100px",
                "& .MuiChip-label": {
                  px: 2,
                },
                "&:hover": {
                  background: theme.palette.brand.secondary.light,
                },
              }}
              clickable
              onClick={onClick}
            />
          </Divider>
        ) : null}
      </Box>
    </MUIHeader>
  );
}
