import { Box, Button } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { useInView } from "react-intersection-observer";
export default function LoadMoreButton({
  children,
  click,
  disabled,
}: {
  children?: ReactNode;
  click: () => void;
  disabled: boolean;
}) {
  const { ref, inView } = useInView({});

  useEffect(() => {
    if (disabled || !inView) {
      return;
    }
    click();
  }, [click, inView, disabled]);

  const hasChildren = children?.toString().length > 0 ? children : "Load More";

  return (
    <Box sx={{ py: 4, margin: "-2rem 0" }} ref={ref}>
      <Button
        sx={{ opacity: "0.3", "&:hover": { opacity: "1" } }}
        disabled={disabled}
        onClick={() => click()}
      >
        {hasChildren}
      </Button>
    </Box>
  );
}
