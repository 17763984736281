import { Box, useTheme } from "@mui/material";

export default function RadialProgress({
	progress = 0,
	width = 20,
	ringColor = "#707070",
	progressColor = "#3FFF77",
	backgroundColor = "#000",
	"aria-label": ariaLabel = "Progress",
}: {
	progress: number;
	width: number;
	ringColor: string;
	progressColor: string;
	backgroundColor: string;
	"aria-label"?: string;
}) {
	const theme = useTheme();
	return (
		<Box
			sx={{
				borderRadius: "100%",
				background: backgroundColor,
				width: `${width}px`,
				height: `${width}px`,
			}}
			className="radial-progress"
		>
			<Box
				aria-label={ariaLabel}
				sx={{
					width: `${width}px`,
					height: `${width}px`,
					borderRadius: "50%",
					background: `conic-gradient(${progressColor} ${progress}%, ${ringColor} ${progress}%)`,
					mask: `radial-gradient(farthest-side,transparent calc(100% - 6px), ${theme.palette.primary.main} calc(100% - 6px + 1px))`,
					transition: "all 0.25s ease-in forwards",
				}}
			>
				<Box sx={{ display: "none", visibility: "hidden" }}>
					<progress value={progress} max={100} />
				</Box>
			</Box>
		</Box >
	);
}
