import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { Item } from "@/generated/client";
import "regenerator-runtime/runtime";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";

export default function ItemFilesTable({ item }: { item: Item }) {
	const { db } = useElectric();

	const { results: links } = useLiveQuery(
		db.link.liveMany({
			where: {
				contentId: item.contentId,
			},
		}),
	);
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }}>
				<TableHead>
					<TableRow>
						<TableCell>Url</TableCell>
						<TableCell>Description</TableCell>
						<TableCell>Title</TableCell>
						<TableCell>Image</TableCell>
						<TableCell>Id</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{links?.map((row) => (
						<TableRow
							key={row.id}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TableCell>
								<a target="_blank" href={row.url} rel="noreferrer">
									{row.url}
								</a>
							</TableCell>
							<TableCell>{row.description}</TableCell>
							<TableCell>{row.title}</TableCell>
							<TableCell>
								<a target="_blank" href={row.image} rel="noreferrer">
									{row.image}
								</a>
							</TableCell>
							<TableCell>{row.id}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
