import ConfirmDiscardChanges from "@/components/ModalForms/ConfirmDiscardChanges";
import UseStorage from "@/hooks/useStorage";
import { UxContext } from "@/models/UxStateProvider";
import { Box, FormLabel, SxProps } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useContext, useEffect, useState } from "react";

export default function ReadOnlyModeToggle({ sx }: { sx?: SxProps }) {
  const { userReadOnlyMode, setUserReadOnlyMode } = useContext(UxContext);
  const [showDoubleConfirm, setShowDoubleConfirm] = useState(false);
  const { readOnlyMode } = useFlags();
  const { getLocalStorage, setLocalStorage } = UseStorage();

  const toggleReadyOnlyMode = (currentValue: boolean) => {
    if (currentValue === false && !showDoubleConfirm) {
      setUserReadOnlyMode(true);
      setLocalStorage({ key: "userReadOnlyMode", value: true });
    } else if (currentValue === true && !showDoubleConfirm) {
      setShowDoubleConfirm(() => true);
    }
  };

  useEffect(() => {
    const localStorageUserReadOnlyMode = getLocalStorage("userReadOnlyMode");
    if (localStorageUserReadOnlyMode === true && userReadOnlyMode === false) {
      setUserReadOnlyMode((prev) => (!prev ? true : prev));
    }
  }, [getLocalStorage, userReadOnlyMode, setUserReadOnlyMode]);

  const denyClose = () => {
    setShowDoubleConfirm(() => false);
  };

  const confirmClose = () => {
    setUserReadOnlyMode(() => false);
    setShowDoubleConfirm(() => false);
    setLocalStorage({ key: "userReadOnlyMode", value: false });
  };

  if (!readOnlyMode) {
    return;
  }
  return (
    <>
      {showDoubleConfirm && (
        <ConfirmDiscardChanges
          id="disable-read-only-mode"
          visible={showDoubleConfirm}
          deleteText="Confirm"
          label="Disable read only mode?"
          text="Any active channel you are in may be marked as read."
          denyCloseHandler={denyClose}
          confirmCloseHandler={confirmClose}
        />
      )}

      <FormLabel
        sx={{
          cursor: "pointer",
          width: "auto",
          display: "inline-flex",
          alignItems: "center",
          ...sx,
        }}
      >
        <Switch
          size="small"
          checked={userReadOnlyMode}
          onChange={() => toggleReadyOnlyMode(userReadOnlyMode)}
        />
        <Box sx={{ ml: 2 }}>Read Only Mode</Box>
      </FormLabel>
    </>
  );
}
