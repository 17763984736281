import ChannelDial from "@/components/Icons/ChannelDial";
import { prettyDate } from "@/components/Search/search";
import CircleIcon from "@mui/icons-material/Circle";
import { Box } from "@mui/material";
import React from "react";
import { SearchMessageResult } from "../../../web-client/api/data-contracts";

export default function SearchItemHeader({
  message,
}: { message: SearchMessageResult }) {
  return (
    <Box className="results-header">
      <Box>
        <ChannelDial height={13} width={16} />
      </Box>
      <Box
        dangerouslySetInnerHTML={{
          __html: message.feedTitle,
        }}
      />
      <Box className="circle-box">
        <CircleIcon />
      </Box>
      <Box
        dangerouslySetInnerHTML={{
          __html: message.authorName,
        }}
      />
      <Box className="circle-box">
        <CircleIcon />
      </Box>
      <Box>{prettyDate(message.publishDate)}</Box>
    </Box>
  );
}
