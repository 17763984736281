import ModalForm, { MODAL_DEFAULT_Z_INDEX } from "@/elements/ModalForm";
import {
	SchedulingContext,
	UploadType,
} from "@/models/SchedulingContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import { useCallback, useContext, useState } from "react";
import { Alert, Stack, Snackbar, Typography, useTheme } from "@mui/material";
import ScheduleMessageFileUpload from "./ScheduleMessageFileUpload";
import ScheduleMessageTTS from "./ScheduleMessageTTS";
import ScheduleMessageChannels from "./ScheduleMessageChannels";
import ScheduleMessageDateAndTime from "./ScheduleMessageDateAndTime";
import { UppyContext } from "@/models/UppyContextProvider";
import ConfirmDiscardChanges from "../ModalForms/ConfirmDiscardChanges";

export default function ScheduleMessageModal() {
	const { scheduleMessageModalOpen, setScheduleMessageModalOpen, isSmUp } =
		useContext(UxContext);
	const { uploadType, currentStep, resetAll, unsavedChanges } =
		useContext(SchedulingContext);
	const { uploading, isScheduledUpload, setIsScheduledUpload } =
		useContext(UppyContext);
	const [confirmUnsavedChanges, setConfirmUnsavedChanges] =
		useState<boolean>(false);
	const theme = useTheme();

	const title = () => {
		let stepTitle: string;
		switch (currentStep) {
			case 2:
				stepTitle = "Select Channels";
				break;
			case 3:
				stepTitle = "Schedule and Publish";
				break;
			case 1:
			default:
				stepTitle = "Schedule a message";
				break;
		}
		return stepTitle;
	};

	const confirmClose = () => {
		resetAll();
		setConfirmUnsavedChanges(false);
		setScheduleMessageModalOpen(false);
		setIsScheduledUpload(false);
	};

	const denyClose = () => {
		setConfirmUnsavedChanges(false);
	};

	const handleClose = useCallback(() => {
		if (unsavedChanges) {
			setConfirmUnsavedChanges(true);
		} else {
			confirmClose();
		}
	}, [unsavedChanges, confirmClose]);

	return (
		<>
			<ModalForm
				id="schedule-message"
				maxHeight={750}
				open={scheduleMessageModalOpen}
				onClose={handleClose}
				sx={{ justifyContent: "center", flexGrow: 1 }}
				slotProps={{
					backdrop: {
						children:
							isSmUp && uploading && isScheduledUpload ? (
								<Snackbar
									anchorOrigin={{ vertical: "top", horizontal: "center" }}
									sx={{
										width: "100%",
										padding: 1,
										top: 55,
										zIndex: MODAL_DEFAULT_Z_INDEX,
									}}
									open={true}
								>
									<Alert
										severity="info"
										sx={{
											width: "100%",
											color: theme.palette.primary.main,
											".MuiAlert-icon": {
												padding: 0,
											},
										}}
										elevation={6}
										variant="filled"
									>
										Uploading file...
									</Alert>
								</Snackbar>
							) : null,
					},
				}}
			>
				<>
					<Stack
						sx={{
							width: "100%",
							height: "100%",
							alignItems: "center",
							gap: 5.5,
						}}
					>
						<Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
							{title()}
						</Typography>
						{currentStep === 1 && (
							<>
								{uploadType === UploadType.File && (
									<Stack sx={{ width: "100%", height: "100%", gap: 1 }}>
										<ScheduleMessageFileUpload />
									</Stack>
								)}

								{uploadType === UploadType.TTS && <ScheduleMessageTTS />}
							</>
						)}

						{currentStep === 2 && (
							<>
								<ScheduleMessageChannels />
							</>
						)}

						{currentStep === 3 && (
							<>
								<ScheduleMessageDateAndTime />
							</>
						)}
					</Stack>
					{!isSmUp && uploading && isScheduledUpload ? (
						<Snackbar
							sx={{
								width: "100%",
								padding: 1,
								left: 0,
								bottom: 0,
								zIndex: MODAL_DEFAULT_Z_INDEX,
							}}
							open={true}
						>
							<Alert
								severity="info"
								sx={{
									width: "100%",
									color: theme.palette.primary.main,
									".MuiAlert-icon": {
										padding: 0,
									},
								}}
								elevation={6}
								variant="filled"
							>
								Uploading file...
							</Alert>
						</Snackbar>
					) : null}
				</>
			</ModalForm>
			<ConfirmDiscardChanges
				id="discard-schedule-message"
				visible={confirmUnsavedChanges}
				denyCloseHandler={denyClose}
				confirmCloseHandler={confirmClose}
			/>
		</>
	);
}
