import { Box } from '@mui/material';

export default function AudioLevelIndicator({
  audioLevel,
  size = 50,
  color = '#ffcc00'
}: {
  audioLevel: number;
  size?: number;
  color?: string;
  backgroundColor?: string;
}) {
  let adjustedLevel = (size / 20) * audioLevel;
  const secondaryColSize = adjustedLevel / 2;
  const secondaryColSizeMan = (size / 3) * 1.6;
  const colSize = size / 5;
  const boxSize = size;
  const maxHeight = size - 2;
  if (adjustedLevel > maxHeight) {
    adjustedLevel = maxHeight;
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        minHeight: `${boxSize}px`,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          height: secondaryColSize,
          width: colSize,
          maxHeight: `${secondaryColSizeMan}px`,
          minHeight: `${colSize}px`,
          marginRight: `${colSize}px`,
          borderRadius: `${colSize}px`,
          background: color
        }}
      />
      <Box
        sx={{
          height: adjustedLevel,
          width: colSize,
          maxHeight: `${maxHeight}px`,
          minHeight: `${colSize}px`,
          marginRight: `${colSize}px`,
          borderRadius: `${colSize}px`,
          background: color
        }}
      />
      <Box
        sx={{
          height: secondaryColSize,
          width: colSize,
          maxHeight: `${secondaryColSizeMan}px`,
          minHeight: `${colSize}px`,
          borderRadius: `${colSize}px`,
          background: color
        }}
      />
    </Box>
  );
}
