import { FeedContext } from "@/models/FeedContextProvider";
import ModalForm from "@/elements/ModalForm";
import { useContext, useState } from "react";
import ConfirmDiscardChanges from "@/components/ModalForms/ConfirmDiscardChanges";
import TemplatesSelector from "@/components/ModalForms/TemplatesSelector";
import { Box } from "@mui/material";

type Props = {
  templateSelectionCallback: (templateData: string) => void;
};

export default function TemplatesModal({ templateSelectionCallback }: Props) {
  const { templatesModal, setTemplatesModal } = useContext(FeedContext);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const reset = () => {
    setShowConfirmModal(() => false);
    setTemplatesModal(false);
  };

  const handleTemplateSelection = (data) => {
    templateSelectionCallback(data);
    setTemplatesModal(false);
  };

  const handleClose = (forceClose?: boolean) => {
    if (forceClose === true) {
      return reset();
    } else {
      setTemplatesModal(false);
    }
  };

  return (
    <>
      <ModalForm
        id="templates-modal"
        open={templatesModal}
        onClose={handleClose}
        maxWidth={1024}
        maxHeight={650}
        sx={{
          justifyContent: "start",
          height: "100%",
          overflow: "hidden !important",
        }}
        noPadding
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <TemplatesSelector setTemplateTextAsTTS={handleTemplateSelection} />
        </Box>
      </ModalForm>
      <ConfirmDiscardChanges
        id="tts-message-delete-confirmation"
        visible={showConfirmModal}
        denyCloseHandler={() => setShowConfirmModal(() => false)}
        confirmCloseHandler={() => handleClose(true)}
      />
    </>
  );
}
