import ChannelItem from "@/components/Search/ChannelItem";
import LoadMoreButton from "@/components/Search/LoadMoreButton";
import MessageItem from "@/components/Search/MessageItem";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { Box } from "@mui/material";
import React, { useContext } from "react";
import {
  SearchFeedsResult,
  SearchMessageResult,
} from "../../../web-client/api/data-contracts";

interface SearchResultsListProps {
  activeIndex: number;
  activeLinkIndex: number;
  data: SearchMessageResult[] | SearchFeedsResult[];
  disabledLoadMoreButton?: boolean;
  headerText: string;
  loadMoreData?: () => void;
  showLoadMoreButton: boolean;
  type: string;
  idOffset: number;
}

export default function SearchResultsList({
  activeIndex,
  activeLinkIndex,
  data,
  disabledLoadMoreButton,
  headerText,
  loadMoreData,
  showLoadMoreButton,
  type,
  idOffset,
}: SearchResultsListProps) {
  // load myActive feeds once and pass as prop to each item to determine if it has access
  const { myActiveFeeds } = useContext(LiveQueryContext);

  return (
    <>
      <Box className="search-type-header">{headerText}</Box>
      <Box>
        {data.map((item, itemIndex) => {
          return type === "messages" ? (
            <MessageItem
              itemIndex={itemIndex + idOffset}
              activeLinkIndex={activeLinkIndex + idOffset}
              active={itemIndex === activeIndex}
              id={`search-item-${itemIndex + idOffset}`}
              key={item.id}
              message={item}
              lastItem={itemIndex === data.length - 1}
            />
          ) : (
            <ChannelItem
              itemIndex={itemIndex}
              activeLinkIndex={activeLinkIndex}
              activeFeeds={myActiveFeeds}
              active={itemIndex === activeIndex}
              id={`search-item-${itemIndex + idOffset}`}
              key={item.id}
              channel={item}
              lastItem={itemIndex === data.length - 1}
            />
          );
        })}
        {showLoadMoreButton && (
          <LoadMoreButton
            disabled={disabledLoadMoreButton}
            click={() => loadMoreData()}
          >
            Show More
          </LoadMoreButton>
        )}
      </Box>
    </>
  );
}
