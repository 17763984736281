import { DataContext } from "@/models/DataProvider";
import { useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function WorkspaceId() {
  const navigate = useNavigate();
  const { workspaceMemberships, fetchMyActiveFeeds } = useContext(DataContext);
  const redirecting = useRef<boolean>(false);
  const params = useParams();
  const feedId = params?.feedId ?? null;
  const workspaceId = params?.workspaceId ?? null;
  const validWorkspaceId = !!workspaceMemberships?.find(
    (wm) => wm.workspaceId === workspaceId,
  );
  const workspaceMembership = workspaceMemberships?.find(
    (wsm) => wsm.workspaceId === workspaceId,
  );
  const myActiveFeeds = fetchMyActiveFeeds(
    workspaceId,
    workspaceMembership?.id,
  );

  /**
   * Workspace ID should only re-direct the user to the correct page
   * Handle Redirects
   */
  useEffect(() => {
    let redirectUrl = "";
    if (redirecting.current === true) {
      return;
    }
    redirecting.current = true;
    if (myActiveFeeds?.length > 0 && !feedId) {
      // (1) If there is no feedId and the current user has active feeds, redirect to the first active feed
      redirectUrl = `/workspaces/${workspaceId}/feeds/${myActiveFeeds[0].id}`;
      navigate(redirectUrl);
      redirecting.current = false;
    } else if (!validWorkspaceId && workspaceMemberships?.length > 0) {
      // (2)  If the user is in an invalid workspace and has workspace memberships, redirect to the root /workspace, which will then redirect them to the correct workspace
      redirectUrl = "/workspaces";
      navigate(redirectUrl);
      redirecting.current = false;
    } else if (myActiveFeeds?.length === 0 && validWorkspaceId) {
      // (3)  If the user has no active feeds, but is in a valid workspace, redirect to the no-channels-available page
      redirectUrl = `/workspaces/${workspaceId}/no-channels-available`;
      navigate(redirectUrl);
      redirecting.current = false;
    } else if (!validWorkspaceId && workspaceMemberships?.length === 0) {
      // (4)  If the user is in an invalid workspace and has no workspace memberships, redirect to the root /workspace
      redirectUrl = "/workspaces";
      navigate(redirectUrl);
      redirecting.current = false;
    } else {
      // reset redirecting flag
      redirecting.current = false;
    }
  }, [
    validWorkspaceId,
    workspaceId,
    feedId,
    workspaceMemberships.length,
    myActiveFeeds?.map((feed) => feed.id).join(),
    navigate,
  ]);

  // this component should only ever redirect the user → no UI
  return <></>;
}
