import CampaignIcon from "@mui/icons-material/Campaign";
import { Box } from "@mui/material";
import React from "react";
import { SearchMessageResult } from "../../../web-client/api/data-contracts";

export default function PodcastItem({
  message,
}: { message: SearchMessageResult }) {
  return (
    <Box>
      <Box className="podcast-header">
        <Box className="podcast-icon">
          <CampaignIcon />
        </Box>
        <Box>
          {message?.displayTitle?.length > 0 && (
            <Box
              dangerouslySetInnerHTML={{
                __html: message.displayTitle,
              }}
            />
          )}
        </Box>
      </Box>
      {message?.displayDescription?.length > 0 && (
        <Box
          dangerouslySetInnerHTML={{
            __html: message.displayDescription,
          }}
        />
      )}
    </Box>
  );
}
