export function buildVersion() {
  const ref = import.meta.env.VITE_VERCEL_GIT_COMMIT_REF;
  const sha = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA;

  if (ref || sha) {
    return `${ref}#${sha}`;
  } else {
    return 'local';
  }
}
