import { styled } from "styled-components";

export const containerStyle = {
  timestamp: {
    minWidth: "6ch",
  },
  playButton: {
    m: 0,
    mr: 1,
    p: 0,
  },
};

export const LongPressOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  padding: 12px;
`;

export const ContentOverlay = styled.div<{ $hasAudio?: boolean }>`
  appearance: inherit;
  position: relative;
  z-index: 1;
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0 0 0;
  ${({ $hasAudio }) => $hasAudio && "padding-top: 32px;"}
`;
