import Loading from "@/components/Loading/Index";
import SearchResultsList from "@/components/Search/SearchResultsList";
import { PaginatedSearchData } from "@/components/Search/search";
import { UxContext } from "@/models/UxStateProvider";
import BasicButtonStyles from "@/theme/BasicButtonStyles";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Box, Button, Fade, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

type SearchResultsProps = {
  channels: PaginatedSearchData;
  messages: PaginatedSearchData;
  disabledLoadMoreButton: boolean;
  hasSearched: boolean;
  initializeSearch: () => void;
  loading: boolean;
  loadPaginatedData?: () => void;
  loadMoreChannels?: () => void;
  noResults: boolean;
  query: string;
  setBlur: (value: boolean) => void;
  staticQueryString: string;
  disableShowMore?: boolean;
  disableEnterKey: boolean;
  resultsIndex: number | null;
};

export default function SearchResults({
  disableEnterKey,
  channels,
  messages,
  disabledLoadMoreButton,
  hasSearched,
  initializeSearch,
  loadPaginatedData,
  loadMoreChannels,
  loading,
  noResults,
  query,
  setBlur,
  staticQueryString,
  disableShowMore,
  resultsIndex,
}: SearchResultsProps) {
  const theme = useTheme();
  const { normalButtonStyles } = BasicButtonStyles();
  const { globalSearchOpen, setGlobalSearchOpen } = useContext(UxContext);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [activeLinkIndex, setActiveLinkIndex] = useState<number>(-1);

  const hasMessages = messages?.results?.length > 0;
  const hasChannels = channels?.results?.length > 0;
  const channelLength = channels?.results?.length ?? 0;
  const messageLength = messages?.results?.length ?? 0;
  const stringMismatch = query !== staticQueryString && hasSearched;

  const scrollResultsToIndex = (index: number) => {
    const element = document.getElementById(`search-item-${index}`);
    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (resultsIndex) {
      setActiveIndex(() => resultsIndex);
    }
  }, [resultsIndex]);

  const arrowDownHandler = () => {
    if (!globalSearchOpen) {
      return;
    }

    const lastItem = channelLength + messageLength - 1;
    if (activeIndex === lastItem) {
      return;
    }
    setBlur(false);

    setActiveIndex((value) => value + 1);
    scrollResultsToIndex(activeIndex + 1);
  };
  const arrowUpHandler = () => {
    if (activeIndex === 0) {
      setBlur(true);
    }
    if (activeIndex === -1) {
      return;
    }
    setActiveIndex((value) => value - 1);
    scrollResultsToIndex(activeIndex - 1);
  };

  const enterHandler = () => {
    if (!disableEnterKey && activeIndex >= 0 && activeLinkIndex === -1) {
      setActiveLinkIndex(() => activeIndex);
      setTimeout(() => {
        setActiveLinkIndex(() => -1);
      }, 500);
    }
  };

  const keyDownHandler = (event: KeyboardEvent) => {
    if (!globalSearchOpen) {
      return;
    }

    if (!channelLength && !messageLength) {
      return;
    }
    const { code } = event;
    if (code === "ArrowDown") {
      arrowDownHandler();
    }
    if (code === "ArrowUp") {
      arrowUpHandler();
    }
    if (code === "Enter") {
      enterHandler();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  });

  const enterButton = () => {
    return (
      <Button
        type="submit"
        onClick={() => initializeSearch()}
        disabled={query?.length === 0}
        sx={{
          ...normalButtonStyles,
          width: "auto",
          display: "inline-flex",
          alignItems: "center",
          background: theme.palette.secondary.main,
          borderRadius: 1,
          px: 1,
          py: 0.25,
        }}
      >
        <Box>Enter</Box>
        <KeyboardReturnIcon
          sx={{
            width: 20,
            height: 20,
            ml: 0.5,
            display: "flex",
          }}
        />
      </Button>
    );
  };

  const updateResults = () => {
    return (
      <Fade in={stringMismatch}>
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 2 }}>To update results click enter</Box>
          <Box>{enterButton()}</Box>
        </Box>
      </Fade>
    );
  };

  if (!hasSearched) {
    return (
      <Box className="search-results flex">
        <Box className="flex-grow" sx={{ color: theme.palette.neutral.main }}>
          Search for{query?.length > 0 ? ` ${query}` : "..."}
        </Box>
        {enterButton()}
      </Box>
    );
  }

  if (loading) {
    return (
      <Box className="search-results flex" sx={{ justifyContent: "center" }}>
        <Loading variant="white" size="large" baseSize={6} />
      </Box>
    );
  }

  if (hasSearched && noResults) {
    return (
      <Box className="search-results">
        {stringMismatch && updateResults()}
        <Box>No results found for {staticQueryString}</Box>
      </Box>
    );
  }

  return (
    <Box className="search-results">
      {stringMismatch && updateResults()}
      {hasChannels && (
        <Box>
          <SearchResultsList
            type="channels"
            headerText="In channels:"
            data={channels.results}
            activeLinkIndex={activeLinkIndex}
            activeIndex={activeIndex}
            disabledLoadMoreButton={disabledLoadMoreButton}
            showLoadMoreButton={false}
            idOffset={0}
          />
          {channels?.meta?.loadMore && (
            <Box sx={{ mb: 1 }}>
              <Box sx={{ display: "inline-block" }}>
                <Button
                  disabled={disableShowMore}
                  onClick={() => loadMoreChannels()}
                  sx={{
                    ...normalButtonStyles,
                    fontSize: "12px",
                    padding: 0,
                    fontWeight: "normal",
                    textDecoration: "underline",
                  }}
                >
                  Show more
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}

      {hasMessages && (
        <SearchResultsList
          type="messages"
          headerText="In messages:"
          data={messages.results}
          activeLinkIndex={activeLinkIndex - channels.results?.length}
          activeIndex={activeIndex - channels?.results?.length}
          disabledLoadMoreButton={disabledLoadMoreButton}
          showLoadMoreButton={messages?.meta?.loadMore}
          loadMoreData={() => loadPaginatedData()}
          idOffset={channels?.results?.length}
        />
      )}
    </Box>
  );
}
