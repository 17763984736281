type configType = "default" | "restricted" | "disabled";
interface ConfigOptions {
	name: configType;
	acceptedTypes: string[];
	allowedMimeTypes: string[];
	enabled: boolean;
	multiple: boolean;
	restrictionsEnabled: boolean;
}

/**
 * Available config options for file uploads
 */
const fileUploadConfigOptions: ConfigOptions[] = [
	{
		name: "default",
		acceptedTypes: [],
		allowedMimeTypes: [],
		enabled: true,
		multiple: false,
		restrictionsEnabled: false,
	},
	{
		name: "restricted",
		acceptedTypes: ["mp3", "wav", "ogg", "m4a"],
		allowedMimeTypes: [
			"audio/ogg",
			"audio/wav",
			"audio/mpeg",
			"audio/x-wav",
			"audio/m4a",
			"audio/x-m4a",
		],
		enabled: true,
		multiple: false,
		restrictionsEnabled: true,
	},
	{
		name: "disabled",
		acceptedTypes: [],
		allowedMimeTypes: [],
		enabled: false,
		multiple: false,
		restrictionsEnabled: false,
	},
];

export const fileUploadConfig =
	fileUploadConfigOptions.find((config) => config.name === "default") ??
	fileUploadConfigOptions[0];
