import { Electric } from "@/generated/client";
import { LiveResultContext } from "electric-sql/dist/client/model/model";
import { ResultData } from "electric-sql/dist/frameworks/react/hooks/useLiveQuery";
import { DependencyList } from "react";

export type WorkspaceMember = {
	accountId: string;
	membershipId: string;
	name: string;
	avatarColor: string;
	role: string;
	email: string;
	phoneNumber: string;
};

type queryType<Res> = (
	runQueryFn: () => LiveResultContext<Res>,
	dependencies: DependencyList,
) => ResultData<Res>;

export function workspaceMemberships<Res>(
	useLiveQuery: queryType<Res>,
	db: Electric["db"],
	{ workspaceId }: { workspaceId: string },
) {
	const { results: workspaceMembers, error } = useLiveQuery(() => {
		if (!workspaceId) {
			return;
		}
		return db.liveRaw({
			sql: `
				SELECT 
					workspace_membership.accountId as accountId, 
					workspace_membership.role, 
					workspace_membership.id as membershipId, 
					COALESCE(account.email, direct_ws_invitation.email) as email,
					COALESCE(account.phoneNumber, direct_ws_invitation.phoneNumber) as phoneNumber,
					COALESCE(account.name, direct_ws_invitation.name) as name, 
					account.avatarColor as avatarColor
				FROM 
					workspace_membership
				LEFT JOIN 
					account 
				ON 
					workspace_membership.accountId = account.id
				LEFT JOIN
					direct_ws_invitation
				ON
					workspace_membership.id = direct_ws_invitation.workspaceMembershipId
				WHERE 
					workspace_membership.workspaceId = ?
				AND 
					workspace_membership.status = 'active'`,
			args: [workspaceId],
		});
	}, [workspaceId]);

	return { workspaceMembers, error } as {
		workspaceMembers: WorkspaceMember[]
		error: any
	}
}

export type MappedWorkspaceMembership = WorkspaceMember & {
	isCurrentFeedMember: boolean;
	isPendingFeedMember: boolean;
	displayName: string;
};

export function mapWorkspaceMemberships(
	workspaceMembers: WorkspaceMember[],
	accountIds: string[],
	pendingInviteIds: string[],
) {
	return workspaceMembers?.map((member) => ({
		...member,
		isCurrentFeedMember: accountIds?.includes(member?.accountId),
		isPendingFeedMember: pendingInviteIds?.includes(member?.membershipId),
		displayName:
			member?.name ||
			member?.email ||
			member?.phoneNumber
	})).sort((a, b) => a?.displayName?.localeCompare(b?.displayName));
}
