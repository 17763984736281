import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import React from 'react';
import theme from '@/theme/theme';

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <CssBaseline />
    </ThemeProvider>
  );
}
