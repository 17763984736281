import { Device, Call } from "@twilio/voice-sdk";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { isAValidPhoneNumber } from "@/utils";
import { AppContext } from "@/models/AppStateProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { AppPhoneNumber } from "web-client/api/data-contracts";
import { AudioAppContext } from "./AudioAppContextProvider";
import { DataContext } from "./DataProvider";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { ActionContext } from "./ActionsProvider";

let device: Device;
let call: Call;

export type AppPhoneState = {
	appPhoneNumber: AppPhoneNumber;
	callInProgress: boolean;
	device: Device;
	call: Call;
	createNumber: (forwardingNumber: string) => Promise<void>;
	sentDigits?: string;
	endCall: () => void;
	formattedInboundNumber?: string;
	formattedOutboundNumber?: string;
	makeOutboundCall: () => Promise<void>;
	muted: boolean;
	outboundNumber: string;
	ringing: boolean;
	sendDigits: (digit: string) => void;
	setSentDigits: (digits: string) => void;
	setCallInProgress: (progress: boolean) => void;
	setOutboundNumber: (outbound: string) => void;
	setRinging: (ringing: boolean) => void;
	toggleCallMute: () => void;
};

export const AppPhoneContext = createContext<AppPhoneState>({
	appPhoneNumber: {} as AppPhoneNumber,
	callInProgress: false,
	device,
	call,
	createNumber: (forwardingNumber) => Promise.resolve(),
	endCall: () => {},
	makeOutboundCall: () => Promise.resolve(),
	muted: false,
	outboundNumber: "",
	ringing: false,
	sendDigits: (digit) => {},
	setCallInProgress: (progress) => {},
	setOutboundNumber: (outbound) => {},
	setRinging: (ringing) => {},
	setSentDigits: (digits) => {},
	toggleCallMute: () => {},
});

const AppPhoneContextProvider = ({ children }) => {
	const { client } = useContext(AppContext);
	const { myAccount } = useContext(DataContext);
	const { createAppPhoneNumber } = useContext(ActionContext);
	const { setPlaybackEnabled, playQueue, pauseQueue } =
		useContext(AudioAppContext);
	const { ampli } = useContext(TrackingContext);
	const [outboundNumber, setOutboundNumber] = useState<string>("");
	const [callInProgress, setCallInProgress] = useState<boolean>(false);
	const [sentDigits, setSentDigits] = useState<string>("");
	const [ringing, setRinging] = useState<boolean>(false);
	const [muted, setMuted] = useState<boolean>(false);

	const { db } = useElectric();

	const { results: appPhoneNumber } = useLiveQuery(
		db.app_phone_number.liveFirst({}),
	);

	const { results: audioQueue } = useLiveQuery(
		db.audio_queue_item.liveMany({
			orderBy: {
				createdAt: "asc",
			},
		}),
	);

	const createNumber = async (forwardingNumber) => {
		createAppPhoneNumber(forwardingNumber);
		ampli.appPhoneCreateNewNumber();
	};

	const handleDeviceSetup = useCallback(async () => {
		if (!appPhoneNumber?.id) {
			console.error("No phone number associated with this account");
			return;
		}
		const fetchToken = await client.fetchPhoneToken(appPhoneNumber?.id);
		if (fetchToken) {
			device = new Device(fetchToken?.accessToken, {
				logLevel: import.meta.env.DEV ? 0 : 4,
				// Leaving this blank fails, but it is supposed to default to closest edge
				edge: "ashburn",
			});

			device.on("error", (error) => {
				console.log(`Twilio.Device Error: ${error.message}`);
			});
			device.on("ready", () => console.log("device ready"));
			device.on("connect", (call) => {
				console.log("device connected", call);
			});
		}
	}, [client, appPhoneNumber]);

	const callEnded = useCallback(() => {
		setRinging(false);
		setCallInProgress(false);
		setOutboundNumber("");
		setSentDigits("");
		setMuted(false);
		setPlaybackEnabled(true);
		// if we have an audio queue then resume playback after 5 seconds of the call ending
		if (audioQueue?.length > 0) {
			setTimeout(() => playQueue(), 5000);
		}
	}, [setPlaybackEnabled, playQueue, audioQueue]);

	const makeOutboundCall = useCallback(async () => {
		await handleDeviceSetup();
		if (!isAValidPhoneNumber(outboundNumber)) {
			console.log("invalid phone number");
			return;
		}
		if (device) {
			// disable hands free playback and pause active playback
			setPlaybackEnabled(false);
			pauseQueue();
			call = await device.connect({
				params: {
					To: outboundNumber,
					From: appPhoneNumber.phoneNumber,
					AppPhoneNumberId: appPhoneNumber.id,
					AccountId: myAccount.id,
				},
				rtcConstraints: {
					audio: true,
				},
			});
			if (call) {
				ampli.outboundCallPlaced();

				call.on("ringing", () => {
					setRinging(true);
				});

				call.on("accept", () => {
					setRinging(false);
					setCallInProgress(true);
					ampli.outboundCallAnswered();
					console.log("call accepted");
				});
				call.on("disconnect", () => {
					callEnded();
					ampli.outboundCallEnded();
					console.log("The call has been disconnected.");
				});
				call.on("reject", () => {
					callEnded();
					ampli.outboundCallRejected({});
					console.log("The call was rejected.");
				});
				call.on("error", (error) => {
					ampli.outboundCallFail();
					console.log(error);
				});
			}
		}
	}, [
		ampli,
		callEnded,
		handleDeviceSetup,
		outboundNumber,
		appPhoneNumber?.phoneNumber,
		appPhoneNumber?.id,
		myAccount,
		setPlaybackEnabled,
		pauseQueue,
	]);

	const endCall = () => {
		if (device) {
			device.disconnectAll();
		}
	};

	const toggleCallMute = () => {
		if (call) {
			const muteState = call.isMuted();
			call.mute(!muteState);
			setMuted(!muteState);
		}
	};

	const sendDigits = useCallback(
		(digit: string) => {
			if (call) {
				const sent = sentDigits + digit;
				call.sendDigits(digit);
				setSentDigits(sent);
			}
		},
		[sentDigits],
	);

	const appPhoneState = {
		callInProgress,
		device,
		call,
		createNumber,
		endCall,
		appPhoneNumber,
		makeOutboundCall,
		muted,
		outboundNumber,
		ringing,
		sendDigits,
		sentDigits,
		setCallInProgress,
		setOutboundNumber,
		setRinging,
		setSentDigits,
		toggleCallMute,
	};

	return (
		<AppPhoneContext.Provider value={appPhoneState}>
			{children}
		</AppPhoneContext.Provider>
	);
};

export default AppPhoneContextProvider;
