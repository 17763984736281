import { SVGProps } from "react";

export default function Tools({ ...props }: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M26.7758 25.0532L24.8824 26.9465C24.6326 27.1948 24.2947 27.3342 23.9424 27.3342C23.5902 27.3342 23.2523 27.1948 23.0024 26.9465L7.16244 11.1332C6.72886 11.2588 6.28047 11.326 5.82911 11.3332C4.98018 11.3326 4.14366 11.1293 3.3891 10.7403C2.63453 10.3513 1.98373 9.78783 1.49079 9.09667C0.997857 8.40552 0.67703 7.60668 0.554986 6.76657C0.432942 5.92646 0.513208 5.06935 0.789111 4.2665L4.17578 7.65317L4.88244 6.9465L6.77578 5.05317L7.48244 4.3465L4.09578 0.959837C4.89862 0.683935 5.75573 0.603669 6.59584 0.725712C7.43595 0.847756 8.23479 1.16858 8.92595 1.66152C9.6171 2.15446 10.1806 2.80526 10.5696 3.55982C10.9586 4.31439 11.1618 5.15091 11.1624 5.99984C11.1553 6.45119 11.0881 6.89959 10.9624 7.33317L26.7758 23.1732C27.0241 23.423 27.1635 23.7609 27.1635 24.1132C27.1635 24.4654 27.0241 24.8034 26.7758 25.0532ZM0.882444 23.1732C0.634109 23.423 0.49472 23.7609 0.49472 24.1132C0.49472 24.4654 0.634109 24.8034 0.882444 25.0532L2.77578 26.9465C3.02559 27.1948 3.36353 27.3342 3.71578 27.3342C4.06802 27.3342 4.40596 27.1948 4.65578 26.9465L11.9491 19.6665L8.17578 15.8932M24.4958 0.666504L19.1624 3.33317V5.99984L16.2691 8.89317L18.9358 11.5598L21.8291 8.6665H24.4958L27.1624 3.33317L24.4958 0.666504Z" />
		</svg>
	);
}
