import { SVGProps } from "react";
export default function NoteEditOutlined({
	...props
}: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>note-edit-outlined</title>
			<path
				d="M18.6901 12L19.9501 10.74C20.3901 10.3 20.9501 10.06 21.5601 10V9L15.5601 3H5.56006C4.45006 3 3.56006 3.89 3.56006 5V19C3.56006 20.1 4.45006 21 5.56006 21H11.5601V19.13L11.6901 19H5.56006V5H12.5601V12H18.6901ZM14.5601 4.5L20.0601 10H14.5601V4.5ZM19.6901 13.83L21.7301 15.87L15.6001 22H13.5601V19.96L19.6901 13.83ZM23.4101 14.19L22.4301 15.17L20.3901 13.13L21.3701 12.15C21.5601 11.95 21.8901 11.95 22.0901 12.15L23.4101 13.47C23.6101 13.67 23.6101 14 23.4101 14.19Z"
				fill="white"
			/>
		</svg>
	);
}
