import ModalForm from "@/elements/ModalForm";
import { Help, CheckCircleOutlined, Circle } from "@mui/icons-material";
import {
  Alert,
  Button,
  Box,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useContext } from "react";
import TextMessageInput from "./TextMessageInput";
import { ActionContext } from "@/models/ActionsProvider";
import { UxContext } from "@/models/UxStateProvider";
import PaperAirplane from "./Icons/PaperAirplane";

export default function UserFeedbackModal() {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackMessageValid, setFeedbackMessageValid] = useState(false);
  const [sending, setSending] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const { sendFeedback } = useContext(ActionContext);
  const { userFeedbackModalOpen, setUserFeedbackModalOpen } =
    useContext(UxContext);

  const maxChars = 5000;

  const exceededCharLimit = feedbackMessage.length > maxChars;
  const canSendMessage = feedbackMessage?.length > 0 && !exceededCharLimit;

  const handleSendLogs = async () => {
    try {
      if (feedbackMessageValid) {
        setSending(true);
        await sendFeedback(feedbackMessage);
        setFeedbackSent(true);
        reset();
        setTimeout(() => {
          setFeedbackSent(false);
          handleClose();
        }, 3000);
        setSending(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const reset = () => {
    setFeedbackMessage("");
    setFeedbackMessageValid(false);
  };

  const handleClose = () => {
    reset();
    setUserFeedbackModalOpen(false);
  };

  const handleTextInput = (text: string) => {
    setFeedbackMessage(text);
  };

  const handleTextValidation = (valid: boolean) => {
    setFeedbackMessageValid(valid);
  };

  return (
    <ModalForm
      id="send-app-feedback"
      open={userFeedbackModalOpen}
      onClose={handleClose}
      disableClose={sending}
      maxWidth={685}
      sx={{
        padding: {
          xs: "16px",
          sm: "68px 88px 68px 88px",
        },
      }}
    >
      {feedbackSent ? (
        <Stack
          sx={{
            width: "100%",
            gap: 6,
            position: "relative",
            alignItems: "center",
          }}
        >
          <Stack sx={{ alignItems: "center", gap: 1.25 }}>
            <CheckCircleOutlined
              role="presentation"
              sx={{ height: "4.5rem", width: "4.5rem" }}
            />
            <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
              Your feedback was submitted.
            </Typography>
          </Stack>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </Stack>
      ) : (
        <Stack sx={{ width: "100%", gap: 3, position: "relative" }}>
          <Stack sx={{ width: "100%", textAlign: "center", gap: 1 }}>
            <Typography variant="h5" component="h2" sx={{ fontWeight: 700 }}>
              Leave Feedback
            </Typography>
            <Typography sx={{ fontSize: "1.125rem" }}>
              Did you encounter a problem with your experience? Write us a note
              and describe your issue. We’re here to help!
            </Typography>
          </Stack>

          <TextMessageInput
            inputId="feedback-message"
            label="Description*"
            rows={7}
            disabled={sending || feedbackSent}
            characterLimit={maxChars}
            showHelperText={false}
            textInputValue={feedbackMessage}
            textInputHandler={handleTextInput}
            validateTextHandler={handleTextValidation}
            textValid={feedbackMessageValid}
            placeholder="Type your feedback here..."
            sx={{ width: "100%" }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2.5,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={sending}
              onClick={handleClose}
              aria-label="Close feedback modal"
              sx={{
                width: { xs: "100%", sm: "auto" },
                order: { xs: 1, sm: 0 },
                flexGrow: 1,
                flexBasis: "100%",
              }}
            >
              CANCEL
            </Button>
            <LoadingButton
              loading={sending}
              variant="contained"
              color="primary"
              disabled={!canSendMessage}
              onClick={handleSendLogs}
              aria-label={"Send Feedback"}
              sx={{
                width: { xs: "100%", sm: "auto" },
                order: { xs: 0, sm: 1 },
                flexGrow: 1,
                flexBasis: "100%",
                gap: 1,
              }}
            >
              <PaperAirplane role="presentation" />
              Send
            </LoadingButton>
          </Box>
        </Stack>
      )}
    </ModalForm>
  );
}
