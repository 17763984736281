import BasicButtonStyles from "@/theme/BasicButtonStyles";
import ModalForm from "@/elements/ModalForm";
import React, { useContext, useState } from "react";
import { AccountInfo } from "@/models/accountInfo";
import { Feed } from "@/generated/client";
import { Box, Button, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import LoadingButton from "@mui/lab/LoadingButton";
import { ActionContext } from "@/models/ActionsProvider";
import Locator from "@/locator";

export default function RemoveMemberFromChannel({
  account,
  feed,
  closeContext,
}: {
  account: AccountInfo;
  feed: Feed;
  closeContext: () => void;
}) {
  const { removeMemberFromFeed } = useContext(ActionContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [removeFromChannel, setRemoveFromChannel] = useState<boolean>(false);
  const { normalButtonStyles, normalIconStyles } = BasicButtonStyles();
  const removeMember = async () => {
    setDisabled(() => true);
    await removeMemberFromFeed(feed.workspaceId, feed.id, account.id);
    closeContext();
  };

  return (
    <>
      <ModalForm
        id={`${account.id}-remove-from-channel`}
        open={removeFromChannel}
        onClose={() => {
          setRemoveFromChannel(() => false);
          closeContext();
        }}
      >
        <Box>
          <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
            Remove user from channel
          </Typography>
          <Typography sx={{ fontSize: 16, mb: 2 }}>
            This users will lose access to this channel. You can invite them
            again later.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2.5,
            }}
          >
            <Button
              disabled={disabled}
              aria-label={Locator.feed.members.cancelRemove}
              variant="outlined"
              color="primary"
              onClick={() => closeContext()}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={disabled}
              aria-label={Locator.feed.members.confirmRemove}
              variant="contained"
              color="error"
              sx={{ borderRadius: 6 }}
              onClick={() => removeMember()}
            >
              Remove
            </LoadingButton>
          </Box>
        </Box>
      </ModalForm>

      <Button
        sx={normalButtonStyles}
        aria-label={Locator.feed.members.list.context.remove}
        onClick={() => setRemoveFromChannel(() => true)}
      >
        <Box sx={normalIconStyles}>
          <ArrowOutwardIcon role="img" />
        </Box>
        <Box>Remove from Channel</Box>
      </Button>
    </>
  );
}
