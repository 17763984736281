import { Box, Button } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useElectric } from "@/electric/ElectricWrapper";
import { useEffect } from "react";
import { WsFeed } from "../../web-client/api/data-contracts";
import { useNavigate } from "react-router-dom";

export default function RedirectNotification() {
	const buildUrlByLocalFeedId = ({
		feedId,
		workspaceId,
		itemId,
	}: { feedId: string, workspaceId: string, itemId: string }): string => {
		return `/workspaces/${workspaceId}/feeds/${feedId}#${itemId}`;
	};
	const navigate = useNavigate();
	const { db } = useElectric();
	const params = new URLSearchParams(window.location.search);
	const feedId = params.get("feedId");
	const itemId = params.get("itemId");
	const { results: feed } = useLiveQuery(() => db.feed.liveFirst({
		where: {
			id: feedId
		}
	}), [feedId]);

	useEffect(() => {
		if (feed) {
			window.location.href = buildUrlByLocalFeedId({ feedId: feed.id, workspaceId: feed.workspaceId, itemId });
		}
	}, [feedId, itemId]);

	return <Button onClick={() => navigate("/workspaces/")}>Home</Button>;
}
