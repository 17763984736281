import ModalForm from "@/elements/ModalForm";
import { toMilliseconds } from "@/utils";
import { Box } from "@mui/material";
import { useIdle } from "@uidotdev/usehooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useRef, useState } from "react";

export default function IdleUserModal() {
  const { idleUserPageRefresh } = useFlags();

  const [startCountdown, setStartCountdown] = useState<boolean>(false);
  const countdownTimerDefault = 30;
  const [timerValue, setTimerValue] = useState<number>(countdownTimerDefault);
  const timeLeft = useRef(countdownTimerDefault);
  if (!idleUserPageRefresh) {
    return;
  }

  const idle = useIdle(toMilliseconds(0, 20, 0));

  const countDown = () => {
    const downloadTimer = setInterval(function () {
      timeLeft.current -= 1;
      setTimerValue(() => timeLeft.current);
      if (timeLeft.current <= 0) {
        window.location.reload();
        clearInterval(downloadTimer);
      }
    }, 1000);
  };

  const [userIdle, setUserIdle] = useState<boolean>(false);
  useEffect(() => {
    if (idle && !startCountdown) {
      setStartCountdown(() => true);
      setUserIdle(() => true);
      countDown();
    }
  }, [idle]);

  const reset = () => {
    timeLeft.current = 10;
    setUserIdle(() => false);
    setStartCountdown(() => false);
  };

  return (
    <ModalForm open={userIdle} onClose={() => reset()}>
      <Box>
        This page will reload in
        <span style={{ color: "red" }}> {timerValue} </span>
        seconds
      </Box>
    </ModalForm>
  );
}
