import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import TrashOutlined from "@/components/Icons/TrashOutlined";
import { AppContext } from "@/models/AppStateProvider";
import { useLiveQuery } from "electric-sql/react";
import { useContext, useEffect, useState } from "react";
import { upsertWsTemplate } from "@/data/workspace";
import { WsTemplate } from "../../../web-client/api/data-contracts";
import { useParams } from "react-router-dom";
import { useElectric } from "@/electric/ElectricWrapper";
import { FullInput } from "@/components/Utils";
import ConfirmDiscardChanges from "@/components/ModalForms/ConfirmDiscardChanges";
import { Add } from "@mui/icons-material";
import Loading from "../Loading/Index";
import TextInputHelper from "@/components/TextInputHelper";
import { LoadingButton } from "@mui/lab";
import Locator from "@/locator";

interface Props {
  setTemplateTextAsTTS: (value: string) => void;
  denyCloseHandler?: Function;
  confirmCloseHandler?: Function;
  includeCloseButton?: boolean;
}
export default function TemplatesSelector({
  setTemplateTextAsTTS,
  denyCloseHandler,
  confirmCloseHandler,
  includeCloseButton,
}: Props) {
  const theme = useTheme();
  const { db } = useElectric();
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const [templates, setTemplates] = useState<Array<WsTemplate>>([]);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [createNewTemplate, setCreateNewTemplate] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<string | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [confirmUnsavedChanges, setConfirmUnsavedChanges] =
    useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [activeTemplate, setActiveTemplate] = useState<WsTemplate | null>(null);
  const [templateText, setTemplateText] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const { client } = useContext(AppContext);
  const characterLimit = 5000;

  const { results: userInfo } = useLiveQuery(
    db.account.liveFirst({
      where: {
        mine: 1,
      },
    }),
  );

  const { results: cachedTemplates } = useLiveQuery(() => {
    if (!userInfo?.id) return;
    return db.template.liveMany({
      where: {
        workspaceId,
        deletedAt: null,
      },
    });
  }, [userInfo]);

  useEffect(() => {
    if (cachedTemplates?.length > 0) {
      setLoadingTemplates(() => false);
      return setTemplates(() => cachedTemplates);
    } else {
      setLoadingTemplates(() => true);
      setTimeout(() => {
        setLoadingTemplates(() => false);
      }, 5000);
    }
  }, [cachedTemplates?.map((t) => t?.id)?.join()]);

  const handleTemplateSelection = (templateData: WsTemplate) => {
    setActiveTemplate(() => templateData);
    setTemplateName(() => templateData?.name);
    setTemplateText(() => templateData?.template);
    setCreateNewTemplate(() => false);
  };

  const useSelectedTemplate = (templateData: WsTemplate) => {
    setTemplateTextAsTTS(templateData.template);
    confirmClose(true);
  };

  const handleCreateNewTemplate = () => {
    setCreateNewTemplate(() => true);
    setActiveTemplate(() => null);
    setTemplateName(() => "");
    setTemplateText(() => "");
  };

  const saveNewTemplate = async (e) => {
    e.preventDefault();
    if (!templateName || !templateText) {
      return;
    }
    setSaving(() => true);
    const { template } = await client.createWorkspaceTemplate({
      workspaceId,
      name: templateName,
      template: templateText,
    });
    setTemplates((array) => [...array, template]);
    await upsertWsTemplate(db, template);
    setActiveTemplate(() => template);
    setCreateNewTemplate(() => false);
    setConfirmUnsavedChanges(() => false);
    setSaving(() => false);
  };

  const saveActiveTemplate = async (e) => {
    e.preventDefault();
    if (!templateName || !templateText) {
      return;
    }
    setSaving(() => true);
    const { template } = await client.updateWorkspaceTemplate({
      workspaceId,
      templateId: activeTemplate.id,
      name: templateName,
      template: templateText,
    });
    setTemplates((array) =>
      array.map((item) => (item.id === activeTemplate.id ? template : item)),
    );
    await upsertWsTemplate(db, template);
    setActiveTemplate(() => template);
    setConfirmUnsavedChanges(() => false);
    setSaving(() => false);
  };

  const onKeyDown = (e) => {
    if (e.metaKey && e.which === 13) {
      activeTemplate?.id ? saveActiveTemplate(e) : saveNewTemplate(e);
    }
  };

  const handleDelete = async () => {
    setSaving(() => true);
    await client.deleteWorkspaceTemplate({
      workspaceId,
      templateId: confirmDelete,
    });
    setTemplates((array) => array.filter((item) => item.id !== confirmDelete));
    setConfirmDelete(() => null);
    setActiveTemplate(() => null);
    await db.template.delete({
      where: { id: confirmDelete },
    });
    setSaving(() => false);
  };

  const saveButtonDisabledState =
    templateName === activeTemplate?.name &&
    templateText === activeTemplate?.template;

  const handleTemplateName = (value) => {
    const newName = value;
    setTemplateName(() => newName);
    if (activeTemplate?.name !== newName) {
      setUnsavedChanges(() => true);
    }
  };

  const handleTemplateText = (value) => {
    const newText = value;
    setTemplateText(() => newText);
    if (activeTemplate?.template !== newText) {
      setUnsavedChanges(() => true);
    }
  };

  const denyClose = () => {
    setConfirmUnsavedChanges(false);
    setConfirmDelete(() => null);
    if (denyCloseHandler) {
      denyCloseHandler();
    }
  };

  const confirmClose = (forceClose = false) => {
    setUnsavedChanges(() => false);
    setConfirmUnsavedChanges(() => false);
    setActiveTemplate(() => null);
    setTemplateName(() => "");
    setTemplateText(() => "");
    if (confirmCloseHandler && forceClose) {
      confirmCloseHandler();
    }
  };

  const handleClose = () => {
    if (unsavedChanges) {
      setConfirmUnsavedChanges(() => true);
    } else {
      confirmClose(true);
    }
  };

  useEffect(() => {
    if (templates?.length > 0 && !activeTemplate) {
      handleTemplateSelection(templates[0]);
    }
  }, [templates.map((t) => t.id).join()]);

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Stack
        sx={{
          borderBottom: `solid 1px ${theme.palette.secondary.main}`,
          p: 2,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          component="h3"
          sx={{ fontWeight: 700 }}
          aria-label={Locator.feed.input.templates.modal.name}
        >
          Use a template
        </Typography>
        {includeCloseButton ? (
          <IconButton
            size="small"
            aria-label="close"
            color="primary"
            onClick={handleClose}
          >
            <Close role="img" />
          </IconButton>
        ) : null}
      </Stack>
      <Stack sx={{ flexDirection: "row", height: "100%" }}>
        <Stack
          sx={{
            width: "100%",
            maxWidth: "360px",
            borderRight: `solid 1px ${theme.palette.secondary.main}`,
            height: "100%",
            maxHeight: "585px",
          }}
        >
          <Box sx={{ p: 1 }}>
            <Button
              onClick={handleCreateNewTemplate}
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <Box sx={{ flex: 1, textAlign: "left" }}>New Template</Box>
              <Box sx={{ display: "flex" }}>
                <Add role="img" />
              </Box>
            </Button>
          </Box>
          {loadingTemplates ? (
            <Stack
              sx={{
                px: 2,
                mt: 1,
                width: "100%",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Loading />
            </Stack>
          ) : (
            <List
              sx={{ p: 1, pr: "6px", height: "100%", maxHeight: 525 }}
              className="scrollable-content"
            >
              {templates?.length > 0 ? (
                templates.map((template) => (
                  <ListItem
                    sx={{ marginBottom: "0.5rem", padding: 0 }}
                    key={template.id}
                    aria-label={Locator.feed.input.templates.modal.listItem}
                  >
                    <Button
                      onClick={() => handleTemplateSelection(template)}
                      sx={{
                        background:
                          template.id === activeTemplate?.id &&
                          theme.palette.secondary.main,
                        justifyContent: "start",
                        textAlign: "left",
                        textTransform: "none",
                      }}
                    >
                      <Box
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {template.name}
                      </Box>
                    </Button>
                  </ListItem>
                ))
              ) : (
                <Box sx={{ px: 1 }}>No templates to show</Box>
              )}
            </List>
          )}
        </Stack>

        <Box sx={{ width: "100%", maxWidth: "664px", height: "100%" }}>
          {activeTemplate && !createNewTemplate && (
            <Stack
              component="form"
              onSubmit={(e) => saveActiveTemplate(e)}
              sx={{
                p: 2,
                pr: "6px",
                height: "100%",
              }}
              className="scrollable-content"
            >
              <Stack sx={{ pb: 2, flexDirection: "row" }}>
                <Box sx={{ flex: 1 }}>
                  <FullInput
                    id="newTemplateName"
                    placeholder="Enter a title"
                    autoFocus
                    required
                    className="input-v2"
                    disabled={saving}
                    value={templateName}
                    callback={(e) => handleTemplateName(e.target.value)}
                    aria-label={
                      Locator.feed.input.templates.modal.selectedTitle
                    }
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <LoadingButton
                    loading={saving}
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ mr: 1, mx: 2 }}
                    disabled={
                      saveButtonDisabledState ||
                      saving ||
                      templateText?.length > characterLimit
                    }
                    aria-label={Locator.feed.input.templates.modal.save}
                  >
                    Save
                  </LoadingButton>
                  <IconButton
                    aria-label="Delete Template"
                    onClick={() => setConfirmDelete(() => activeTemplate.id)}
                    disabled={saving}
                    sx={{ width: 44 }}
                  >
                    <TrashOutlined role="img" />
                  </IconButton>
                </Box>
              </Stack>
              <Stack sx={{ pb: 1, height: "100%" }}>
                <FullInput
                  id="activeTemplate"
                  disabled={saving}
                  className="text-input-v2"
                  multiline
                  required
                  placeholder="Start typing your templated message"
                  rows={7}
                  error={templateText?.length > characterLimit}
                  inputProps={{
                    className: "scrollable-content",
                  }}
                  sx={{ height: "100%" }}
                  formControlProps={{
                    sx: { height: "100%" },
                  }}
                  value={templateText}
                  callback={(e) => handleTemplateText(e.target.value)}
                  onKeyDown={onKeyDown}
                  helperText={
                    <TextInputHelper
                      textInput={templateText}
                      limit={characterLimit}
                    />
                  }
                  aria-label={
                    Locator.feed.input.templates.modal.selectedContent
                  }
                />
              </Stack>

              <Stack sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  type="button"
                  disabled={!saveButtonDisabledState || saving}
                  onClick={() => useSelectedTemplate(activeTemplate)}
                  aria-label={Locator.feed.input.templates.modal.use}
                >
                  Use this template
                </Button>
              </Stack>
            </Stack>
          )}

          {createNewTemplate && !activeTemplate ? (
            <Stack
              component="form"
              onSubmit={(e) => saveNewTemplate(e)}
              sx={{ p: 2, height: "100%" }}
            >
              <Stack sx={{ pb: 2 }}>
                <FullInput
                  id="newTemplateName"
                  placeholder="Enter a title"
                  autoFocus
                  required
                  className="input-v2"
                  disabled={saving}
                  value={templateName}
                  callback={(e) => setTemplateName(e.target.value)}
                />
              </Stack>
              <Stack sx={{ pb: 2, height: "100%" }}>
                <FullInput
                  id="createNewTemplate"
                  disabled={saving}
                  className="text-input-v2"
                  multiline
                  required
                  placeholder="Start a typing your templated message"
                  rows={7}
                  inputProps={{
                    className: "scrollable-content",
                  }}
                  sx={{ height: "100%" }}
                  formControlProps={{
                    sx: { height: "100%" },
                  }}
                  value={templateText}
                  error={templateText?.length > characterLimit}
                  callback={(e) => setTemplateText(e.target.value)}
                  onKeyDown={onKeyDown}
                  helperText={
                    <TextInputHelper
                      textInput={templateText}
                      limit={characterLimit}
                    />
                  }
                />
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <LoadingButton
                  sx={{ width: "max-content" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={saving}
                  disabled={
                    (templateName?.length === 0 &&
                      templateText?.length === 0) ||
                    templateText?.length > characterLimit
                  }
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          ) : null}

          {!createNewTemplate && !activeTemplate && templates?.length >= 0 ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  background: theme.palette.secondary.main,
                  display: "block",
                  width: "420px",
                  p: 2,
                  borderRadius: 2,
                  textAlign: "center",
                }}
              >
                <Box>
                  Click <strong>“New template”</strong> to create a templated
                  message.
                </Box>
                <Box>
                  Templated messages can be saved and used in text-to-speech
                  messages.
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Stack>
      <ConfirmDiscardChanges
        id="tts-delete-template-confirmation"
        label="Are you sure?"
        text="Confirm you would like to delete this template. Your content will be lost."
        deleteText="Delete"
        visible={confirmDelete !== null}
        denyCloseHandler={denyClose}
        confirmCloseHandler={() => handleDelete()}
        loading={saving}
      />
      <ConfirmDiscardChanges
        id="tts-discard-unsaved-template-confirmation"
        text="Confirm you would like to discard changes to this template. Your content will be lost."
        visible={confirmUnsavedChanges}
        denyCloseHandler={denyClose}
        confirmCloseHandler={confirmClose}
      />
    </Stack>
  );
}
