import { Box, Typography } from '@mui/material';
import { containerStyle } from './AudioPlayer/styles';
import { toTimeString } from './AudioPlayer/utils';

export function TimeStamp(props: {
  time: number;
  loading?: boolean;
  show?: boolean;
  position?: string;
}) {
  const { time, loading = false, show = true, position = 'left' } = props;

  const defaultTimeStr = '00:00';
  const invalidTimeStr = '--:--';

  if (!show) {
    return null;
  }

  const timeStr = Number.isNaN(time) ? invalidTimeStr : toTimeString(time);

  return (
    <Box sx={{ ...containerStyle.timestamp, textAlign: position }}>
      <Typography>{loading ? defaultTimeStr : timeStr}</Typography>
    </Box>
  );
}
