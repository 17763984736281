import BrowserErrorRefresh from "@/components/ModalForms/BrowserErrorRefresh";
import ModalForm from "@/elements/ModalForm";
import { firebaseConfig, vapidKey } from "@/hooks/firebase/config";
import UseTimeouts from "@/hooks/useTimeouts";
import { AppContext } from "@/models/AppStateProvider";
import { DataContext } from "@/models/DataProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import BasicButtonStyles from "@/theme/BasicButtonStyles";
import NorthIcon from "@mui/icons-material/North";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import Bowser from "bowser";
import { initializeApp as FirebaseApp } from "firebase/app";
import { deleteToken, getMessaging, getToken } from "firebase/messaging";
import { useFlags } from "launchdarkly-react-client-sdk";
import type React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import type { Device } from "../../../web-client/api/data-contracts";
type NotificationTypes = "default" | "granted" | "denied";

export default function UseFirebase(): {
  BrowserCantGetTokenModal: () => React.JSX.Element;
  ActivateFirebaseBanner: () => React.JSX.Element;
  showNotificationsBanner: boolean;
  deleteActiveToken: () => void;
} {
  const theme = useTheme();
  // check if the browser supports service workers and notifications
  const checkForServiceWorker = () => {
    const ServiceWorkerIsSupported = "serviceWorker" in window.navigator;
    const NotificationIsSupported = !!window?.Notification;
    const hasNotifications = "Notification" in window;
    const hasServiceWorker = !!window?.navigator?.serviceWorker;

    return !(
      !hasNotifications ||
      !NotificationIsSupported ||
      !ServiceWorkerIsSupported ||
      !hasServiceWorker
    );
  };

  const { setUserFeedbackModalOpen } = useContext(UxContext);
  const { normalButtonStyles, normalIconStyles } = BasicButtonStyles();
  const { ampli } = useContext(TrackingContext);

  if (!checkForServiceWorker()) {
    ampli.serviceworkerOfNotificationNotAvailable();

    // Return an empty component as a fallback if Notifications are not available
    return {
      showNotificationsBanner: true,
      ActivateFirebaseBanner: () => <></>,
      BrowserCantGetTokenModal: () => (
        <Button
          onClick={() => setUserFeedbackModalOpen(true)}
          sx={{
            ...normalButtonStyles,
            p: "1rem",
            fontSize: "14px",
            fontWeight: "normal",
            textAlign: "left",
            background: theme.palette.secondary.main,
          }}
        >
          Your browser does not support Notifications or ServiceWorkers. Please
          contact support for help.
        </Button>
      ),
      deleteActiveToken: async () => null,
    };
  }

  const { browserCantGetToken } = useFlags();
  const { myAccountId, fetchSingleFeed } = useContext(DataContext);
  const browser = Bowser.getParser(window.navigator.userAgent);
  const [token, setToken] = useState<string | null>(null);
  const [fetchingToken, setFetchingToken] = useState<boolean>(false);
  const [requestNotification, setRequestNotification] =
    useState<boolean>(false);
  const [notificationsState, setNotificationsState] =
    useState<NotificationTypes>(Notification.permission);
  const [tokenCheck, setTokenCheck] = useState<number>(0);
  const [brokenBrowser, setBrokenBrowser] = useState<boolean>(false);
  const { refresh } = UseTimeouts(15);
  const friendlyName = `${browser.getBrowserName()} ${browser.getBrowserVersion()} ${browser.getOSName()} ${browser.getOSVersion()} ${browser.getPlatformType()}`;
  const isEdge = browser
    .getBrowserName()
    .toLowerCase()
    .includes("microsoft edge");
  const { client } = useContext(AppContext);
  const messaging = useRef(null);
  FirebaseApp(firebaseConfig);
  messaging.current = getMessaging();

  useEffect(() => {
    const hook = (event: MessageEvent<any>) => {
      console.log("hook", event.data);
      if (!event.data.action) {
        return;
      }

      switch (event.data.action) {
        case "redirect-from-notificationclick":
          window.open(event.data.url, "_self", "noreferrer");
          break;
        // no default
      }
    };
    navigator.serviceWorker.addEventListener("message", hook);

    return () => {
      navigator.serviceWorker.removeEventListener("message", hook);
    };
  }, []);

  const deleteActiveToken = async () => await deleteToken(messaging.current);

  /**
   * Check our server to see if the token is already registered
   * @param token
   */
  const fetchUserDevice = async (
    token: string,
  ): Promise<Device | undefined> => {
    const userInfo = await client.getUserInfo();
    return userInfo?.devices?.find(
      (device) => device.deviceToken === token && device.enabled,
    );
  };

  /**
   * Send the firebase token to our server, so we know where to send notifications
   * @param token
   */
  const sendTokenToServer = async (token: string): Promise<void> => {
    const activeDevice = await fetchUserDevice(token);
    if (!activeDevice) {
      await client.setPushNotificationToken({
        accountId: myAccountId,
        deviceToken: token,
        friendlyName,
      });
    }
  };

  const asyncFetchToken = async () => {
    try {
      return await getToken(messaging.current, { vapidKey });
    } catch (error) {
      return;
    }
  };
  /**
   * Request a firebase token, then send the token to our server
   */
  const fetchFirebaseToken = async (): Promise<void> => {
    if (token || fetchingToken) {
      return;
    }

    // try to get the token 5 times before failing
    for (const index of [1, 2, 3, 4, 5]) {
      setFetchingToken(() => true);
      const timeout = index * 500;
      await new Promise((resolve) => setTimeout(resolve, timeout));
      const tokenValue = await asyncFetchToken();
      setFetchingToken(() => false);
      if (tokenValue) {
        await sendTokenToServer(tokenValue);
        setToken(() => tokenValue);
        break;
      }
    }
  };

  /**
   * Request access to the browsers notification stack
   * if granted access, then request a firebase token
   */
  const requestDesktopNotifications = async (): Promise<void> => {
    setRequestNotification(() => true);
    const permissions = await Notification.requestPermission();
    setNotificationsState(() => permissions);
    setRequestNotification(() => false);
    if (permissions === "granted") {
      await fetchFirebaseToken();
    }
  };

  // only fetch a firebase token if the state is granted and there is no token
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect((): void => {
    if (notificationsState === "granted" && !token) {
      fetchFirebaseToken();
    }
  }, [token, notificationsState]);

  /**
   * Display a small modal that lets the user know what just happened when they clicked the below banner
   * @constructor
   */
  const NotificationConfirmationBox = (): React.JSX.Element => {
    return (
      <ModalForm
        open={requestNotification}
        onClose={() => setRequestNotification(() => false)}
        allowClose={false}
        maxWidth="380px"
        maxHeight="160px"
        noPadding
        sx={{ p: 2 }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mr: 2, display: "flex", alignItems: "start" }}>
            <Box
              sx={{
                p: 1,
                borderRadius: "0.5rem",
                display: "flex",
                alignItems: "center",
                background: theme.palette.secondary.main,
              }}
            >
              {isEdge ? <NorthIcon /> : <NorthWestIcon />}
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ fontSize: "16px" }}>
              Allow notifications
            </Typography>
            {isEdge ? (
              <Typography
                sx={{ fontSize: "14px", mb: 2 }}
                color={theme.palette.neutral.main}
              >
                To enable notifications on your computer, click “Allow” above.
              </Typography>
            ) : (
              <Typography
                sx={{ fontSize: "14px", mb: 2 }}
                color={theme.palette.neutral.main}
              >
                To enable notifications on your computer, click “Allow” above.
              </Typography>
            )}
            <Box>
              <Button
                sx={{
                  width: "auto",
                  borderRadius: "1rem",
                  py: "1px",
                  px: 2,
                  textTransform: "none",
                  background: theme.palette.brand.info.main,
                }}
                onClick={() => setRequestNotification(() => false)}
              >
                Got It!
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalForm>
    );
  };

  /**
   * If the browser can't retrieve a token for some reason, have the user re-start, which should trigger an update
   * @constructor
   */
  const BrowserCantGetTokenModal = (): React.JSX.Element => {
    useEffect(() => {
      if (brokenBrowser) {
        ampli.browserCantGetToken();
      }
    }, []);

    return (
      <BrowserErrorRefresh
        open={brokenBrowser}
        setOpen={() => {
          setTokenCheck(() => 0);
          setBrokenBrowser(() => false);
        }}
      >
        <Typography sx={{ mb: 1 }}>
          Your browser is having troubles receiving push notifications.
        </Typography>
        <Typography>Please reload the page to continue.</Typography>
      </BrowserErrorRefresh>
    );
  };

  /**
   * Display a banner for a user to click to request notification access
   * @constructor
   */
  const ActivateFirebaseBanner = (): React.JSX.Element => {
    return (
      notificationsState === "default" && (
        <>
          {NotificationConfirmationBox()}
          <Stack sx={{ height: "80px" }}>
            <Button
              onClick={requestDesktopNotifications}
              sx={{
                py: 1.25,
                display: "flex",
                "&:hover": { background: theme.palette.brand.info.main },
                alignItems: "center",
                textAlign: "left",
                textTransform: "initial",
                justifyContent: "start",
                background: theme.palette.brand.info.main,
                borderRadius: "1rem",
              }}
            >
              <Box sx={{ flexGrow: 1, pl: 0.5 }}>
                <Typography sx={{ fontSize: "16px", mb: 0.5 }}>
                  Turn on desktop notifications
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  Get notified of new messages.
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 1.5,
                  borderRadius: "0.75rem",
                  display: "flex",
                  alignItems: "center",
                  background: theme.palette.brand.info.dark,
                }}
              >
                <NotificationsOffIcon />
              </Box>
            </Button>
          </Stack>
        </>
      )
    );
  };

  useEffect(() => {
    if (notificationsState !== "granted" || !browserCantGetToken) {
      return;
    }
    if (refresh === true && !token) {
      setTokenCheck((value) => value + 1);
    }
    if (refresh === true && !token && tokenCheck >= 4) {
      setBrokenBrowser(() => true);
    }
    if (token) {
      // console.log(`%c${token}`, "background:#333;color:white;padding:10px;");
    }
  }, [refresh, tokenCheck, token, notificationsState, browserCantGetToken]);

  return {
    ActivateFirebaseBanner,
    BrowserCantGetTokenModal,
    deleteActiveToken,
    showNotificationsBanner: notificationsState === "default",
  };
}
