export type ResponseError = {
	error?: Error;
	data?: Object;
	status: Number;
	statusText?: String;
};

export async function handleErrorResponse(response) {
	let body;
	// check if the response hasnt been read yet
	if (
		!response.bodyUsed &&
		response.json &&
		typeof response.json === "function"
	) {
		body = await response.json();
	}
	console.log(response);
	return {
		error: response?.error,
		status: response?.status,
		statusText: response?.statusText,
		data: { ...body },
	} as ResponseError;
}
