import { Electric } from '@/generated/client';
import { FullItem } from "@/components/FeedMap";
import { LiveResultContext } from 'electric-sql/dist/client/model/model';
import { ResultData } from 'electric-sql/dist/frameworks/react/hooks/useLiveQuery';
import { DependencyList } from 'react';

export type FeedItemEvent = {
    id: string;
    accountId: string;
    name?: string;
    createdAt: string;
    feedId: string;
    itemId: string;
    contentId?: string;
};

type queryType<Res> = (
  runQueryFn: () => LiveResultContext<Res>,
  dependencies: DependencyList
) => ResultData<Res>;

export function getLatestFeedItemEvents<Res>(
  useLiveQuery: queryType<Res>,
  db: Electric['db'],
  { item }: { item: FullItem }
) {
 const { results: latestFeedItemEvents, error } = useLiveQuery(() => {
   if (!item.feedId || !item.id) return;
		
		return db.liveRaw({
			sql: `SELECT t1.* FROM 
		(
			SELECT feedId, accountId, itemId, name, MAX(createdAt) as max from account_event WHERE feedId = ? AND itemId = ?
			group by feedId, accountId, itemId, name
		) as t2
		join account_event as t1 on 
		t1.feedId = t2.feedId and 
		t1.accountId = t2.accountId and 
		t1.itemId = t2.itemId and 
		t1.name = t2.name and
		t1.createdAt = t2.max
		ORDER BY createdAt DESC`,
			args: [item.feedId, item.id],
		});
}, [item])

  return { latestFeedItemEvents, error } as {
    latestFeedItemEvents: FeedItemEvent[];
    error: any;
  };
}


