import {
  Box,
  Fade,
  Modal,
  Stack,
  Typography,
  useTheme,
  ModalProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton/IconButton";

export const MODAL_DEFAULT_Z_INDEX = 2300;

export type ModalFormProps = ModalProps & {
  title?: string;
  allowClose?: boolean;
  disableClose?: boolean;
  maxWidth?: number | string;
  maxHeight?: number | string;
  contentPosition?: string;
  noPadding?: boolean;
  footerSlot?: React.ReactNode;
};

export default function ModalForm({
  children,
  onClose,
  maxWidth,
  maxHeight,
  disableClose,
  allowClose = true,
  contentPosition = "center",
  noPadding,
  footerSlot,
  ...props
}: ModalFormProps) {
  const theme = useTheme();
  const styles = () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: contentPosition,
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    maxWidth: { xs: "100%", sm: maxWidth ?? 600 },
    maxHeight: { xs: "100%", sm: maxHeight ?? 600 },
    ":focus-visible": {
      outline: "none",
      outlineOffset: 0,
      boxShadow: "none",
    },
  });

  const contentStyles = (additionalStyles?: any) => {
    return {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "24px",
      padding: noPadding ? 0 : { xs: 2, sm: "40px 70px" },
      pr: noPadding ? 0 : { xs: "6px", sm: "60px" },
      height: { xs: "100%", sm: "auto" },
      width: "100%",
      background: theme.palette.secondary.dark,
      borderRadius: { xs: "0px", sm: "16px" },
      outline: "none",
      overflow: "hidden",
      "& .full-button-width .MuiButton-root": {
        padding: 0,
        flexDirection: "row",
      },
      ":focus-visible": {
        outline: "none",
        outlineOffset: 0,
        boxShadow: "none",
      },
      ...additionalStyles,
    };
  };

  const handleClose = (
    event: object,
    reason: "backdropClick" | "escapeKeyDown",
  ) => {
    if (!disableClose) {
      onClose(event, reason);
    }
  };

  return (
    <Modal
      role="dialog"
      open={props.open}
      onClose={handleClose}
      {...props}
      aria-modal
      aria-hidden={!props.open}
      closeAfterTransition
      slotProps={{
        backdrop: {
          ...props.slotProps?.backdrop,
          timeout: 300,
        },
      }}
      sx={{
        zIndex: MODAL_DEFAULT_Z_INDEX,
        ".MuiBackdrop-root": {
          backdropFilter: "blur(8px)",
        },
      }}
    >
      <Fade in={props.open}>
        <Box sx={styles}>
          <Box
            id={`${props.id}-modal-content`}
            sx={[
              contentStyles(props?.sx),
              ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
            ]}
            className="scrollable-content"
          >
            {allowClose ? (
              <IconButton
                size="small"
                aria-label="close"
                color="primary"
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  zIndex: MODAL_DEFAULT_Z_INDEX,
                }}
                onClick={(e) => handleClose(e, "escapeKeyDown")}
                disabled={disableClose}
              >
                <CloseIcon role="img" />
              </IconButton>
            ) : null}
            {props.title && (
              <Typography variant="h6" component="h2">
                {props.title}
              </Typography>
            )}
            {children}
          </Box>
          {footerSlot && (
            <Box sx={{ pt: 1.5, width: "100%" }}>{footerSlot}</Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
