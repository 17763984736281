import { SVGProps } from "react";
export default function FolderOutlined({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.348633 12.0234V5.80469H18.2549V12.0234C18.2549 12.8568 18.0465 13.487 17.6299 13.9141C17.2132 14.3464 16.6325 14.5625 15.8877 14.5625H2.91113C2.06738 14.5625 1.42936 14.3464 0.99707 13.9141C0.564779 13.487 0.348633 12.8568 0.348633 12.0234ZM0.348633 4.67969V2.5C0.348633 1.67708 0.551758 1.05729 0.958008 0.640625C1.36947 0.21875 1.93978 0.0078125 2.66895 0.0078125H4.87988C5.15072 0.0078125 5.38509 0.0260417 5.58301 0.0625C5.78092 0.0989583 5.96322 0.161458 6.12988 0.25C6.29655 0.333333 6.47103 0.450521 6.65332 0.601562L7.09863 0.96875C7.31738 1.14062 7.52311 1.26302 7.71582 1.33594C7.90853 1.40885 8.14551 1.44531 8.42676 1.44531H15.6924C16.5309 1.44531 17.1663 1.66146 17.5986 2.09375C18.0361 2.52604 18.2549 3.15885 18.2549 3.99219V4.67969H0.348633Z"
        fill="currentColor"
      />
    </svg>
  );
}
