import { useNavigate } from "react-router-dom";
import RootLayout from "@/routes/layout";
import { useErrorBoundary } from "react-error-boundary";
import { Stack, Button, useTheme } from "@mui/material";

export default function ErrorFallback({ error }) {
	const theme = useTheme();
	const navigate = useNavigate();
	const { resetBoundary } = useErrorBoundary();

	let message;

	if (error) {
		switch (error.status) {
			case 401:
				message = (
					<p>
						Oops! Looks like you tried to visit a feed or page that you do not
						have access to.
					</p>
				);
				break;
			case 404:
				message = (
					<p>
						Oops! Looks like you tried to visit a feed or page that does not
						exist.
					</p>
				);
				break;
			case 418:
				message = (
					<p>
						We are currently performing system maintenance. Please check back in
						a few minutes.
					</p>
				);
				break;

			default:
				message = <p>{error?.data?.message || "Something went wrong"}</p>;
		}
	}

	return (
		<RootLayout>
			<Stack
				sx={{
					alignItems: "center",
					justifyContent: "center",
					height: "100dvh",
					px: 2,
					backgroundColor: theme.palette.primary.dark,
					backgroundImage:
						"url(https://storyboard-fm.cdn.prismic.io/storyboard-fm/d3c6f3e4-ffa5-4bb2-9564-5378b56356a0_Login+Background.svg)",
					backgroundPosition: "50%",
					backgroundSize: "cover",
				}}
				role="alert"
			>
				{error ? <h1>Error {error?.status}</h1> : <h1>Something went wrong</h1>}
				{message}
				<Stack
					sx={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						gap: 2,
					}}
				>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						sx={{ maxWidth: "max-content" }}
						onClick={() => {
							navigate("/");
						}}
					>
						Go Back
					</Button>
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{ maxWidth: "max-content" }}
						onClick={() => {
							resetBoundary();
						}}
					>
						Try Again
					</Button>
				</Stack>
			</Stack>
		</RootLayout>
	);
}
