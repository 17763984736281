import AvatarWithStatus from "@/components/AvatarWithStatus";
import { useElectric } from "@/electric/ElectricWrapper";
import useSwipeToDismiss from "@/hooks/useSwipeToDismiss";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { Close } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useContext, useMemo } from "react";
import { useHover } from "react-aria";
import { useNavigate } from "react-router-dom";
import PlaybackIndicator from "./PlaybackIndicator/Index";

export interface ToastPlayerProps {
  onClose?: () => void;
}

export default function ToastPlayer({ onClose }: ToastPlayerProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { db } = useElectric();
  const { queuePlaying, playQueue, pauseQueue } = useContext(AudioAppContext);

  const { results: currentQueueItem } = useLiveQuery(
    db.audio_queue_item.liveFirst({
      orderBy: {
        createdAt: "asc",
      },
    }),
  );

  const { results: currentItem } = useLiveQuery(() => {
    if (!currentQueueItem?.itemId) return;
    return db.item.liveFirst({
      where: {
        id: currentQueueItem?.itemId,
      },
    });
  }, [currentQueueItem?.id]);

  const { results: currentItemsFeed } = useLiveQuery(() => {
    if (!currentItem?.feedId) return;
    return db.feed.liveFirst({
      where: {
        id: currentItem?.feedId,
      },
    });
  }, [currentItem?.feedId]);

  const { results: currentItemWorkspace } = useLiveQuery(() => {
    if (!currentItemsFeed?.workspaceId) return;
    return db.workspace.liveFirst({
      where: {
        id: currentItemsFeed?.workspaceId,
      },
    });
  }, [currentItemsFeed?.workspaceId]);

  const { results: userInfo } = useLiveQuery(() => {
    if (!currentItem?.accountId) return;
    return db.account.liveFirst({
      where: {
        id: currentItem?.accountId,
      },
    });
  }, [currentItem?.accountId]);

  const itemUrl = useMemo(() => {
    if (currentItem && currentItemsFeed) {
      return `/workspaces/${currentItemsFeed?.workspaceId}/feeds/${currentItemsFeed?.id}#${currentItem?.id}`;
    }
  }, [currentItem, currentItemsFeed]);

  const swipeHandlers = useSwipeToDismiss({
    onDismiss: () => {
      if (onClose) {
        onClose();
      }
    },
  });
  const { hoverProps, isHovered } = useHover({});

  const handleTap = () => {
    if (itemUrl) {
      navigate(itemUrl);
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        maxWidth: 400,
        height: 60,
        width: "100%",
        p: 1.25,
        alignItems: "center",
        background: theme.palette.secondary.dark,
        border: `1px solid ${theme.palette.brand.other.green.main}`,
        borderRadius: "65px",
        boxShadow:
          "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 8px 12px rgba(0, 0, 0, 0.15)",
        cursor: "pointer",
      }}
      {...swipeHandlers}
      {...hoverProps}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          sx={{ alignItems: "center", width: "100%" }}
          onClick={handleTap}
        >
          <AvatarWithStatus accountId={userInfo?.id} />
          <Stack sx={{ pl: 2, pr: 1, py: 0.25 }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              {userInfo?.name}
            </Typography>
            <Typography
              className="text-ellipsis"
              sx={{
                fontSize: "0.875rem",
                color: theme.palette.brand.other.gray.main,
              }}
            >
              {currentItemWorkspace?.name} • {currentItemsFeed?.title}
            </Typography>
          </Stack>
        </Stack>
        {isHovered ? (
          <IconButton
            color="primary"
            onClick={onClose}
            aria-label="close toast player"
            sx={{ borderRadius: "100px" }}
          >
            <Close role="img" />
          </IconButton>
        ) : (
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "0 0 auto",
              width: 44,
              height: 44,
              borderRadius: "100px",
              p: 1,
            }}
          >
            <PlaybackIndicator
              playing={queuePlaying}
              variant="green"
              sx={{
                width: 24,
                height: 24,
              }}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
