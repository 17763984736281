import type { SVGProps } from "react";
export default function ListIcon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>list-icon</title>
      <path
        d="M0 10V8H2V10H0ZM0 6V4H2V6H0ZM0 2V0H2V2H0ZM4 10V8H8V10H4ZM4 6V4H17V6H4ZM4 2V0H17V2H4ZM13.95 14L9.7 9.75L11.125 8.325L13.95 11.15L19.6 5.5L21 6.95L13.95 14Z"
        fill="white"
      />
    </svg>
  );
}
