import { Box, BoxProps } from "@mui/material";
import "./playback-indicator.css";

type Props = BoxProps & {
	playing: boolean;
	variant?: string;
};

export default function PlaybackIndicator({
	playing,
	variant = "primary",
	sx,
}: Props) {
	const classes = `playback-indicator ${variant}`;

	return (
		<Box
			className={classes}
			data-playing={playing}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: 22,
				width: 40,
				...sx,
			}}
		>
			<div className="playing playing-bar1"></div>
			<div className="playing playing-bar2"></div>
			<div className="playing playing-bar3"></div>
		</Box>
	);
}
