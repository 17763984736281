import {
  createManyWorkspaceMembership,
  createManyWsAccount,
  createManyWsCommandAliases,
  createManyWsEvent,
  createManyWsFeed,
  createManyWsFeedGroup,
  createManyWsFeedGroupMembership,
  createManyWsItem,
  createManyWsPermission,
  createManyWsTemplate,
} from "@/data/workspace";
import { useElectric } from "@/electric/ElectricWrapper";
import { Electric } from "@/generated/client";
import { AppContext } from "@/models/AppStateProvider";
import {
  downloadOldestFeedItemEvents,
  fetchFeedItemsAndDownloadEvents,
} from "@/models/actions/bootstrap";
import {
  downloadFeedItemContent,
  initialUnreadItems,
} from "@/models/actions/initialFeedLoad";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  UserInfoResponse,
  WsEvent,
  WsFeedGroupMembership,
  BootstrapWorkspaceAccounts,
  BootstrapWorkspaceFeedGroupMemberships,
  BootstrapWorkspaceFeedGroups,
  BootstrapWorkspaceFeedItems,
  BootstrapWorkspaceFeedPermissions,
  BootstrapWorkspaceFeeds,
  BootstrapWorkspaceMemberships,
  BootstrapWorkspaceTemplates,
  BootstrapLatestUnreadEventPerFeed,
  WorkspaceMembership,
  WsAccount,
  WsCommandAlias,
  WsFeed,
  WsFeedGroup,
  WsItem,
  WsPermission,
  WsTemplate,
} from "../../web-client/api/data-contracts";
import Client from "../../web-client/client";

export type BootstrapResponses =
  | BootstrapWorkspaceFeedItems
  | BootstrapWorkspaceMemberships
  | BootstrapWorkspaceFeedPermissions
  | BootstrapWorkspaceFeeds
  | BootstrapWorkspaceTemplates
  | BootstrapWorkspaceAccounts
  | BootstrapWorkspaceFeedGroups
  | BootstrapWorkspaceFeedGroupMemberships
  | BootstrapLatestUnreadEventPerFeed;

export type CreateManyPayloads =
  | WorkspaceMembership[]
  | WsItem[]
  | WsPermission[]
  | WsFeed[]
  | WsTemplate[]
  | WsAccount[]
  | WsCommandAlias[]
  | WsFeedGroup[]
  | WsFeedGroupMembership[]
  | WsEvent[];

export type BootstrapEventListType = {
  bootstrapFunctionName: string;
  bootstrapFunctionCallResponseName: string;
  createManyFunctionName: (
    db: Electric["db"],
    values: CreateManyPayloads,
  ) => Promise<any>;
  feed?: boolean;
  wait?: boolean;
  pageSize?: number;
};

export const bootstrappedApiCalls: BootstrapEventListType[] = [
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeeds",
    createManyFunctionName: createManyWsFeed,
    bootstrapFunctionCallResponseName: "feeds",
    feed: true,
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedItemsPaginated",
    createManyFunctionName: createManyWsItem,
    bootstrapFunctionCallResponseName: "items",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceMemberships",
    createManyFunctionName: createManyWorkspaceMembership,
    bootstrapFunctionCallResponseName: "workspaceMemberships",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedPermissions",
    createManyFunctionName: createManyWsPermission,
    bootstrapFunctionCallResponseName: "permissions",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceTemplates",
    createManyFunctionName: createManyWsTemplate,
    bootstrapFunctionCallResponseName: "templates",
    pageSize: 100,
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceAccounts",
    createManyFunctionName: createManyWsAccount,
    bootstrapFunctionCallResponseName: "accounts",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedGroups",
    createManyFunctionName: createManyWsFeedGroup,
    bootstrapFunctionCallResponseName: "feedGroups",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceFeedGroupMemberships",
    createManyFunctionName: createManyWsFeedGroupMembership,
    bootstrapFunctionCallResponseName: "feedGroupMemberships",
  },
  {
    bootstrapFunctionName: "bootstrapWorkspaceCommandAliases",
    createManyFunctionName: createManyWsCommandAliases,
    bootstrapFunctionCallResponseName: "commandAliases",
  },
  {
    bootstrapFunctionName: "latestUnreadEventPerFeed",
    createManyFunctionName: createManyWsEvent,
    bootstrapFunctionCallResponseName: "events",
  },
  {
    bootstrapFunctionName: "latestUnreadEventPerFeedForOrganizers",
    createManyFunctionName: createManyWsEvent,
    bootstrapFunctionCallResponseName: "events",
  },
];

export default function BootstrapApplication() {
  const params = useParams();
  const { client } = useContext(AppContext);
  const { db } = useElectric();
  const [bootstrapLoading, setBootstrapLoading] = useState<boolean>(false);
  const [allComplete, setAllComplete] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [feedIds, setFeedIds] = useState<string[]>([]);
  const [activeWorkspaceId, setActiveWorkspaceId] = useState<string | null>(
    null,
  );

  const recursiveBootstrapCall = async ({
    workspaceId,
    client,
    db,
    bootstrapEvent,
    page,
    pageSize = 10000,
  }: {
    workspaceId: string;
    client: Client;
    db: Electric["db"];
    bootstrapEvent: BootstrapEventListType;
    page: number;
    pageSize: number;
  }) => {
    const additionalResponse: BootstrapResponses = await client[
      bootstrapEvent.bootstrapFunctionName
    ]({
      workspaceId,
      page,
      pageSize,
    });

    const responseData =
      additionalResponse[bootstrapEvent.bootstrapFunctionCallResponseName];

    if (bootstrapEvent.feed) {
      const feeds: WsFeed[] = responseData as WsFeed[];
      const idMap = feeds?.map((feed) => feed.id);
      setFeedIds((prev) => [...prev, ...idMap]);
    }

    if (responseData?.length > 0) {
      bootstrapEvent.createManyFunctionName(db, responseData);
    } else {
      console.log(
        "No data found for bootstrap event",
        bootstrapEvent.bootstrapFunctionName,
        bootstrapEvent.bootstrapFunctionCallResponseName,
        additionalResponse,
      );
    }

    if (responseData?.length >= pageSize) {
      return await recursiveBootstrapCall({
        workspaceId,
        client,
        db,
        bootstrapEvent,
        page: page + 1,
        pageSize,
      });
    } else {
      return true;
    }
  };

  const bootstrapPaginatedApplication = async ({
    client,
    db,
    user,
    workspaceId,
    forceBootstrap,
  }: {
    client: Client;
    db: Electric["db"];
    user: UserInfoResponse;
    workspaceId?: string;
    forceBootstrap: boolean;
  }) => {
    const userId =
      user?.session?.credentials[0]?.CredentialScopes[0]?.accountId;
    setUserId(() => userId);

    if (user?.workspaces?.length === 0) {
      console.error("INVALID USER. PLEASE CONTACT SUPPORT");
      return;
    }
    if (bootstrapLoading && !forceBootstrap) {
      return;
    }
    setAllComplete(() => false);
    const localActiveWorkspaceId = workspaceId ?? user.workspaces[0].id;
    setActiveWorkspaceId(() => localActiveWorkspaceId);
    setBootstrapLoading(() => true);
    const bootstrapPromises = [];
    if (bootstrappedApiCalls?.length > 0) {
      for (const bootstrapEvent of bootstrappedApiCalls) {
        bootstrapPromises.push(
          recursiveBootstrapCall({
            workspaceId,
            client,
            db,
            bootstrapEvent,
            page: 0,
            pageSize: bootstrapEvent.pageSize,
          }),
        );
      }
    }

    await Promise.all(bootstrapPromises);

    setAllComplete(() => true);
  };

  useEffect(() => {
    const updateLocalFeed = async () => {
      for (const id of feedIds) {
        initialUnreadItems(db, id, userId, params.workspaceId);
        // if you are on a feed, load that data in the bootstrap
        if (params?.feedId && params?.workspaceId && id === params?.feedId) {
          fetchFeedItemsAndDownloadEvents(client, db, id, params.workspaceId);
        }
        setFeedIds((prev) => prev.filter((feedId) => feedId !== id));
      }
      return feedIds?.length;
    };

    if (feedIds?.length > 0 && userId && allComplete) {
      setAllComplete(() => false);
      updateLocalFeed().then(() => {
        setAllComplete(() => true);
      });
    }
  }, [feedIds, userId, db, params, client, allComplete]);

  return {
    allComplete,
    bootstrapPaginatedApplication,
    activeWorkspaceId,
  };
}
