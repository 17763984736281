import { Item } from "@/generated/client";
import { DataContext } from "@/models/DataProvider";
import { format } from "date-fns";
import { useContext, useMemo } from "react";

export interface FullItem {
  id: string;
  feedId: string;
  contentId: string;
  groupId?: string;
  deletedAt?: string;
  createdAt: string;
  accountId: string;
  status: string;
  mine: boolean;
  label: string;
  key: string;
  feedItemIndex: number;
  lastActiveItem: boolean;
  isFirstDateGroupItem: boolean;
  dateGroupLabel: string;
  lastActiveItemIdIsMine: boolean;
  shortDateStr: string;
  loadedContent: number;
  url?: string;
  isSilent?: number;
}

type IndexedItem = Item & {
  shortDateStr: string;
  formattedTime: string;
  feedItemIndex: number;
};

export const FeedMap = ({
  items,
  feedId,
}: { items: Item[]; feedId: string }): FullItem[] => {
  const { myAccount } = useContext(DataContext);

  const itemIds = items?.map((item) => item.id);

  return useMemo(() => {
    const dateFormat = (publishedDate) => {
      const date = new Date(publishedDate);
      const dateFormat = "MMM d, yyyy";
      const shortDateStr = format(date, "MMddyyyy");
      const formattedDate = format(date, dateFormat);
      const formattedTime = format(date, "h:mm aa");
      return {
        shortDateStr,
        formattedDate,
        formattedTime,
      };
    };

    const indexedItems = new Map<string, IndexedItem[]>();

    items?.forEach((feedItem, index) => {
      const { shortDateStr, formattedTime } = dateFormat(feedItem.createdAt);

      const item = {
        ...feedItem,
        shortDateStr,
        feedItemIndex: index,
        formattedTime,
      };
      if (indexedItems.has(shortDateStr)) {
        indexedItems.set(shortDateStr, [
          ...indexedItems.get(shortDateStr),
          item,
        ]);
      } else {
        indexedItems.set(shortDateStr, [item]);
      }
    });

    const ret =
      items?.map((feedItem, index): FullItem => {
        const { shortDateStr, formattedTime } = dateFormat(feedItem.createdAt);

        const firstItemForDate = indexedItems.get(shortDateStr)?.[0];

        const firstItem = dateFormat(firstItemForDate.createdAt);

        const isFirstDateGroupItem = firstItemForDate.id === feedItem.id;
        const dailyLabel = firstItem.formattedDate;
        const dateGroupLabel = isFirstDateGroupItem ? dailyLabel : "";

        const lastActiveItem = items[items.length - 1]?.id === feedItem.id;

        const lastActiveItemIdIsMine =
          lastActiveItem && feedItem?.accountId === myAccount?.id;
        return {
          id: feedItem.id,
          feedId: feedItem.feedId,
          contentId: feedItem.contentId,
          groupId: feedItem.groupId,
          deletedAt: feedItem.deletedAt,
          createdAt: feedItem.createdAt,
          accountId: feedItem.accountId,
          status: feedItem.status,
          mine: feedItem.accountId === myAccount?.id,
          label: `${dailyLabel} @ ${formattedTime}`,
          key: feedItem.createdAt,
          isFirstDateGroupItem,
          dateGroupLabel,
          feedItemIndex: index++,
          lastActiveItem,
          lastActiveItemIdIsMine,
          shortDateStr,
          loadedContent: feedItem.loadedContent,
          url: `${window?.location?.href?.split("#")[0]}#${feedItem?.id}` || "",
          ...feedItem,
        };
      }) || [];
    return ret;
  }, [itemIds, myAccount?.id]);
};
