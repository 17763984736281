import { Account, Permission, WorkspaceMembership } from "@/generated/client";

export type AccountInfo = Account & {
	initials?: string;
	isAdmin?: boolean;
	isMuted?: boolean;
	permissions?: Array<Permission>;
	workspaceMembership?: WorkspaceMembership;
};

export function initialsFromAccount(account: Account) {
	let initials = account.id.slice(0, 4);
	if (account.firstName && account.lastName) {
		initials = [account.firstName[0], account.lastName[0]].join("");
	} else {
		if (account.name) {
			initials = account.name
				.split(" ")
				.map((n) => n[0])
				.join("");
		}
	}
	return initials;
}

export default function (accounts: Account[]) {
	const currentFeedAccountInfo: Map<string, AccountInfo> = new Map();

	for (const account of accounts || []) {
		const initials = initialsFromAccount(account);
		currentFeedAccountInfo.set(account.id, {
			...account,
			initials,
		});
	}

	return currentFeedAccountInfo;
}
