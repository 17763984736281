import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AuthSuccess() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const returnTo = searchParams.get("returnTo");

	useEffect(() => {
		navigate(returnTo ? returnTo : "/");
	}, [navigate, returnTo]);
	return null;
}
