import { Box, Link, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { IconForFile } from "@/components/FileIcons";
import { File } from "@/generated/client";

export default function FileFragment(file: File) {
	const mimeSplit = file?.mimeType?.split("/");
	const type = mimeSplit[0];
	const subtype = mimeSplit[1];

	let fragment;
	if (type === "image") {
		fragment = (
			<Link href={file.url} target="_blank">
				<img
					src={file.url}
					alt={file.name}
					style={{ maxWidth: "100%", height: "auto" }}
					loading="lazy"
				/>
			</Link>
		);
	} else if (subtype === "pdf") {
		fragment = (
			<Box sx={{ width: "100%", display: "block" }}>
				<object
					width="100%"
					height="400"
					data={file.url}
					type="application/pdf"
					aria-label={`View PDF: ${file.name}`}
				>
					<Link
						sx={{
							background: "#fff",
							display: "block",
							width: "100%",
							height: "100%",
						}}
						href={file.url}
						target="_blank"
						rel="noreferrer"
					>
						{file.name}
					</Link>
				</object>
			</Box>
		);
	} else if (type === "video") {
		fragment = (
			<video controls preload="metadata" width="100%">
				<source src={`${file.url}#t=0.001`} />
			</video>
		);
	}

	return (
		<>
			{fragment ? (
				fragment
			) : (
				<Link
					href={file.url}
					target="_blank"
					rel="noreferrer"
					sx={{
						width: "100%",
						display: "flex",
						alignItems: "center",
					}}
				>
					<ListItemIcon>
						{IconForFile({ mimeType: file.mimeType })}
					</ListItemIcon>
					<ListItemText sx={{ wordBreak: "break-all" }} primary={file.name} />
				</Link>
			)}
		</>
	);
}
