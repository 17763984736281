import { MutableRefObject, createContext, useRef, useState } from "react";
import { SetStateType } from "@/utils";

export type UserManagementState = {
  activeSearchField?: MutableRefObject<any>;
  handleSearchFilter: (e: any) => void;
  searchFilter?: string;
  setSearchFilter: SetStateType<string>;
};

export const UserManagementContext = createContext<UserManagementState>({
  setSearchFilter: () => {},
  handleSearchFilter: () => {},
});

const UserManagementContextProvider = ({ children }) => {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const activeSearchField = useRef(null);

  const handleSearchFilter = (e) => {
    if (e.quickFilterValues) {
      const searchText = e.quickFilterValues?.join(" ");
      setSearchFilter(searchText);
      // if the search field is empty, focus on it
      if (activeSearchField.current && !searchText) {
        activeSearchField.current?.focus();
      }
    }
  };

  const userManagementState: UserManagementState = {
    activeSearchField,
    handleSearchFilter,
    searchFilter,
    setSearchFilter,
  };
  return (
    <UserManagementContext.Provider value={userManagementState}>
      {children}
    </UserManagementContext.Provider>
  );
};

export default UserManagementContextProvider;
