import { TrackingContext } from "@/models/TrackingStateProvider";
import { Button } from "@mui/material";
import { useContext } from "react";
import { FeedContext } from "@/models/FeedContextProvider";
import FileUploadModal from "./FileUploadModal";
import { ButtonProps } from "@mui/material";

export type UploadFileButtonProps = ButtonProps & {};

export default function UploadFileButton({
	children,
	...props
}: UploadFileButtonProps) {
	const { ampli } = useContext(TrackingContext);
	const { setFileUploadModal } = useContext(FeedContext);

	const toggleFileUpload = () => {
		ampli.fileUploadInvoke();
		setFileUploadModal((value) => !value);
	};

	return (
		<>
			<FileUploadModal />
			<Button onClick={toggleFileUpload} aria-label="Upload File" {...props}>
				{children}
			</Button>
		</>
	);
}
