import Loading from "@/components/Loading/Index";
import StoryboardChat from "@/components/StoryboardChat";
import ModalForm from "@/elements/ModalForm";
import UseFirebase from "@/hooks/firebase/useFirebase";
import UseStorage from "@/hooks/useStorage";
import Locator from "@/locator";
import { AppContext } from "@/models/AppStateProvider";
import { Box, Button, Link, Typography } from "@mui/material";
import React, { useState } from "react";

export default function NoValidWorkspacesModal({ open }: { open: boolean }) {
  const { client } = React.useContext(AppContext);
  const { clearLocalStorage } = UseStorage();
  const { deleteActiveToken } = UseFirebase();
  const [loggingOut, setLoggingOut] = useState<boolean>(false);
  const logout = async () => {
    setLoggingOut(() => true);
    deleteActiveToken();
    clearLocalStorage();
    client.logout();
  };

  return (
    <>
      <ModalForm
        onClose={() => {}}
        open={loggingOut}
        allowClose={false}
        sx={{ background: "transparent" }}
      >
        <>
          <Box>
            <StoryboardChat />
          </Box>
          <Box>
            <Loading size="large" />
          </Box>
        </>
      </ModalForm>
      <ModalForm
        onClose={() => {}}
        id="no-valid-workspaces-modal"
        open={open && !loggingOut}
        allowClose={false}
        sx={{ padding: "40px 60px" }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 2, fontWeight: 700 }}
          >
            You are not a member of any workspaces
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Contact your system administrator to invite you to your company’s
            workspace, or{" "}
            <Link
              sx={{ color: "#26B0EB", cursor: "pointer" }}
              onClick={() => logout()}
            >
              log out
            </Link>{" "}
            and sign in with a different credential.
          </Typography>
          <Button
            href="https://storyboard.com/speak-with-a-product-specialist?utm_campaign=FY23%20%7C%20In-app%20-%20Hands%20Free%20Upsell&utm_source=In-app-upsell&utm_medium=new-workspace"
            variant="contained"
            aria-label={Locator.blankSlate.requestDemo.button}
          >
            Request A Demo
          </Button>
        </Box>
      </ModalForm>
    </>
  );
}
