import React from "react";
import Loading from "@/components/Loading/Index";
import { Stack, useTheme } from "@mui/material";
import { UxContext } from "@/models/UxStateProvider";

export default function LoadingScreen() {
	const { isSmUp } = React.useContext(UxContext);
	const theme = useTheme();

	return (
		<Stack
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100dvh",
				backgroundColor: theme.palette.primary.dark,
				backgroundImage:
					"url(https://storyboard-fm.cdn.prismic.io/storyboard-fm/d3c6f3e4-ffa5-4bb2-9564-5378b56356a0_Login+Background.svg)",
				backgroundPosition: "50%",
				backgroundSize: "cover",
			}}
		>
			<img
				src="https://storyboard-fm.cdn.prismic.io/storyboard-fm/ad3cdba4-3291-4537-899f-c8f1dac24da5_sb_chat_logo.svg"
				alt="Chat by storyboard logo"
				loading="lazy"
				style={{ maxWidth: isSmUp ? "100%" : "260px" }}
			/>
			<Loading size={isSmUp ? "large" : "small"} />
		</Stack>
	);
}
