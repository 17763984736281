import { SVGProps } from "react";

export default function StoryboardChat({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="464"
      height="100"
      viewBox="0 0 464 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M88.8806 88.3457C86.3878 88.3302 83.9248 87.8107 81.6442 86.8195C79.3636 85.8283 77.314 84.3865 75.6225 82.5835C72.2251 78.9548 70.3499 74.1936 70.3741 69.2582V35.7794C70.353 34.8817 70.5144 33.9888 70.8487 33.1534C71.183 32.3179 71.6836 31.5566 72.3209 30.9144C72.9583 30.2721 73.7196 29.7618 74.5601 29.4133C75.4006 29.0649 76.3033 28.8854 77.2153 28.8854C78.1272 28.8854 79.03 29.0649 79.8705 29.4133C80.711 29.7618 81.4723 30.2721 82.1096 30.9144C82.747 31.5566 83.2475 32.3179 83.5818 33.1534C83.9162 33.9888 84.0775 34.8817 84.0564 35.7794V69.2474C84.0405 70.7814 84.6065 72.266 85.6438 73.4107C86.4116 74.2516 87.4871 74.7579 88.6339 74.818C89.7808 74.8781 90.9048 74.4872 91.7589 73.7312C91.8729 73.6298 91.9815 73.5228 92.0845 73.4107C93.1184 72.2671 93.6829 70.7856 93.6681 69.2546V7.05809C93.647 6.16034 93.8084 5.2675 94.1427 4.43203C94.4771 3.59656 94.9776 2.83532 95.6149 2.19305C96.2523 1.55078 97.0136 1.04043 97.8541 0.691993C98.6946 0.343558 99.5974 0.164062 100.509 0.164062C101.421 0.164062 102.324 0.343558 103.165 0.691993C104.005 1.04043 104.766 1.55078 105.404 2.19305C106.041 2.83532 106.542 3.59656 106.876 4.43203C107.21 5.2675 107.372 6.16034 107.35 7.05809V69.2474C107.381 74.1813 105.511 78.9431 102.117 82.5727C100.429 84.3752 98.3832 85.8176 96.1065 86.8106C93.8299 87.8036 91.3705 88.3261 88.8806 88.3457Z"
        fill="#FF723E"
      />
      <path
        d="M78.3326 19.9872C74.7781 16.9906 70.253 15.3437 65.5738 15.3437C60.8945 15.3437 56.3694 16.9906 52.8149 19.9872C51.0356 21.5036 49.6046 23.3756 48.617 25.4788C47.6295 27.582 47.108 29.8682 47.0874 32.1852V56.5308C47.0668 56.9365 46.9582 57.3331 46.769 57.694C46.5797 58.055 46.3141 58.3718 45.9902 58.6232C44.9211 59.417 43.6186 59.8464 42.2797 59.8464C40.9408 59.8464 39.6383 59.417 38.5693 58.6232C38.246 58.3727 37.9809 58.0571 37.7917 57.6975C37.6024 57.3378 37.4934 56.9425 37.472 56.538V46.0255C37.472 44.2384 36.7511 42.5245 35.4678 41.2608C34.1845 39.9971 32.4439 39.2872 30.6291 39.2872C28.8142 39.2872 27.0736 39.9971 25.7903 41.2608C24.507 42.5245 23.7861 44.2384 23.7861 46.0255V56.5308C23.8079 58.8475 24.3303 61.133 25.3184 63.2355C26.3066 65.3381 27.7379 67.2094 29.5172 68.7252C33.0717 71.7218 37.5968 73.3687 42.2761 73.3687C46.9553 73.3687 51.4804 71.7218 55.0349 68.7252C56.814 67.2084 58.2451 65.3364 59.2332 63.2333C60.2213 61.1303 60.7438 58.8443 60.7661 56.5272V32.1888C60.7865 31.7836 60.895 31.3874 61.0843 31.027C61.2736 30.6667 61.5393 30.3505 61.8633 30.1C62.9344 29.3092 64.2369 28.8818 65.5756 28.8818C66.9143 28.8818 68.2168 29.3092 69.2879 30.1C69.6107 30.3517 69.8755 30.6681 70.0646 31.0282C70.2538 31.3883 70.363 31.7839 70.3851 32.1888V46.7674C70.4574 48.5247 71.2353 50.1818 72.548 51.3745C73.8607 52.5671 75.6006 53.1976 77.3853 53.1275H77.4695C79.1414 53.1988 80.7738 52.6138 82.0081 51.501C83.2424 50.3882 83.9778 48.8386 84.0528 47.1923C84.0528 47.0519 84.0528 46.9114 84.0528 46.771V32.1888C84.032 29.872 83.5112 27.586 82.525 25.4824C81.5388 23.3788 80.1098 21.5057 78.3326 19.9872Z"
        fill="#A929FE"
      />
      <path
        d="M7.33872 57.2835C5.52481 57.2835 3.78519 56.574 2.50257 55.311C1.21994 54.048 0.499363 52.335 0.499363 50.5489V46.3568C0.470005 41.4205 2.346 36.6575 5.74775 33.0315C7.32794 31.3179 9.23548 29.9277 11.3614 28.9401C13.4874 27.9526 15.7901 27.3872 18.138 27.2762C20.4859 27.1651 22.8331 27.5107 25.0454 28.293C27.2576 29.0754 29.2917 30.2793 31.0314 31.8359C31.4557 32.2152 31.8604 32.6138 32.2457 33.0315C35.6431 36.6593 37.515 41.422 37.4831 46.3568C37.5041 47.2545 37.3428 48.1474 37.0085 48.9829C36.6741 49.8183 36.1736 50.5796 35.5362 51.2218C34.8989 51.8641 34.1376 52.3745 33.2971 52.7229C32.4566 53.0713 31.5538 53.2508 30.6419 53.2508C29.7299 53.2508 28.8272 53.0713 27.9867 52.7229C27.1462 52.3745 26.3849 51.8641 25.7475 51.2218C25.1102 50.5796 24.6096 49.8183 24.2753 48.9829C23.941 48.1474 23.7796 47.2545 23.8007 46.3568C23.8184 44.8247 23.252 43.3417 22.2134 42.2007C21.4514 41.3598 20.3814 40.8513 19.2387 40.7871C18.096 40.723 16.9743 41.1084 16.1202 41.8586C15.9995 41.9667 15.8824 42.0819 15.7544 42.2007C14.7259 43.3462 14.1678 44.828 14.189 46.3568V50.5669C14.1881 52.3533 13.4667 54.0663 12.1835 55.3292C10.9003 56.5921 9.16028 57.3015 7.34604 57.3015L7.33872 57.2835Z"
        fill="#198787"
      />
      <path
        d="M134.47 37.1948C134.47 35.675 136.716 34.4757 140.443 34.4757C144.052 34.4705 147.542 35.7495 150.27 38.0771C150.427 38.2066 150.627 38.2724 150.831 38.261C151.035 38.2497 151.227 38.162 151.367 38.0159L155.413 33.3917C155.483 33.3245 155.539 33.2442 155.577 33.1554C155.615 33.0667 155.635 32.9713 155.635 32.8749C155.635 32.7785 155.615 32.6831 155.577 32.5943C155.539 32.5055 155.483 32.4252 155.413 32.3581L155.361 32.3148C151.299 28.7017 145.997 26.7369 140.523 26.8155C132.477 26.8155 125.81 30.8635 125.81 37.8214C125.81 51.1683 147.783 48.5104 147.783 54.1395C147.783 55.9762 145.278 57.4276 141.357 57.4276C137.19 57.4862 133.153 55.9958 130.052 53.2535C129.972 53.185 129.879 53.1326 129.779 53.0995C129.678 53.0664 129.572 53.0531 129.467 53.0605C129.361 53.0678 129.258 53.0957 129.163 53.1424C129.068 53.1891 128.984 53.2538 128.915 53.3327C128.883 53.3675 128.855 53.4049 128.831 53.4444L124.979 57.9354C124.903 58.0072 124.842 58.0931 124.8 58.1881C124.758 58.2831 124.735 58.3854 124.733 58.4891C124.731 58.5928 124.75 58.6959 124.788 58.7924C124.827 58.889 124.884 58.9771 124.957 59.0518L124.979 59.0734C127.146 61.1151 129.706 62.7087 132.508 63.7603C135.31 64.8118 138.298 65.2999 141.295 65.1958C150.738 65.1958 156.711 59.7541 156.711 52.9258C156.696 40.4216 134.47 42.5069 134.47 37.1948Z"
        fill="white"
      />
      <path
        d="M159.659 27.579V20.621C159.647 20.3808 159.725 20.1447 159.879 19.9579C160.033 19.7711 160.251 19.6469 160.493 19.609C162.614 19.2921 165.309 18.8491 167.237 18.5286C168.074 18.399 168.334 18.8419 168.334 19.2849V27.5682H178.29C178.388 27.5623 178.486 27.5761 178.579 27.6085C178.671 27.641 178.756 27.6915 178.829 27.757C178.901 27.8226 178.959 27.9018 179 27.9901C179.041 28.0783 179.063 28.1736 179.065 28.2705V34.717C179.07 34.8149 179.056 34.9128 179.021 35.0048C178.987 35.0968 178.934 35.181 178.865 35.2521C178.797 35.3233 178.715 35.3799 178.623 35.4185C178.532 35.4572 178.433 35.4771 178.333 35.4769H168.316V50.466C168.316 54.8958 171.333 57.2331 175.576 57.2331C176.547 57.2296 177.516 57.1245 178.465 56.9198C178.638 56.8506 178.832 56.8494 179.006 56.9164C179.18 56.9834 179.322 57.1134 179.401 57.2799C179.412 57.3021 179.42 57.325 179.427 57.3483L180.89 63.3591C180.933 63.5271 180.91 63.7047 180.827 63.8572C180.744 64.0098 180.606 64.1262 180.44 64.1838C178.591 64.7564 176.661 65.0336 174.724 65.005C164.896 65.005 159.626 58.2991 159.626 50.837V27.579H159.659Z"
        fill="white"
      />
      <path
        d="M198.903 26.8191C195.046 26.8362 191.282 27.9791 188.085 30.1031C184.888 32.2271 182.403 35.2368 180.944 38.7517C179.484 42.2666 179.116 46.1288 179.885 49.8498C180.655 53.5707 182.528 56.9834 185.267 59.6562C188.007 62.329 191.49 64.1418 195.275 64.8654C199.061 65.5891 202.98 65.191 206.536 63.7215C210.092 62.2521 213.125 59.7773 215.253 56.6101C217.38 53.4429 218.506 49.7256 218.488 45.9282C218.464 40.8369 216.387 35.9635 212.714 32.3799C209.041 28.7964 204.073 26.7961 198.903 26.8191ZM198.903 57.3123C195.938 57.2163 193.131 55.9755 191.089 53.8581C189.046 51.7407 187.931 48.9168 187.985 45.9967V45.9318C188.107 43.8307 188.851 41.8106 190.125 40.1211C191.4 38.4315 193.149 37.1467 195.156 36.4251C197.164 35.7036 199.343 35.5769 201.423 36.0609C203.503 36.5449 205.393 37.6182 206.861 39.1484C208.328 40.6785 209.308 42.5984 209.68 44.6709C210.051 46.7434 209.798 48.8778 208.952 50.8102C208.105 52.7427 206.703 54.3887 204.917 55.5449C203.131 56.701 201.04 57.3167 198.903 57.3159V57.3123Z"
        fill="white"
      />
      <path
        d="M278.616 27.579H270.526C270.33 27.59 270.142 27.6554 269.983 27.7676C269.823 27.8798 269.7 28.0342 269.626 28.2128C268.163 31.184 266.671 34.1588 265.259 36.9391L259.605 48.2655L254.016 36.9931C252.553 34.0219 251.061 31.0472 249.649 28.2633C249.563 28.0628 249.421 27.8907 249.239 27.7676C249.057 27.6444 248.842 27.5752 248.622 27.5682H242.166C240.746 27.2448 239.29 27.096 237.832 27.1252C234.783 27.1775 231.833 28.1982 229.42 30.0352V28.3497C229.426 28.2536 229.411 28.1574 229.378 28.0669C229.346 27.9763 229.294 27.8931 229.228 27.8223C229.162 27.7514 229.082 27.6944 228.993 27.6544C228.904 27.6145 228.808 27.5925 228.711 27.5898H222.146C222.048 27.5845 221.95 27.5985 221.858 27.6312C221.765 27.6639 221.681 27.7145 221.609 27.78C221.537 27.8455 221.479 27.9246 221.439 28.0126C221.398 28.1005 221.376 28.1956 221.374 28.2921V63.6436C221.361 63.8279 221.421 64.0098 221.541 64.1519C221.661 64.294 221.831 64.3854 222.018 64.4071C222.06 64.4108 222.103 64.4108 222.146 64.4071H229.267C229.454 64.4195 229.639 64.3606 229.783 64.2426C229.927 64.1247 230.02 63.9566 230.042 63.7733C230.046 63.7301 230.046 63.6868 230.042 63.6436V44.2788C230.042 37.3857 234.153 34.9151 238.33 34.9151C239.324 34.8967 240.317 35.002 241.285 35.2284C241.486 35.2641 241.694 35.2197 241.862 35.1049C242.03 34.9901 242.144 34.8143 242.181 34.6162V34.5946L242.473 33.4421C243.205 34.8611 243.911 36.2764 244.617 37.6954L254.766 57.8129L250.077 67.047C249.178 68.8837 247.956 71.2858 247.056 73.0577C246.928 73.3098 246.928 73.8789 247.506 73.8789H255.6C255.795 73.8678 255.984 73.8024 256.143 73.6902C256.302 73.578 256.426 73.4236 256.5 73.245C257.531 71.1598 258.621 69.0097 259.649 66.9209L273.975 38.4697C275.58 35.2284 277.442 31.573 279.048 28.4109C279.201 28.148 279.201 27.579 278.616 27.579Z"
        fill="white"
      />
      <path
        d="M299.814 26.8191C295.84 26.8531 291.976 28.1126 288.765 30.4205V17.7111C288.771 17.615 288.757 17.5189 288.724 17.4283C288.691 17.3377 288.64 17.2545 288.573 17.1837C288.507 17.1128 288.427 17.0558 288.339 17.0159C288.25 16.9759 288.153 16.9539 288.056 16.9512H280.865C280.766 16.9458 280.666 16.9604 280.573 16.9942C280.48 17.028 280.394 17.0802 280.322 17.1476C280.25 17.2151 280.192 17.2963 280.153 17.3864C280.114 17.4764 280.093 17.5735 280.094 17.6715V63.6436C280.088 63.7418 280.102 63.8401 280.136 63.9325C280.17 64.025 280.223 64.1096 280.292 64.1811C280.36 64.2526 280.443 64.3096 280.535 64.3485C280.626 64.3873 280.725 64.4073 280.825 64.4071H288.016C288.113 64.4119 288.211 64.3975 288.302 64.3646C288.394 64.3317 288.478 64.281 288.55 64.2156C288.622 64.1501 288.679 64.0712 288.719 63.9835C288.759 63.8957 288.781 63.801 288.784 63.7048C288.787 63.6846 288.787 63.6639 288.784 63.6436V61.6232C291.991 63.9391 295.859 65.1994 299.836 65.2246C305.007 65.2848 309.991 63.3198 313.691 59.7618C317.39 56.2038 319.503 51.3443 319.564 46.2524C319.625 41.1604 317.63 36.2531 314.016 32.61C310.403 28.9669 305.468 26.8865 300.297 26.8263H299.833L299.814 26.8191ZM299.814 57.3123C296.826 57.2164 293.995 55.9704 291.929 53.842C289.863 51.7135 288.727 48.8722 288.765 45.9282V45.867C288.876 43.7398 289.618 41.6912 290.899 39.975C292.18 38.2587 293.945 36.9501 295.973 36.211C298.002 35.472 300.207 35.335 302.314 35.8171C304.421 36.2991 306.338 37.379 307.828 38.923C309.318 40.4671 310.316 42.4075 310.697 44.5042C311.079 46.6008 310.828 48.7616 309.975 50.7191C309.122 52.6766 307.705 54.3449 305.899 55.5174C304.093 56.6899 301.977 57.3152 299.814 57.3159V57.3123Z"
        fill="white"
      />
      <path
        d="M340.73 26.8191C336.874 26.8376 333.11 27.9817 329.914 30.1065C326.718 32.2314 324.234 35.2417 322.776 38.7567C321.317 42.2717 320.95 46.1336 321.72 49.8541C322.491 53.5746 324.365 56.9866 327.104 59.6587C329.844 62.3307 333.327 64.1428 337.112 64.8659C340.898 65.5889 344.816 65.1904 348.372 63.7208C351.927 62.2511 354.96 59.7763 357.087 56.6093C359.215 53.4423 360.34 49.7252 360.322 45.9282C360.31 43.4066 359.794 40.9121 358.803 38.587C357.812 36.2619 356.365 34.1519 354.546 32.3774C352.726 30.6029 350.57 29.1987 348.199 28.245C345.829 27.2913 343.29 26.8068 340.73 26.8191ZM340.73 57.3123C337.764 57.2181 334.956 55.978 332.912 53.8603C330.868 51.7426 329.753 48.9176 329.809 45.9966V45.9318C329.93 43.8308 330.674 41.8108 331.948 40.1214C333.222 38.432 334.971 37.1471 336.979 36.4254C338.986 35.7037 341.165 35.5769 343.245 36.0606C345.325 36.5443 347.215 37.6173 348.683 39.1471C350.15 40.6768 351.13 42.5964 351.503 44.6687C351.875 46.741 351.622 48.8752 350.776 50.8077C349.93 52.7403 348.528 54.3865 346.743 55.5431C344.957 56.6997 342.867 57.316 340.73 57.3159V57.3123Z"
        fill="white"
      />
      <path
        d="M400.598 27.579H393.466C393.368 27.5737 393.27 27.5877 393.178 27.6204C393.086 27.6531 393.001 27.7037 392.929 27.7692C392.857 27.8347 392.799 27.9138 392.759 28.0018C392.718 28.0897 392.696 28.1848 392.694 28.2813V30.3017C390.184 28.5624 387.292 27.4307 384.254 26.9986C381.216 26.5664 378.117 26.846 375.21 27.8147C372.302 28.7833 369.667 30.4137 367.52 32.5733C365.372 34.7329 363.772 37.3609 362.85 40.2437C361.928 43.1266 361.709 46.1831 362.211 49.1649C362.714 52.1466 363.924 54.9696 365.742 57.4045C367.561 59.8394 369.937 61.8175 372.678 63.1782C375.419 64.5388 378.448 65.2437 381.517 65.2355C385.52 65.2533 389.429 64.0367 392.694 61.7565V63.6436C392.689 63.7415 392.703 63.8394 392.738 63.9314C392.772 64.0235 392.825 64.1076 392.894 64.1787C392.962 64.2499 393.045 64.3065 393.136 64.3452C393.227 64.3838 393.326 64.4037 393.426 64.4035H400.616C400.713 64.4084 400.811 64.394 400.902 64.3613C400.994 64.3287 401.078 64.2783 401.149 64.2132C401.221 64.1481 401.278 64.0697 401.319 63.9824C401.359 63.8951 401.381 63.8007 401.384 63.7049C401.388 63.6846 401.388 63.6639 401.384 63.6436V28.3497C401.389 28.2539 401.375 28.158 401.343 28.0677C401.31 27.9773 401.259 27.8943 401.193 27.8235C401.128 27.7527 401.048 27.6956 400.96 27.6555C400.871 27.6153 400.776 27.593 400.678 27.5898L400.598 27.579ZM392.438 45.9967C392.429 48.9749 391.221 51.828 389.077 53.9293C386.934 56.0307 384.031 57.2086 381.007 57.2043C377.982 57.2 375.083 56.0139 372.946 53.9064C370.809 51.799 369.609 48.9425 369.609 45.9642C369.609 42.986 370.809 40.1295 372.946 38.0221C375.083 35.9146 377.982 34.7285 381.007 34.7242C384.031 34.7199 386.934 35.8978 389.077 37.9992C391.221 40.1005 392.429 42.9536 392.438 45.9318V45.9967Z"
        fill="white"
      />
      <path
        d="M426.353 34.5982L427.816 28.7783C427.879 28.6092 427.871 28.4226 427.794 28.2592C427.717 28.0959 427.578 27.9692 427.407 27.9067H427.363C425.624 27.3421 423.798 27.0848 421.968 27.1468C418.919 27.1991 415.969 28.2198 413.556 30.0568V28.3497C413.561 28.2536 413.547 28.1574 413.514 28.0669C413.481 27.9763 413.43 27.8931 413.364 27.8223C413.298 27.7514 413.218 27.6944 413.129 27.6544C413.04 27.6145 412.944 27.5925 412.846 27.5898H406.296C406.198 27.5845 406.1 27.5985 406.008 27.6312C405.916 27.6639 405.831 27.7145 405.759 27.78C405.687 27.8455 405.629 27.9246 405.589 28.0126C405.549 28.1005 405.527 28.1956 405.524 28.2921V63.6436C405.512 63.8279 405.572 64.0098 405.691 64.1519C405.811 64.294 405.982 64.3854 406.168 64.4071C406.211 64.4108 406.254 64.4108 406.296 64.4071H413.428C413.615 64.4185 413.798 64.3592 413.942 64.2413C414.085 64.1235 414.178 63.956 414.2 63.7733C414.203 63.7301 414.203 63.6868 414.2 63.6436V44.2788C414.2 37.3857 418.311 34.9151 422.487 34.9151C423.482 34.8967 424.475 35.002 425.443 35.2284C425.643 35.2642 425.85 35.2202 426.018 35.1062C426.186 34.9921 426.301 34.8172 426.339 34.6198C426.342 34.6116 426.347 34.6042 426.353 34.5982Z"
        fill="white"
      />
      <path
        d="M455.58 16.9512C455.48 16.9458 455.381 16.9604 455.287 16.9942C455.194 17.028 455.108 17.0802 455.036 17.1476C454.964 17.2151 454.906 17.2963 454.867 17.3864C454.828 17.4764 454.808 17.5735 454.808 17.6715V30.4457C451.596 28.1388 447.731 26.8795 443.755 26.8443C441.195 26.8145 438.653 27.2816 436.276 28.2189C433.899 29.1562 431.733 30.5454 429.901 32.3072C428.069 34.0689 426.607 36.1687 425.6 38.4866C424.592 40.8046 424.057 43.2953 424.027 45.8166C423.997 48.3379 424.471 50.8403 425.423 53.1811C426.375 55.5218 427.786 57.655 429.575 59.4589C431.364 61.2628 433.497 62.702 435.851 63.6944C438.205 64.6867 440.734 65.2129 443.294 65.2426H443.755C447.733 65.2174 451.6 63.9571 454.808 61.6412V63.6436C454.802 63.7418 454.816 63.8401 454.851 63.9325C454.885 64.025 454.938 64.1096 455.006 64.1811C455.075 64.2526 455.157 64.3096 455.249 64.3485C455.341 64.3873 455.44 64.4073 455.539 64.4071H462.73C462.827 64.4119 462.925 64.3975 463.017 64.3646C463.109 64.3317 463.193 64.281 463.264 64.2156C463.336 64.1501 463.394 64.0712 463.434 63.9835C463.474 63.8957 463.496 63.801 463.498 63.7048V17.7111C463.503 17.615 463.489 17.5189 463.456 17.4283C463.423 17.3377 463.372 17.2545 463.306 17.1837C463.24 17.1128 463.16 17.0558 463.071 17.0159C462.982 16.9759 462.886 16.9539 462.788 16.9512H455.58ZM454.808 45.9246C454.799 48.8828 453.599 51.7166 451.47 53.8038C449.341 55.891 446.458 57.0609 443.454 57.0566C440.449 57.0523 437.57 55.8742 435.447 53.7809C433.324 51.6876 432.132 48.8504 432.132 45.8922C432.132 42.934 433.324 40.0968 435.447 38.0035C437.57 35.9103 440.449 34.7321 443.454 34.7278C446.458 34.7235 449.341 35.8934 451.47 37.9806C453.599 40.0678 454.799 42.9016 454.808 45.8598V45.9246Z"
        fill="white"
      />
      <path
        d="M412.419 92.1379C412.419 92.939 412.264 93.7524 411.952 94.578C411.648 95.4037 411.185 96.1639 410.561 96.8588C409.938 97.5455 409.146 98.1014 408.186 98.5264C407.235 98.9515 406.107 99.1641 404.802 99.1641C403.071 99.1641 401.59 98.7676 400.36 97.9746C399.129 97.1735 398.19 96.0699 397.541 94.6639C396.893 93.2578 396.569 91.6392 396.569 89.8081C396.569 87.9769 396.893 86.3624 397.541 84.9645C398.19 83.5584 399.129 82.4589 400.36 81.666C401.59 80.873 403.071 80.4766 404.802 80.4766C406.238 80.4766 407.464 80.73 408.482 81.2368C409.499 81.7437 410.315 82.4017 410.93 83.211C411.546 84.0203 411.968 84.8787 412.198 85.7861C412.346 86.3747 412.419 86.9428 412.419 87.4905H408.014C408.014 87.2371 407.998 87.0041 407.965 86.7916C407.883 86.2275 407.706 85.7329 407.436 85.3078C407.165 84.8746 406.808 84.5353 406.365 84.2901C405.922 84.0449 405.401 83.9222 404.802 83.9222C404.08 83.9222 403.436 84.1307 402.87 84.5476C402.312 84.9563 401.865 85.594 401.529 86.4605C401.201 87.327 401.036 88.4429 401.036 89.8081C401.036 91.1732 401.201 92.2932 401.529 93.1679C401.865 94.0426 402.312 94.6884 402.87 95.1053C403.436 95.5222 404.08 95.7307 404.802 95.7307C405.311 95.7307 405.762 95.6367 406.156 95.4486C406.558 95.2606 406.894 95.0031 407.165 94.6761C407.444 94.341 407.653 93.9567 407.792 93.5235C407.94 93.0902 408.014 92.6283 408.014 92.1379H412.419Z"
        fill="white"
      />
      <path
        d="M430.872 80.7709V98.8575H426.466V90.3353L428.448 91.5125H418.43L420.412 90.3966V98.8575H416.006V80.7709H420.412V89.3544L418.43 88.2017H428.448L426.466 89.3421V80.7709H430.872Z"
        fill="white"
      />
      <path
        d="M434.066 98.8575L438.435 80.7586H445.4L449.756 98.8575H445.252L441.917 83.395H441.942L438.558 98.8575H434.066ZM439.43 94.8601L440.167 91.5125H443.673L444.393 94.8601H439.43Z"
        fill="white"
      />
      <path
        d="M463.501 84.0816H457.261L459.144 82.0584V98.8575H454.763V82.0584L456.671 84.0816H450.419V80.7709H463.501V84.0816Z"
        fill="white"
      />
    </svg>
  );
}
