import { createContext, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { WsEvent, WsItem } from "web-client/api/data-contracts";
import { Item } from "@/generated/client";
import { AccountEventType } from "web-client/api/data-contracts";
import {
  updateUnreadItemByEvents,
  updateUnreadItemByFeedId,
} from "@/data/workspace";
import { groupBy } from "@/utils";

type UnreadFeed = {
  feedId: string;
  unreadItems: Array<Item>;
};

export const unreadClearEvents: AccountEventType[] = [
  "Saw Feed Item",
  "Marked Feed as Read",
  "Opened Feed",
  "Started Listening to Feed Item", // used for in-line feed views
];

export type UnreadsState = {
  allUnreadFeeds?: Array<UnreadFeed>;
  unreadFeedIds?: Array<string>;
  handleUnreadItem: (items: WsItem) => Promise<void>;
  handleReadItemEvent: (events: WsEvent) => Promise<void>;
};

export const UnreadsContext = createContext<UnreadsState>({
  allUnreadFeeds: [],
  handleUnreadItem: async () => { },
  handleReadItemEvent: async () => { },
});

const UnreadsContextProvider = ({ children }) => {
  const { db } = useElectric();

  const { results: myAccount } = useLiveQuery(
    db.account.liveFirst({
      where: {
        mine: 1,
      },
    }),
  );

  const handleUnreadItem = useCallback(
    async (item: WsItem) => {
      if (!myAccount?.id) return;
      updateUnreadItemByFeedId(db, item, myAccount?.id);
    },
    [db, myAccount?.id],
  );

  const handleReadItemEvent = useCallback(
    async (event: WsEvent) => {
      if (!myAccount?.id) return;
      updateUnreadItemByEvents(db, event, myAccount?.id);
    },
    [db, myAccount?.id],
  );

  const { results: unreadFeedIds } = useLiveQuery(
    db.liveRaw({
      sql: `
      SELECT DISTINCT feedId
      FROM item
      WHERE unread = 1
        AND deletedAt IS NULL
      `,
    }),
  );

  const unreadsState: UnreadsState = {
    unreadFeedIds: unreadFeedIds?.map((ufi) => ufi.feedId),
    handleUnreadItem,
    handleReadItemEvent,
  };

  return (
    <UnreadsContext.Provider value={unreadsState}>
      {children}
    </UnreadsContext.Provider>
  );
};

export default UnreadsContextProvider;
