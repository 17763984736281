import { AudioEncoding } from "@/generated/client";
import { browserIsSafari } from "./window";

export const AUDIO_FILTERS = {
	STANDARD: {
		HIGHPASS: 80,
		LOWPASS: 16000,
	},
};

export const getBandpassData = (f1, f2) => {
	const centerFreq = Math.sqrt(f1 * f2);
	const q = centerFreq / (f2 - f1);
	return {
		centerFreq,
		q,
	};
};

export const canPlayAudio = (audioContent: AudioEncoding[]): boolean => {
	const hasAudio = audioContent?.length > 0;
	if (!hasAudio) {
		return false;
	}
	const hasAudioSources =
		hasAudio &&
		audioContent.filter((audio) => audio?.url?.length > 0)?.length > 1;

	if (!hasAudioSources) {
		return false;
	}

	const audioSources = hasAudioSources && [
		...new Set(audioContent.map((item) => item?.url?.split(".")?.reverse()[0])),
	];

	if (browserIsSafari(window.navigator.userAgent)) {
		return audioSources.includes("caf");
	}
	return audioSources.includes("opus");
};

export function targetCodecForBrowser() {
	if (browserIsSafari(window.navigator.userAgent)) {
		return "caf";
	}
	return "opus";
}
