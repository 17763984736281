import { useContext, useState } from "react";
import { Stack, Box, Button, Link, useTheme, Typography } from "@mui/material";
import TextMessageInput from "../TextMessageInput";
import { FullInput } from "@/components/Utils";
import {
  SchedulingContext,
  UploadType,
} from "@/models/SchedulingContextProvider";

export default function ScheduleMessageTTS() {
  const {
    displayName,
    setUploadType,
    scheduledText,
    setScheduledText,
    setDisplayName,
    goToPrevStep,
    goToNextStep,
  } = useContext(SchedulingContext);
  const [textValid, setTextValid] = useState<boolean>(false);

  const internalNameCharLimit = 40;
  const textCharLimit = 5000;
  const disableContinue =
    !textValid ||
    scheduledText?.length > textCharLimit ||
    displayName?.trim()?.length === 0 ||
    displayName?.length > internalNameCharLimit;

  const theme = useTheme();

  const handleTextInput = (text: string) => {
    setScheduledText(text);
  };

  const handleInternalName = (internalName: string) => {
    setDisplayName(internalName);
  };

  const handleTextValidation = (valid: boolean) => {
    setTextValid(valid);
  };

  const handleReturn = () => {
    setUploadType(UploadType.File);
    goToPrevStep();
  };

  const InternalNameLabel = () => {
    return (
      <Box>
        <Typography sx={{ fontWeight: 700 }}>Internal Name*</Typography>
        <Typography>Only you will be able to see this name.</Typography>
      </Box>
    );
  };

  const InternalNameHelperText = () => {
    const message = () => {
      let msg = "";
      if (displayName?.length >= internalNameCharLimit) {
        msg = `Error: You cannot exceed ${internalNameCharLimit.toLocaleString()} characters.`;
      }
      return msg;
    };

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          width: "100%",
          marginBottom: 0,
        }}
      >
        {message()}
        <Box
          sx={{
            fontSize: "1rem",
            marginLeft: "auto",
          }}
        >
          {displayName?.length}/{internalNameCharLimit}
        </Box>
      </Box>
    );
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "flex-start",
        gap: 2,
      }}
    >
      <FullInput
        label={<InternalNameLabel />}
        sx={{
          position: "relative",
          width: "100%",
          p: 0,
          border: "solid 1px transparent",
          "label + &": {
            marginTop: "52px",
          },
        }}
        value={displayName}
        callback={(e) => handleInternalName(e.target.value)}
        error={displayName?.length > internalNameCharLimit}
        helperText={<InternalNameHelperText />}
      />

      <TextMessageInput
        label="Enter text to generate audio"
        rows={9}
        textInputValue={scheduledText}
        textInputHandler={handleTextInput}
        validateTextHandler={handleTextValidation}
      />

      <Stack
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          gap: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{ order: { xs: 1, sm: 0 } }}
          onClick={handleReturn}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ order: { xs: 0, sm: 1 } }}
          disabled={disableContinue}
          onClick={goToNextStep}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
}
