import { useElectric } from "@/electric/ElectricWrapper";
import type { AccountInfo } from "@/models/accountInfo";
import Person from "@mui/icons-material/Person";
import { Box, Tooltip, useTheme } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "@/models/DataProvider";

const defaultSize = "md";
type SizeType = "xs" | "sm" | "md" | "lg";

interface AvatarProps {
  size?: SizeType; // Avatar size
  accountId?: string; // Account ID of the user
  member?: AccountInfo; // Member info if there is no account id
  disableTooltip?: boolean; // Disable tooltip
}

const sizes: Array<{
  name: string;
  avatar: number;
  status: number;
  border: number;
}> = [
  { name: "xs", avatar: 16, status: 5, border: 0.75 },
  { name: "sm", avatar: 24, status: 7.5, border: 1.13 },
  { name: "md", avatar: 32, status: 10, border: 1.5 },
  { name: "lg", avatar: 64, status: 20, border: 3 },
];

export default function AvatarWithStatus({
  size,
  accountId,
  member,
  disableTooltip,
}: AvatarProps) {
  const theme = useTheme();
  const { db } = useElectric();
  const { workspaceId } = useParams();
  const { isWorkspaceLimitedMember, myAccountId } = useContext(DataContext);
  let handsFreeStatusIndicator = null;

  const avatarAccountId = accountId || member?.id;
  const limitedMember = isWorkspaceLimitedMember(workspaceId, myAccountId);

  // fetch account details from accountId prop
  const { results: account } = useLiveQuery(() => {
    if (!avatarAccountId) return;
    return db.account.liveFirst({
      where: {
        id: avatarAccountId,
      },
    });
  }, [avatarAccountId]);

  // fetch workspace membership details from account if available
  const { results: workspaceMembership } = useLiveQuery(
    () =>
      db.workspace_membership.liveFirst({
        where: {
          accountId: avatarAccountId,
          workspaceId,
          status: "active",
        },
      }),
    [avatarAccountId, workspaceId],
  );

  // fetch hands-free status of the workspace member if account and workspace membership is available
  const { results: workspaceMemberHandsFreeStatus } = useLiveQuery(
    () =>
      db.ws_hands_free_status.liveFirst({
        where: {
          id: workspaceMembership?.id,
        },
      }),
    [workspaceMembership?.id],
  );

  const workspaceHandsFreeDisabled =
    typeof workspaceMemberHandsFreeStatus?.enabled === "undefined";
  const disabledTooltip = disableTooltip || workspaceHandsFreeDisabled;
  const showHandsFreeIndicator =
    !limitedMember || avatarAccountId === myAccountId;
  if (showHandsFreeIndicator && !workspaceHandsFreeDisabled) {
    handsFreeStatusIndicator = workspaceMemberHandsFreeStatus?.enabled === 1;
  }

  if (!size) {
    size = defaultSize;
  }
  const sizeValue = sizes.find((s) => s.name === size);
  const avatarSize = `${sizeValue.avatar}px`;
  const fontSize = `${sizeValue.avatar / 2}px`;
  const statusSize = `${sizeValue.status}px`;
  const statusBorder = `solid ${sizeValue.border}px #222`;
  const statusColor = handsFreeStatusIndicator
    ? theme.palette.brand.other.green.main
    : theme.palette.secondary.main;
  const accountName = account
    ? account?.name ||
      member?.name ||
      `${account?.firstName} ${account?.lastName}` ||
      `${member?.firstName} ${member?.lastName}`
    : "";
  const initial =
    accountName && accountName?.length > 0
      ? accountName[0]?.toUpperCase()
      : null;
  const avatarColor =
    account?.avatarColor ||
    member?.avatarColor ||
    theme.palette.secondary.light;
  const handsFreeStatus = handsFreeStatusIndicator
    ? "Hands-Free is on"
    : "Hands-Free is off";
  const ariaLabel = workspaceHandsFreeDisabled
    ? accountName
    : `${accountName}: ${handsFreeStatus}`;

  if (!initial) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: avatarSize,
          height: avatarSize,
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: theme.palette.primary.main,
          borderRadius: "100%",
        }}
      >
        <Person
          sx={{
            width: fontSize,
            height: fontSize,
          }}
        />
      </Box>
    );
  }

  return (
    <Tooltip
      title={handsFreeStatus}
      disableFocusListener={disabledTooltip}
      disableHoverListener={disabledTooltip}
      disableTouchListener={disabledTooltip}
      componentsProps={{
        tooltip: {
          sx: {
            marginTop: "4px !important",
            fontSize: "1rem",
            fontWeight: 500,
            background: theme.palette.secondary.light,
            lineHeight: 1.25,
          },
        },
      }}
    >
      <Box
        aria-label={ariaLabel}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: avatarSize,
          height: avatarSize,
          background: avatarColor,
          borderRadius: "100%",
          position: "relative",
        }}
        role="img"
      >
        <Box
          sx={{
            marginBottom: "-1px",
            fontWeight: 700,
            fontSize,
            lineHeight: fontSize,
            textTransform: "uppercase",
            color: theme.palette.primary.main,
          }}
        >
          {initial}
        </Box>
        {workspaceHandsFreeDisabled !== true && (
          <Box
            sx={{
              background: statusColor,
              position: "absolute",
              right: 0,
              bottom: 0,
              borderRadius: "100%",
              width: statusSize,
              height: statusSize,
              border: statusBorder,
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
}
