/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Login<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name Auth0CallbackList
   * @summary callback
   * @request GET:/login/auth0/callback
   */
  auth0CallbackList = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/login/auth0/callback`,
      method: "GET",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name LoginList
   * @summary login
   * @request GET:/login
   */
  loginList = (
    query?: {
      /** URL to send the user after successful login */
      returnTo?: string;
      /** alternative authentication methods such as password */
      authMethod?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/login`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name Auth0List
   * @summary loginAuth0
   * @request GET:/login/auth0
   */
  auth0List = (
    query?: {
      /** URL to send the user after successful login */
      returnTo?: string;
      /** email address or phone number of unauthenticated user */
      identifier?: string;
      /** name of social or sso idp */
      connection?: string;
      /** alternative authentication methods such as password */
      authMethod?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/login/auth0`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name TokenList
   * @summary tokenLogin
   * @request GET:/login/token
   */
  tokenList = (
    query: {
      token: string;
      returnTo?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/login/token`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      ...params,
    });
}
