import React from 'react';
import './loading.css';

type Props = {
  baseSize: number;
  size: string;
  variant: string;
};

export default function Loading({ baseSize, size, variant }: Props) {
  const loadingClasses = `loading-indicator ${variant} ${size}`;

  const degrees = 360;
  const colors = ['teal', 'purple', 'orange'];
  const colorMap = colors.map(
    (color, index) =>
      `${color} rotate-${(degrees / colors.length) * (index + 1)}`
  );

  const moveSize = (baseSize / 2) * 3;
  const sizeTimesTwo = baseSize * 2;
  const sizeTimesFour = baseSize * 4;
  const style = {
    '--base-size': `${baseSize}px`,
    '--move-size': `${moveSize}px`,
    '--size-times-two': `${sizeTimesTwo}px`,
    '--size-times-four': `${sizeTimesFour}px`
  } as React.CSSProperties;

  return (
    <div className={loadingClasses} style={style} role="progressbar">
      {colorMap.map((color, index) => (
        <div key={`loading-dot-${index}`} className={`dot ${color}`} />
      ))}
    </div>
  );
}

Loading.defaultProps = {
  baseSize: 20,
  size: 'large',
  variant: 'storyboard'
};
