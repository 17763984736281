import LoadingScreen from "@/components/LoadingScreen";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Root() {
	const navigate = useNavigate();

	React.useEffect(() => {
		navigate("/workspaces");
	}, [navigate]);

	return (
		<>
			<LoadingScreen />
		</>
	);
}
