import { Box, Stack, Typography, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
	DateCalendar,
	DateCalendarProps,
} from "@mui/x-date-pickers/DateCalendar";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { FullInput } from "./Utils";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { format } from "date-fns";
import { useState, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

export enum DatePickerVariant {
	Calendar = "calendar",
	Dropdown = "dropdown",
}

export type DatePickerProps = DateCalendarProps<Date> & {
	label?: string;
	selectedDate: Date;
	variant?: DatePickerVariant;
	dateSelectionHandler?: Function;
};

export default function DatePicker({
	label = "",
	selectedDate,
	variant = DatePickerVariant.Calendar,
	dateSelectionHandler,
	disabled,
}: DatePickerProps) {
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const defaultDate = new Date(Date.now());
	const theme = useTheme();

	const handleDateChange = (newDate) => {
		dateSelectionHandler(newDate);
		setShowCalendar(false);
	};

	const handleInput = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleError = (error) => {
		if (error) {
			setError(true);
		} else {
			setError(false);
		}
	};

	const Calendar = () => {
		return (
			<DateCalendar
				views={["day"]}
				disablePast
				minDate={defaultDate}
				disableHighlightToday
				value={selectedDate}
				onChange={handleDateChange}
				disabled={disabled}
			/>
		);
	};

	return (
		<Stack sx={{ width: "100%", gap: 1, position: "relative" }}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				{variant === "dropdown" ? (
					<Box
						sx={{
							width: "100%",
						}}
					>
						<MuiDatePicker
							label={label}
							views={["day"]}
							format="MM/dd/yyyy"
							disablePast
							open={showCalendar}
							minDate={defaultDate}
							disableHighlightToday
							value={selectedDate}
							onChange={handleDateChange}
							onOpen={handleInput}
							onClose={handleInput}
							onError={handleError}
							disabled={disabled}
							slotProps={{
								textField: {
									variant: "standard",
									fullWidth: true,
									helperText: error ? "Invalid date" : null,
								},
							}}
							sx={{
								minHeight: 95,
								fieldset: {
									border: "none",
								},
								"& .MuiInputBase-root": {
									marginTop: theme.spacing(3.5),
									borderRadius: "8px",
									border: `1.5px solid ${theme.palette.secondary.light}`,
									boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
									position: "relative",
									background: theme.palette.secondary.dark,
									fontSize: 14,
									width: "100%",
									padding: "8px",
									transition: theme.transitions.create([
										"border-color",
										"background-color",
										"box-shadow",
									]),
									"&:focus-within": {
										borderColor: theme.palette.primary.main,
									},
									boxSizing: "border-box",
									minHeight: 44,
									"::before, ::after": {
										content: "none",
									},
									"&.Mui-error": {
										color: theme.palette.error.main,
										borderColor: theme.palette.error.main,
									},
									"&.Mui-error > .MuiInputAdornment-root > *": {
										color: theme.palette.error.main,
									},
								},
								"& .MuiInputBase-input": {
									padding: 0,
									paddingRight: "8px;",
									"&:focus-visible": {
										boxShadow: "none",
									},
								},
								"& .MuiIconButton-root": {
									padding: 0,
								},
								"& .MuiInputAdornment-root": {
									marginRight: "10px",
								},
							}}
						/>
					</Box>
				) : (
					<Calendar />
				)}
			</LocalizationProvider>
		</Stack>
	);
}
