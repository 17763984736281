import ChannelContextMenu from "@/components/ContextMenus/ChannelContextMenu";
import Loading from "@/components/Loading/Index";
import { useElectric } from "@/electric/ElectricWrapper";
import Locator from "@/locator";
import { DataContext } from "@/models/DataProvider";
import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useState, memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLiveQuery } from "electric-sql/react";
import SteeringWheel from "../Icons/SteeringWheel";
import { UnreadsContext } from "@/models/UnreadsContextProvider";
import equal from "fast-deep-equal/react";

const WorkspaceChannelListItem = ({
  feedId,
  joined,
  setJoined,
  feed,
  membershipId,
  markAllChannelsAsRead,
}: {
  feedId: string;
  joined?: boolean;
  feed?: any;
  setJoined?: (value: boolean) => void;
  membershipId?: string;
  markAllChannelsAsRead?: () => void;
}) => {
  const { db } = useElectric();
  const { currentFeedId, joinPublicChannel } = useContext(DataContext);
  const { unreadFeedIds } = useContext(UnreadsContext);
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const [joining, setJoining] = useState<boolean>(false);

  const navigate = useNavigate();
  const theme = useTheme();

  const isCurrent = currentFeedId === feedId;

  let { results: commandAliases } = useLiveQuery(() => {
    if (!feedId) return;
    return db.liveRaw({
      sql: `
      SELECT
        workspace_membership.accountId,
        workspace_command_alias.*
  		FROM workspace_command_alias
  		INNER JOIN workspace_membership 
        ON workspace_membership.id = workspace_command_alias.workspaceMembershipId
      WHERE
        workspace_command_alias.feedId = ?
          `,
      args: [feedId],
    });
  }, [feedId]);

  const isAliasChannel = commandAliases?.length > 0;

  const unreadFeed = unreadFeedIds?.find((ufi) => ufi === feedId);

  const icon = () => {
    if (feed?.isPrivate === 1) {
      return <LockIcon sx={{ fontSize: "1.125rem" }} />;
    }
    if (isAliasChannel) {
      return <SteeringWheel />;
    }
    return null;
  };

  const hasIcon = icon() !== null;

  const dmName = "";

  const handleLinkClick = async () => {
    if (!joined) {
      setJoining(() => true);
      await joinPublicChannel(workspaceId, feed.id);
      setJoining(() => false);
      setJoined(true);
    }
    console.log("Navigating to", feedId);
    navigate(`/workspaces/${workspaceId}/feeds/${feedId}`);
    const ttsInput = document.getElementById("tts-form-text-input");
    // auto focus to the tts input on channel change
    if (ttsInput) {
      ttsInput.focus();
    }
  };

  const handleJoined = (value: boolean) => {
    setJoined(value);
  };

  // const showOurAccount = isOrganizer ?
  //   flags.debugMode
  //   : false

  return (
    <ListItem
      component="div"
      key={`${workspaceId}-${feedId}`}
      data-testid={Locator.workspaceNav.channels.list.testId(feedId)}
      aria-label={Locator.workspaceNav.channels.list.item}
      sx={{
        fontSize: "1.125rem",
        fontWeight: 500,
        px: 0,
        "&:first-of-type": {
          paddingTop: 0,
        },
        height: 48,
        gap: 1.5,
        p: 0,
      }}
    >
      <ChannelContextMenu
        markAllChannelsAsRead={markAllChannelsAsRead}
        changeJoined={(value: boolean) => handleJoined(value)}
        joined={joined}
        feed={feed}
        membershipId={membershipId}
        joiningHandler={setJoining}
      >
        <ListItemButton
          disabled={joining}
          onClick={handleLinkClick}
          selected={isCurrent}
          disableRipple={true}
          sx={{
            width: "100%",
            height: 48,
            borderRadius: "8px",
            color: joined
              ? theme.palette.primary.main
              : theme.palette.secondary.light,
            "&.Mui-selected, &.Mui-disabled": {
              color: theme.palette.primary.main,
              background: theme.palette.secondary.main,
            },
            "&:focus-visible": {
              outline: "none",
              boxShadow: "none",
            },
            justifyContent: "space-between",
            wordBreak: "break-word",
            px: 1.5,
            pl: hasIcon ? 1.75 : 1.5,
            py: 0,
            lineHeight: "24px",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {hasIcon ? (
              <Stack sx={{ width: 23, alignItems: "center" }}>{icon()}</Stack>
            ) : null}
            <Typography
              sx={{
                WebkitLineClamp: "2",
                fontSize: "1.125rem",
                fontWeight: 500,
                px: 1,
              }}
              className="text-ellipsis"
            >
              {feed?.isDm ? dmName : feed?.title || feed?.id}
            </Typography>
          </Stack>
          {joined && unreadFeed && (
            <Box
              aria-label={Locator.workspaceNav.channels.list.unreadCount}
              data-testid={Locator.workspaceNav.channels.list.unreadCountId(
                (unreadFeed ? 1 : 0).toString(),
              )}
              sx={{
                borderRadius: "50%",
                background: theme.palette.brand.secondary.main,
                height: 12,
                width: 12,
                minWidth: 12,
              }}
            />
          )}
          {joining ? <Loading variant="disabled" baseSize={6} /> : null}
        </ListItemButton>
      </ChannelContextMenu>
    </ListItem>
  );
};

const areEqual = (prevProps, nextProps) => {
  return equal(nextProps, prevProps) && prevProps.joined === nextProps.joined;
};

export default memo(WorkspaceChannelListItem, areEqual);
