import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker";
import { useState, useRef, useEffect } from "react";
import { Box, Typography, Stack, useTheme } from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { format, isAfter, isPast, min } from "date-fns";
import { useOnClickOutside } from "usehooks-ts";

export default function TimePicker({
  label = "",
  disabled = false,
  includeTimeZone = true,
  selectedTime,
  timeSelectionHandler,
  minDate,
}) {
  const [minTime, setMinTime] = useState<Date>();
  const [showTimeOptions, setShowTimeOptions] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const timeOffset = format(new Date(), "OOOO");
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, () => setShowTimeOptions(false));

  const theme = useTheme();

  const inputRef = useRef();

  const handleInput = () => {
    setShowTimeOptions((prev) => !prev);
  };

  const validateTime = (time) => {
    if (isPast(time)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleError = (error) => {
    if (error) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleTimeSelection = (time) => {
    timeSelectionHandler(time);
    if (minTime) {
      validateTime(time);
    }
  };

  useEffect(() => {
    if (minDate) {
      const currentDate = new Date(Date.now());
      const time = isAfter(minDate, currentDate) ? minDate : currentDate;
      setMinTime((prev) => (prev !== time ? time : prev));
    } else {
      setMinTime((prev) => (prev !== null ? null : prev));
    }
  }, [minDate]);

  const pickerIcon = () => {
    return showTimeOptions ? (
      <ArrowDropUp role="presentation" />
    ) : (
      <ArrowDropDown role="presentation" />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ position: "relative" }}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <MuiTimePicker
              inputRef={inputRef}
              label={label}
              disabled={disabled}
              value={selectedTime}
              open={showTimeOptions}
              timeSteps={{ hours: 1, minutes: 15 }}
              onChange={handleTimeSelection}
              onOpen={handleInput}
              onClose={handleInput}
              onError={handleError}
              minTime={minTime}
              closeOnSelect={true}
              slots={{
                openPickerIcon: pickerIcon,
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  fullWidth: true,
                  helperText: error ? "Invalid time" : null,
                },
                actionBar: {
                  sx: { display: "none" },
                },
              }}
              sx={{
                minHeight: 95,
                "& .MuiInputBase-root": {
                  marginTop: theme.spacing(3.5),
                  borderRadius: "8px",
                  border: `1.5px solid ${theme.palette.secondary.light}`,
                  boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
                  position: "relative",
                  background: theme.palette.secondary.dark,
                  fontSize: 14,
                  width: "100%",
                  padding: "8px",
                  transition: theme.transitions.create([
                    "border-color",
                    "background-color",
                    "box-shadow",
                  ]),
                  "&:focus-within": {
                    borderColor: theme.palette.primary.main,
                  },
                  boxSizing: "border-box",
                  minHeight: 44,
                  "::before, ::after": {
                    content: "none",
                  },
                  "&.Mui-error": {
                    color: theme.palette.error.main,
                    borderColor: theme.palette.error.main,
                  },
                  "&.Mui-error > .MuiInputAdornment-root > *": {
                    color: theme.palette.error.main,
                  },
                },
                "& .MuiInputBase-input": {
                  padding: 0,
                  paddingRight: "8px;",
                  "&:focus-visible": {
                    boxShadow: "none",
                  },
                },
                "& .MuiIconButton-root": {
                  padding: 0,
                },
                "& .MuiInputAdornment-root": {
                  marginRight: "10px",
                },
              }}
            />
          </Box>
          {includeTimeZone && (
            <Typography
              sx={{
                px: 1,
                py: 1.25,
                whiteSpace: "nowrap",
                mt: "2px",
              }}
            >
              Local time ({timeOffset})
            </Typography>
          )}
        </Stack>
      </Box>
    </LocalizationProvider>
  );
}
