import { SVGProps } from "react";
export default function PersonMenu({ ...props }: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="26"
			height="20"
			viewBox="0 0 26 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>person-menu</title>
			<path
				d="M11.75 6.25C11.75 8.325 10.075 10 8 10C5.925 10 4.25 8.325 4.25 6.25C4.25 4.175 5.925 2.5 8 2.5C10.075 2.5 11.75 4.175 11.75 6.25ZM15.5 20H0.5V17.5C0.5 14.7375 3.8625 12.5 8 12.5C12.1375 12.5 15.5 14.7375 15.5 17.5M25.5 10V12.5H14.25V10M25.5 5V7.5H14.25V5M25.5 0V2.5H14.25V0H25.5Z"
				fill="currentColor"
			/>
		</svg>
	);
}
