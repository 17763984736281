import { useContext, useEffect } from "react";
import DragDrop from "@uppy/drag-drop";
import StatusBar from "@uppy/status-bar";
import { Box, Typography, Button, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./HandleFileUpload.css";
import { UppyContext } from "@/models/UppyContextProvider";
import { FeedContext } from "@/models/FeedContextProvider";

export default function HandleFileUpload({ dragDropId, statusBarId }) {
  const {
    addedFile,
    hasFiles,
    note,
    removeFiles,
    uploading,
    uppyClient,
    removePlugin,
  } = useContext(UppyContext);

  const { setFileUploadModal, setStatus: setFeedStatus } =
    useContext(FeedContext);

  const uploadFile = async () => {
    try {
      setFeedStatus({ message: "Sending message...", severity: "info" });
      setFileUploadModal(false);
      await uppyClient?.upload();
      setFeedStatus(null);
    } catch (e) {
      console.log("Error uploading file", e);
    }
  };

  useEffect(() => {
    const dragDrop = uppyClient?.getPlugin("DragDrop");
    const dragDropTarget = `#${dragDropId}`;
    if (dragDrop) {
      dragDrop.setOptions({ target: dragDropTarget });
    } else {
      uppyClient?.use(DragDrop, {
        target: dragDropTarget,
        note,
        locale: {
          strings: {
            dropHereOr: "Drag files here to upload, or %{browse}",
            browse: "Browse Files",
          },
        },
      });
    }

    const statusBar = uppyClient?.getPlugin("StatusBar");
    const statusBarTarget = `#${statusBarId}`;

    if (statusBar) {
      statusBar.setOptions({ target: statusBarTarget });
    } else {
      uppyClient?.use(StatusBar, {
        target: statusBarTarget,
        showProgressDetails: true,
        hideUploadButton: true,
      });
    }

    return () => {
      removePlugin("DragDrop");
      removePlugin("StatusBar");
    };
  }, [note, uppyClient, removePlugin, dragDropId, statusBarId]);

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        maxHeight: "455px",
        border: "dashed 2px #ccc",
        padding: "2rem 2rem 1rem 2rem",
        borderRadius: "1rem",
        mb: 0,
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          id={dragDropId}
          sx={{
            display: !hasFiles ? "block" : "none",
            cursor: "pointer",
            width: "100%",
            height: "100%",
          }}
        />
        <Stack
          id={statusBarId}
          className={!uploading && "not-uploading"}
          sx={{ alignItems: "center", gap: 1 }}
        >
          <Typography sx={{ fontWeight: 700, textAlign: "center" }}>
            {addedFile?.name}
          </Typography>
          {hasFiles && !uploading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ padding: "12px 48px" }}
                onClick={uploadFile}
              >
                Upload File
              </Button>
              <button
                className="remove-file"
                onClick={removeFiles}
                type="button"
                aria-label="Remove File"
              >
                <CloseIcon sx={{ fontSize: "10px" }} />
              </button>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
