import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicButtonStyles from "@/theme/BasicButtonStyles";
import { AccountInfo } from "@/models/accountInfo";
import { Feed } from "@/generated/client";
import { Box, Button } from "@mui/material";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { ActionContext } from "@/models/ActionsProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import Locator from "@/locator";
export default function SendDirectMessage({
  account,
  feed,
  closeContext,
}: { account: AccountInfo; feed: Feed; closeContext: () => void }) {
  const { createWorkspaceDm } = useContext(ActionContext);
  const navigate = useNavigate();
  const { normalButtonStyles, normalIconStyles } = BasicButtonStyles();
  const [disabled, setDisabled] = useState<boolean>(false);

  const sendDirectMessage = async (account: AccountInfo) => {
    setDisabled(() => true);
    const newWorkspaceDm = await createWorkspaceDm(
      account.workspaceMembership.workspaceId,
      [account.workspaceMembership.id],
    );
    if (newWorkspaceDm) {
      navigate(
        `/workspaces/${account.workspaceMembership.workspaceId}/feeds/${newWorkspaceDm.id}`,
      );
    }
    closeContext();
  };

  return (
    <Button
      disabled={disabled}
      aria-label={Locator.feed.members.list.context.sendDirectMessage}
      sx={normalButtonStyles}
      onClick={() => sendDirectMessage(account)}
    >
      <Box sx={normalIconStyles}>
        <AddCommentOutlinedIcon sx={{ transform: "scaleX(-1)" }} />
      </Box>
      <Box>Send Direct Message</Box>
    </Button>
  );
}
