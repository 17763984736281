import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import ModalForm, { MODAL_DEFAULT_Z_INDEX } from "@/elements/ModalForm";
import Locator from "@/locator";

interface Props {
  id: string;
  visible?: boolean;
  loading?: boolean;
  label?: string;
  text?: string;
  deleteText?: string;
  denyCloseHandler: Function;
  confirmCloseHandler: Function;
  ariaLabel?: string;
}
export default function ConfirmDiscardChanges({
  id,
  visible,
  loading,
  label = "Unsaved Changes",
  text = "Are you sure you want to discard this message? Your content will be lost.",
  deleteText = "Discard",
  denyCloseHandler,
  confirmCloseHandler,
  ariaLabel = Locator.workspaceNav.confirmDeleteModal.main,
}: Props) {
  return (
    <ModalForm
      id={id}
      open={visible}
      onClose={() => denyCloseHandler()}
      aria-label={ariaLabel}
    >
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          gap: 2,
        }}
      >
        <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
          {label}
        </Typography>
        <Typography sx={{ fontWeight: 600 }}>{text}</Typography>
        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            sx={{ order: { xs: 1, sm: 0 } }}
            onClick={() => denyCloseHandler()}
            aria-label={Locator.workspaceNav.confirmDeleteModal.cancel}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="error"
            sx={{ order: { xs: 0, sm: 1 } }}
            onClick={() => confirmCloseHandler()}
            aria-label={Locator.workspaceNav.confirmDeleteModal.confirm}
          >
            {deleteText}
          </LoadingButton>
        </Stack>
      </Stack>
    </ModalForm>
  );
}
