import { SVGProps } from "react";
export default function CrownIcon({ ...props }: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="18"
			height="22"
			viewBox="0 0 18 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9 0L18 4V10C18 15.55 14.16 20.74 9 22C3.84 20.74 0 15.55 0 10V4L9 0ZM9 2.18L2 5.3V10.22C2 14.54 5.25 19 9 20C12.75 19 16 14.54 16 10.22V5.3L9 2.18ZM13 13V14.5V14.59C12.96 14.81 12.78 14.96 12.53 15H12.43H5.57H5.47C5.22 14.96 5.04 14.81 5 14.59V14.5V13H13ZM14 7L13 12H5L4 7L6.67 9.67L9 7.34L11.33 9.67L14 7Z"
			/>
		</svg>
	);
}
