import { API, graphqlOperation } from "@aws-amplify/api";

const publishDoc = /* GraphQL */ `
  mutation Publish($data: AWSJSON!, $name: String!) {
    publish(data: $data, name: $name) {
      data
      name
    }
  }
`;

const subscribeFilter = /* GraphQL */ `
  subscription subscribeFilter($filter: AWSJSON!) {
    subscribeFilter(filter: $filter) {
      data
      name
    }
  }
`;

const subscribeSingle = /* GraphQL */ `
  subscription Subscribe($name: String!) {
    subscribe(name: $name) {
      data
      name
    }
  }
`;

export default class PubSub {
	subscribeFilter(names: string[], next: any, error?: any) {
		const filter = JSON.stringify({ name: { in: names } });
		return (
			API.graphql({
				query: subscribeFilter,
				variables: { filter },
			}) as any
		).subscribe({
			next: ({ provider, value }: { provider: any; value: any }) => {
				next(value.data.subscribeFilter, provider, value);
			},
			error: console.error,
			complete: () => console.log("complete filter subsciprtion", names),
		});
	}
	subscribe(name: string, next: any, error?: any) {
		return (
			API.graphql({
				query: subscribeSingle,
				variables: { name },
			}) as any
		).subscribe({
			next: ({ provider, value }: { provider: any; value: any }) => {
				next(value.data.subscribe, provider, value);
			},
			error: console.error,
			complete: () => console.log("complete filter subsciprtion", name),
		});
	}
	constructor(public endpoint: string, public key: string) {
		const config = {
			aws_appsync_graphqlEndpoint: endpoint,
			aws_appsync_region: "us-east-2",
			aws_appsync_authenticationType: "API_KEY",
			aws_appsync_apiKey: key,
		};
		API.configure(config);
	}

	async publish(name: string, data: Object) {
		// console.log('publishing', name, data);

		const variables = {
			data: JSON.stringify(data),
			name,
		};
		return await API.graphql(graphqlOperation(publishDoc, variables));
	}
}
