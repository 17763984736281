import { useTheme } from "@mui/material";

/**
 * Button Styles for Non-Material UI buttons
 */
export default function BasicButtonStyles() {
	const theme = useTheme();

	const normalButtonStyles = {
		p: "0.5rem",
		lineHeight: "normal",
		textTransform: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "start",
		"&:disabled": {
			background: theme.palette.secondary.main,
		},
	};

	const normalIconStyles = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		mr: 1,
		width: "25px",
	};

	return {
		normalButtonStyles,
		normalIconStyles,
	};
}
