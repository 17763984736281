import Loading from "@/components/Loading/Index";
import ModalForm from "@/elements/ModalForm";
import UseTimeouts from "@/hooks/useTimeouts";
import { DataContext } from "@/models/DataProvider";
import type { AccountInfo } from "@/models/accountInfo";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, useTheme } from "@mui/material";
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import React, { useContext, useEffect, useState } from "react";

export default function DriverPanel({
  account,
  killKey,
}: {
  account: AccountInfo;
  killKey?: boolean;
}) {
  const hasMapsKey: string | null = import.meta.env.VITE_MAPS_KEY ?? null;
  const theme = useTheme();
  const { fetchGeoCoords } = useContext(DataContext);
  const [mapsModal, setMapsModal] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const [lastChecked, setLastChecked] = useState<Date>(new Date());
  const [lastCheckedString, setLastCheckedString] = useState<string>("");
  const [loadingPosition, setLoadingPosition] = useState<boolean>(false);
  const [mapsKey, setMapsKey] = useState<string | null>(null);
  const [position, setPosition] = useState<{ lat: number; lng: number } | null>(
    null,
  );

  const fetchGeoSpatialInfo = async (
    refreshValue: boolean,
  ): Promise<{
    lat: number;
    lng: number;
  }> => {
    setDisabled(() => true);
    const latLng = await fetchGeoCoords(
      account.workspaceMembership.workspaceId,
      [account.workspaceMembership.id],
    );
    if (refreshValue) {
      setForceRefresh(() => true);
    }
    setDisabled(() => false);
    setLastChecked(() => new Date());
    return latLng;
  };

  useEffect(() => {
    if (!hasMapsKey) {
      return;
    }
    if (killKey) {
      setLoadingPosition(() => true);
      if (!position?.lat) {
        fetchGeoSpatialInfo(false)
          .then((positionData) => {
            setLoadingPosition(() => false);
            setPosition(() => positionData);
            setMapsKey(() => null);
            setMapsKey(() => hasMapsKey);
          })
          .catch(() => {
            setLoadingPosition(() => false);
          });
      }
    }
  }, [killKey, account?.workspaceMembership?.id]);

  const transformDateDiff = (dateOne: Date, dateTwo: Date): string => {
    const diff = (dateOne.getTime() - dateTwo.getTime()) / 1000;
    const seconds = Math.abs(Math.round(diff));
    const minutes = Math.abs(Math.round(diff / 60));
    const hours = (diff / (60 * 60)).toFixed(1);
    if (seconds < 60) {
      return `${seconds}s`;
    }
    if (seconds >= 60) {
      return `${minutes}m`;
    }
    return `${hours}h`;
  };

  const { refresh } = UseTimeouts(60);
  useEffect(() => {
    if ((refresh && lastChecked) || forceRefresh) {
      setLastCheckedString(() => transformDateDiff(new Date(), lastChecked));
      setForceRefresh(() => false);
    }
  }, [refresh, lastChecked]);

  return (
    <>
      <ModalForm
        maxWidth={800}
        open={mapsModal}
        onClose={() => setMapsModal(() => false)}
      >
        <Box>
          <APIProvider apiKey={mapsKey} libraries={["marker"]}>
            <Map
              mapId={`map-id-expanded-${account.id}`}
              style={{
                width: "750px",
                height: "450px",
                borderRadius: "0.5rem",
              }}
              defaultCenter={position}
              defaultZoom={15}
              gestureHandling={"greedy"}
            >
              <AdvancedMarker position={position} title="Current Location">
                <div
                  style={{
                    width: 16,
                    height: 16,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    background: "#0057ff",
                    border: "2px solid #fff",
                    borderRadius: "100%",
                    boxShadow: "2px 2px 2px #0000006e",
                  }}
                />
              </AdvancedMarker>
            </Map>
          </APIProvider>
        </Box>
      </ModalForm>

      <Box
        sx={{
          px: 1,
          mt: 1,
          p: 2,
          borderTop: `solid 1px ${theme.palette.secondary.light}`,
        }}
      >
        {loadingPosition && !position?.lat && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading variant="white" size="large" baseSize={6} />
          </Box>
        )}
        {mapsKey && position?.lat && position?.lng && (
          <Box>
            <Box sx={{ display: "flex", mb: 1, width: "100%" }}>
              <Box sx={{ flexGrow: "1" }}>Location</Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                {lastCheckedString} ago
                <Button
                  disabled={disabled}
                  onClick={() => fetchGeoSpatialInfo(true)}
                  sx={{
                    ml: 1,
                    p: 0,
                    width: "auto",
                    minWidth: 0,
                    borderRadius: "100%",
                    background: "#fff",
                    color: "#000",
                    "&:hover": { background: "#efefef" },
                  }}
                >
                  <RefreshIcon sx={{ fontSize: "18px" }} />
                </Button>
              </Box>
            </Box>
            <APIProvider apiKey={mapsKey} libraries={["marker"]}>
              <Map
                mapId={`map-id-${account.id}`}
                style={{
                  width: "278px",
                  height: "140px",
                  borderRadius: "0.5rem",
                  position: "relative",
                }}
                defaultCenter={position}
                defaultZoom={15}
                gestureHandling={"greedy"}
                fullscreenControl={false}
              >
                {disabled && (
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: theme.palette.secondary.light,
                      opacity: 0.9,
                      borderRadius: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loading variant="white" size="large" baseSize={6} />
                  </Box>
                )}

                <Button
                  onClick={() => setMapsModal(() => true)}
                  sx={{
                    position: "absolute",
                    color: "#000",
                    boxShadow: "1px 1px 2px #000",
                    width: "auto",
                    background: "#fff",
                    minWidth: 0,
                    p: 0,
                    lineHeight: "auto",
                    right: 5,
                    top: 5,
                    "&:hover": {
                      background: "#fff",
                    },
                  }}
                >
                  <FullscreenIcon />
                </Button>

                <AdvancedMarker position={position} title="Current Location">
                  <div
                    style={{
                      width: 16,
                      height: 16,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      background: "#0057ff",
                      border: "2px solid #fff",
                      borderRadius: "100%",
                      boxShadow: "2px 2px 2px #0000006e",
                    }}
                  />
                </AdvancedMarker>
              </Map>
            </APIProvider>
          </Box>
        )}
      </Box>
    </>
  );
}
