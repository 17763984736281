import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { useParams } from "react-router-dom";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { ActionContext } from "@/models/ActionsProvider";
import { useCallback, useContext, useState } from "react";
import { Box, Button, Typography, Stack, useTheme } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  gridStringOrNumberComparator,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { format, set } from "date-fns";
import { DataGridPagination } from "../Pagination";
import { LoadingButton } from "@mui/lab";
import Locator from "@/locator";
import { UserManagementContext } from "@/models/UserManagementContextProvider";

export default function WorkspaceInvitesTable() {
  const { db } = useElectric();
  const { removeMember } = useContext(ActionContext);
  const { ampli } = useContext(TrackingContext);
  const { activeSearchField, searchFilter, handleSearchFilter } = useContext(
    UserManagementContext,
  );
  const params = useParams();
  const workspaceId = params?.workspaceId as string as string;
  const theme = useTheme();
  const [revokingId, setRevokingId] = useState<string>("");

  const { results: workspaceInvites } = useLiveQuery(() => {
    if (!workspaceId) return;
    return db.liveRaw({
      sql: `
			SELECT 
				direct_ws_invitation.email, 
				direct_ws_invitation.phoneNumber,
				direct_ws_invitation.name,
				direct_ws_invitation.createdAt as date,
				direct_ws_invitation.workspaceMembershipId as id, 
				workspace_membership.status as membershipStatus
			FROM 
				direct_ws_invitation
			JOIN 
				workspace_membership 
			ON 
				direct_ws_invitation.workspaceMembershipId = workspace_membership.id
			WHERE 
				workspace_membership.workspaceId = ?
			AND 
				workspace_membership.status = 'active'
			AND 
				direct_ws_invitation.claimedBy is null
			ORDER BY 
				direct_ws_invitation.createdAt DESC`,
      args: [workspaceId],
    });
  }, [workspaceId]) as {
    results: {
      id: string;
      name: string;
      email: string;
      phoneNumber: string;
      date: string;
      membershipStatus: string;
    }[];
  };

  const formatDate = (date: string) => {
    return format(new Date(date), "MMM dd, yyyy");
  };

  const revokeInvite = useCallback(
    async (workspaceMembershipId: string) => {
      try {
        setRevokingId(workspaceMembershipId);
        if (workspaceId) {
          ampli.revokePendingInvite();
          // happy path for workspaces mvp, set the invite membership status to 'inactive', which follows the same functionality of updating a member
          await removeMember(workspaceId, [workspaceMembershipId]);
        }
      } catch (e) {
        console.log(e);
      }
      setRevokingId("");
    },
    [ampli, workspaceId, removeMember],
  );

  const columns: GridColDef[] = [
    {
      field: "contact",
      headerName: "Contact",
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      sortComparator: gridStringOrNumberComparator,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.email || params.row.phoneNumber,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Typography fontWeight={700}>
              {params?.row?.email || params?.row?.phoneNumber}
            </Typography>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Typography fontWeight={700}>{params.row.name}</Typography>
          </>
        );
      },
    },
    {
      field: "date",
      headerName: "Invitation Date",
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatDate(params?.row?.date);
      },
    },
    {
      field: "revoke",
      headerName: "",
      width: 115,
      editable: false,
      sortable: false,
      align: "right",
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row?.mine) {
          const pendingInviteId = params.row?.email || params.row?.phoneNumber;
          return (
            <LoadingButton
              loading={revokingId === params.row.id}
              onClick={() => revokeInvite(params.row.id)}
              aria-label={
                Locator.workspaceNav.members.pendingInvites.revokeButton
              }
              data-testid={Locator.workspaceNav.members.pendingInvites.revokeTestId(
                pendingInviteId,
              )}
              sx={{
                padding: "8px 12px",
                fontSize: "0.875rem",
                background: theme.palette.secondary.dark,
                maxWidth: "85px",
                lineHeight: 1,
              }}
            >
              REVOKE
            </LoadingButton>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1300,
        overflow: "hidden",
        height: "100%",
      }}
    >
      {workspaceInvites?.length > 0 ? (
        <DataGrid
          rows={workspaceInvites}
          columns={columns}
          pagination
          pageSizeOptions={[100, 60, 20]}
          slots={{
            pagination: DataGridPagination,
            toolbar: GridToolbarQuickFilter,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              inputRef: activeSearchField,
              inputProps: {
                placeholder: "Search for members",
              },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 20 },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [searchFilter],
              },
            },
          }}
          onFilterModelChange={handleSearchFilter}
          hideFooter={workspaceInvites?.length === 0}
        />
      ) : (
        <Stack
          sx={{
            width: "100%",
            py: 1.5,
            px: 2,
            background: theme.palette.secondary.main,
            borderRadius: 1.5,
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>No pending invites</Typography>
        </Stack>
      )}
    </Box>
  );
}
