import { SVGProps } from "react";
export default function ChannelDial({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>channel-dial</title>
      <path
        d="M11.7286 3.05078C11.124 3.05078 10.6364 2.5625 10.6364 1.95703C10.6364 1.35156 11.124 0.863281 11.7286 0.863281C12.343 0.863281 12.8306 1.35156 12.8306 1.95703C12.8306 2.5625 12.343 3.05078 11.7286 3.05078ZM4.29762 6.16602C3.68324 6.16602 3.20539 5.67773 3.20539 5.07227C3.20539 4.4668 3.68324 3.97852 4.29762 3.97852C4.90224 3.97852 5.38984 4.4668 5.38984 5.07227C5.38984 5.67773 4.90224 6.16602 4.29762 6.16602ZM19.1694 6.16602C18.5648 6.16602 18.0772 5.67773 18.0772 5.07227C18.0772 4.4668 18.5648 3.97852 19.1694 3.97852C19.774 3.97852 20.2616 4.4668 20.2616 5.07227C20.2616 5.67773 19.774 6.16602 19.1694 6.16602ZM11.7286 19.6035C10.022 19.6035 8.44222 18.998 7.22322 17.9824L12.3333 12.9336C12.6746 12.6016 12.6648 12.1133 12.3333 11.7617C12.0407 11.459 11.5043 11.4492 11.1923 11.7617L6.1505 16.8984C5.18505 15.6777 4.60968 14.1543 4.60968 12.4746C4.60968 8.54883 7.81809 5.35547 11.7286 5.35547C15.6489 5.35547 18.8476 8.54883 18.8476 12.4746C18.8476 16.4297 15.6489 19.6035 11.7286 19.6035ZM1.22574 13.5684C0.621114 13.5684 0.133514 13.0801 0.133514 12.4746C0.133514 11.8691 0.621114 11.3809 1.22574 11.3809C1.83036 11.3809 2.31796 11.8691 2.31796 12.4746C2.31796 13.0801 1.83036 13.5684 1.22574 13.5684ZM22.2413 13.5684C21.6367 13.5684 21.1491 13.0801 21.1491 12.4746C21.1491 11.8691 21.6367 11.3809 22.2413 11.3809C22.8459 11.3809 23.3335 11.8691 23.3335 12.4746C23.3335 13.0801 22.8459 13.5684 22.2413 13.5684ZM4.29762 21.0098C3.68324 21.0098 3.18589 20.5215 3.18589 19.8965C3.18589 19.2812 3.68324 18.793 4.29762 18.793C4.91199 18.793 5.39959 19.2812 5.39959 19.8965C5.39959 20.5215 4.91199 21.0098 4.29762 21.0098ZM19.1694 21.0098C18.555 21.0098 18.0674 20.5215 18.0674 19.8965C18.0674 19.2812 18.555 18.793 19.1694 18.793C19.7838 18.793 20.2811 19.2812 20.2811 19.8965C20.2811 20.5215 19.7838 21.0098 19.1694 21.0098Z"
        fill="currentColor"
      />
    </svg>
  );
}
