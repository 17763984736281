import React, { ReactNode, useEffect, useState } from "react";
import { makeElectricContext } from "electric-sql/react";
import { electrify } from "electric-sql/wa-sqlite";
// import { electrify } from "./wa-sqlite";
import { Electric, schema } from "@/generated/client";
import { ElectricDatabase } from "./wa-sqlite/database";

export const { ElectricProvider, useElectric } =
  makeElectricContext<Electric>();

export const ElectricWrapper = ({ children }) => {
  const [electric, setElectric] = useState<Electric>();

  useEffect(() => {
    let isMounted = true;

    const init = async () => {
      const config = {
        auth: {
          token: "abc",
        },
      };
      const conn = await ElectricDatabase.init(":memory:", "/");
      const electric = await electrify(conn, schema, config);

      if (!isMounted) {
        return;
      }

      setElectric(electric);
      electric.db.account_event.deleteMany({});
    };

    init();

    return () => {
      isMounted = false;
    };
  }, []);

  if (electric === undefined) {
    return null;
  }

  return <ElectricProvider db={electric}>{children}</ElectricProvider>;
};
