import { useElectric } from "@/electric/ElectricWrapper";
import { Feed, FeedGroup } from "@/generated/client";
import { useLiveQuery } from "electric-sql/react";
import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";

export interface FeedWithGroup extends Feed {
  groupId?: string;
  latestActivity?: string;
  feedId?: string;
  name?: string;
}
interface GroupWithFeeds extends FeedGroup {
  feeds: FeedWithGroup[];
}

export type LiveQueries = {
  fetchNextActiveChannel?: (groupId?: string) => Promise<string>;
  myActiveFeeds?: Array<FeedWithGroup>;
  groups?: Array<FeedGroup>;
  groupsOfFeeds?: Array<GroupWithFeeds>;
  nonGroupedFeeds?: Array<FeedWithGroup>;
  fetchGroupCounts?: (groupId: string) => Promise<number>;
};

export const LiveQueryContext = createContext<LiveQueries>({});
type Props = {
  children: React.ReactNode | React.ReactNode[];
};
const LiveQueriesProvider = ({ children }: Props) => {
  const { db } = useElectric();
  const params = useParams();
  const workspaceId = params?.workspaceId ?? null;
  const feedId = params?.feedId ?? null;

  // Current logged-in user's account
  const { results: myAccount } = useLiveQuery(
    db.account.liveFirst({
      where: {
        mine: 1,
      },
    }),
  );

  // List of memberships for the current user
  const { results: myMemberships } = useLiveQuery(() => {
    if (!myAccount?.id) return;
    return db.workspace_membership.liveMany({
      where: {
        accountId: myAccount?.id,
        status: "active",
      },
    });
  }, [myAccount?.id]);

  // Get the current workspace membership
  const workspaceMembership =
    myMemberships?.find((m) => m?.workspaceId === workspaceId) ?? null;

  // Get the workspace membership id
  const workspaceMembershipId = workspaceMembership?.id ?? null;

  // List of active feeds that the current user has permissions to
  const { results: myActiveFeeds } = useLiveQuery(() => {
    if (!workspaceId || !workspaceMembership?.id) return;
    const sql = `
        SELECT
            feed.id,
            feed.title,
            feed.workspaceId,
            feed.updatedAt,
            feed_group.id as groupId,
            (MAX(
                    MAX(coalesce(item.createdAt, '0')),
                    coalesce(feed.updatedAt, '0'),
                    coalesce(feed.latestActivity, '0')
             )) as latestActivity
        FROM
            feed
                JOIN permission ON permission.feedId = feed.id
                JOIN workspace_membership on workspace_membership.id = permission.workspace_membershipId
                JOIN account ON account.id = workspace_membership.accountId
                LEFT OUTER JOIN item ON feed.id = item.feedId
                LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
                LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
        WHERE
            feed.workspaceId = ?
          AND
            feed.id IN ( SELECT permission.feedId FROM permission WHERE permission.workspace_membershipId = ? AND permission.name = 'read' AND enabled = true)
          AND
            feed.isDm = 0
          AND
            permission.workspace_membershipId = ?
          AND
            permission.enabled = 1
        GROUP BY feed.id
        ORDER BY latestActivity DESC
        `;
    return db.liveRaw({
      sql,
      args: [workspaceId, workspaceMembership?.id, workspaceMembership?.id],
    });
  }, [workspaceId, workspaceMembership?.id]);

  // Fetch the next active channel available to the user
  const fetchNextActiveChannel = async (groupId?: string): Promise<string> => {
    const realOrFakeFeedId = !feedId ? "NULL" : feedId;
    const realOrFakeGroupId = !groupId ? "NULL" : groupId;

    const activeChannel = await db.raw({
      sql: `
        SELECT
            permission.feedId as feedId,
            permission.workspace_membershipId as workspaceMembershipId,
            permission.enabled as enabled,
            feed.title,
            feed.id,
            feed_group_membership.groupId as groupId,
            (MAX(
                coalesce(feed.updatedAt, '0'),
                coalesce(feed.latestActivity, '0'),
                coalesce(item.createdAt, '0')
             )) as latestActivity
        FROM
            permission
            JOIN feed ON feed.id = permission.feedId
            LEFT OUTER JOIN feed_group_membership on feed.id = feed_group_membership.feedId
            LEFT OUTER JOIN item ON feed.id = item.feedId
        WHERE permission.workspace_membershipId = ?
        AND permission.enabled = 1
        AND feed.workspaceId = ?
        AND feed.id != ?
        AND (feed_group_membership.groupId != ? OR feed_group_membership.groupId IS NULL)
        GROUP BY feed.id
        ORDER BY latestActivity DESC
        LIMIT 1
      `,
      args: [
        workspaceMembershipId,
        workspaceId,
        realOrFakeFeedId,
        realOrFakeGroupId,
      ],
    });

    if (activeChannel?.length === 0) {
      return `/workspaces/${workspaceId}/no-channels-available`;
    }
    return `/workspaces/${workspaceId}/feeds/${activeChannel[0]?.id}`;
  };

  const { results: groups } = useLiveQuery(() => {
    if (!workspaceId) return;
    const sql = `
    SELECT
        feed_group.id,
        feed_group.name,
        feed_group.updatedAt,
        feed_group_membership.feedId,
        (MAX(
                MAX(coalesce(item.createdAt, '0')),
                coalesce(feed.updatedAt, '0'),
                coalesce(feed.latestActivity, '0')
         )) as latestActivity
    FROM feed_group
        JOIN feed_group_membership on feed_group.id = feed_group_membership.groupId
        JOIN feed on feed.id = feed_group_membership.feedId
        JOIN permission ON permission.feedId = feed.id
        LEFT OUTER JOIN item ON feed.id = item.feedId
    WHERE
        feed_group.workspaceId = ?
    AND
        feed.id IN ( SELECT permission.feedId FROM permission WHERE permission.workspace_membershipId = ? AND permission.name = 'read' AND enabled = true)
    GROUP BY feed_group.id
    `;
    return db.liveRaw({ sql, args: [workspaceId, workspaceMembershipId] });
  }, [workspaceId, workspaceMembershipId]);

  const { results: groupedFeeds } = useLiveQuery(() => {
    if (!feedId) return;
    const sql = `
    SELECT
        feed.id,
        feed.title,
        feed.workspaceId,
        feed.updatedAt,
        feed_group_membership.groupId as groupId,
        (MAX(
                coalesce(feed.updatedAt, '0'),
                coalesce(feed.latestActivity, '0'),
                coalesce(item.createdAt, '0')
         )) as latestActivity
    FROM
        feed
        LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
        LEFT JOIN permission on feed.id = permission.feedId
        LEFT OUTER JOIN item ON feed.id = item.feedId
        LEFT OUTER JOIN feed_group on feed_group.id = feed_group_membership.groupId
    WHERE
        feed.workspaceId = ?
        AND
        permission.enabled = 1
        AND
        permission.name = 'read'
        AND permission.enabled = true
        AND permission.workspace_membershipId = ?
    GROUP BY feed.id
    ORDER BY feed_group_membership.groupId, latestActivity ASC 
    `;
    return db.liveRaw({ sql, args: [workspaceId, workspaceMembershipId] });
  }, [workspaceId, workspaceMembershipId, groups]);

  const groupsOfFeeds: Array<GroupWithFeeds> = groups?.map((group) => ({
    ...group,
    feeds: groupedFeeds?.filter(
      (feed: FeedWithGroup) => feed?.groupId === group.id,
    ),
  }));

  const nonGroupedFeeds: FeedWithGroup[] = myActiveFeeds?.filter(
    (feed: FeedWithGroup) => !feed?.groupId,
  );

  const fetchGroupCounts = async (groupId: string): Promise<number> => {
    const groupedFeedItems = await db.raw({
      sql: `
      SELECT
          feed_group.id as feedGroupId,
          feed_group_membership.feedId,
          feed_group_membership.workspaceId
      FROM
          feed_group
          JOIN feed_group_membership on feed_group.id = feed_group_membership.groupId
          JOIN feed on feed.id = feed_group_membership.feedId
          JOIN permission on feed.id = permission.feedId
      WHERE
          feed_group.id = ?
          AND feed.workspaceId = ?
          AND permission.workspace_membershipId = ?
          AND permission.enabled = 1
          AND permission.name = 'read'
          AND permission.enabled = true
      `,
      args: [groupId, workspaceId, workspaceMembershipId],
    });

    return groupedFeedItems?.length;
  };

  /// →  →  →  →  →  →  →  →  →  →  →  →  →  →  →  →  Return All Live Queries
  const LiveQueryState: LiveQueries = {
    fetchNextActiveChannel,
    fetchGroupCounts,
    myActiveFeeds,
    groups,
    groupsOfFeeds,
    nonGroupedFeeds,
  };

  return (
    <LiveQueryContext.Provider value={LiveQueryState}>
      {children}
    </LiveQueryContext.Provider>
  );
};

export default LiveQueriesProvider;
