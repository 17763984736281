import ChannelGroupButton from "@/components/Buttons/ChannelGroupButton";
import { FullInput } from "@/components/Utils";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { FeedContext } from "@/models/FeedContextProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { SchedulingContext } from "@/models/SchedulingContextProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Search, Send } from "@mui/icons-material";
import * as Icons from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import cuid from "cuid";
import Fuse from "fuse.js";
import { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

interface CheckedItem {
  groupId: string;
  id: string | null;
}

export default function FleetMessageSelectChannels() {
  const theme = useTheme();
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const { setStatus } = useContext(FeedContext);
  const { setFleetMessageModalOpen } = useContext(UxContext);
  const { scheduledText, selectedChannels, setSelectedChannels, resetAll } =
    useContext(SchedulingContext);
  const { nonGroupedFeeds, groups, groupsOfFeeds } =
    useContext(LiveQueryContext);
  const { publishBroadcast } = useContext(ActionContext);
  const { ampli } = useContext(TrackingContext);

  const [searchField, setSearchField] = useState<string>("");
  const [activeGroups, setActiveGroups] = useState<string[]>([]);
  const [sending, setSending] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<Array<CheckedItem>>(
    [] as CheckedItem[],
  );

  const filteredNonGroupedFeeds = useMemo(() => {
    if (!searchField || searchField?.length === 0) {
      return nonGroupedFeeds?.sort((a, b) => a.title?.localeCompare(b?.title));
    }
    const fuseSearchNonGroupedFeeds = new Fuse(nonGroupedFeeds, {
      useExtendedSearch: true,
      keys: ["title", "id"],
    });
    return fuseSearchNonGroupedFeeds
      .search(`'${searchField}`)
      ?.map((result) => result?.item)
      .sort((a, b) => a?.title?.localeCompare(b?.title));
  }, [searchField, nonGroupedFeeds]);

  const filteredGroupsOfFeeds = useMemo(() => {
    if (!searchField || searchField?.length === 0) {
      return groupsOfFeeds?.sort((a, b) => a?.name?.localeCompare(b?.name));
    }
    const findGroupName = new Fuse(groupsOfFeeds, {
      useExtendedSearch: true,
      keys: ["name", "id"],
    });

    const findGroupsByGroupName = findGroupName
      ?.search(`'${searchField}`)
      .map((item) => item?.item);

    const flatGroupOfFeeds = groupsOfFeeds
      ?.map((group) => group?.feeds?.flat())
      ?.flat();

    const findGroupedFeedByTitle = new Fuse(flatGroupOfFeeds, {
      useExtendedSearch: true,
      keys: ["title", "id"],
    });

    const foundFeeds = findGroupedFeedByTitle
      .search(`'${searchField}`)
      ?.map((item) => item?.item?.id);

    return groupsOfFeeds
      ?.map((item) => {
        const hasGroupBySearchedName = findGroupsByGroupName.find(
          (group) => group?.id === item?.id,
        );
        const feedIncludesFoundFeedId = !!item.feeds?.find((feed) =>
          foundFeeds?.includes(feed.id),
        );
        if (feedIncludesFoundFeedId) {
          return {
            ...item,
            feeds: item?.feeds
              ?.filter((feed) => foundFeeds?.includes(feed.id))
              .sort((a, b) => a?.title?.localeCompare(b?.title)),
          };
        } else if (hasGroupBySearchedName) {
          return {
            ...item,
            feeds: item?.feeds?.sort((a, b) =>
              a.title?.localeCompare(b?.title),
            ),
          };
        }
      })
      ?.filter((item) => item)
      .sort((a, b) => a.name?.localeCompare(b?.name));
  }, [searchField, groupsOfFeeds]);

  const setCheckedValues = (ids: Array<CheckedItem>) => {
    const idsOnly = ids?.map((item) => item?.id);
    setSelectedChannels(idsOnly);
    setCheckedItems(() => ids);
  };

  const toggleActiveGroups = (id: string) => {
    if (activeGroups?.includes(id)) {
      setActiveGroups(
        activeGroups?.filter((activeGroupId) => activeGroupId !== id),
      );
    } else {
      setActiveGroups([...activeGroups, id]);
    }
  };

  const arrayHasItem = (array: CheckedItem[], id: string, key: string) =>
    array?.length ? !!array?.find((item) => item[key] === id) : false;

  const toggleSingleFeed = ({
    id,
    groupId,
  }: { id: string; groupId: string }) => {
    const hasActiveId = arrayHasItem(checkedItems, id, "id");
    if (hasActiveId) {
      setCheckedValues(checkedItems?.filter((item) => item.id !== id));
    } else {
      setCheckedValues([...checkedItems, { id, groupId }]);
    }
  };

  const toggleGroupedItem = (groupId: string) => {
    const groupHasItem = arrayHasItem(checkedItems, groupId, "groupId");
    const isGroupOpen = activeGroups?.includes(groupId);

    const feedItems: CheckedItem[] = groupsOfFeeds
      ?.find((group) => group.id === groupId)
      ?.feeds?.map((feed) => ({ id: feed.id, groupId }));

    const groupedCount = checkedItems?.filter(
      (item) => item.groupId === groupId,
    )?.length;

    if (isGroupOpen && groupHasItem) {
      setCheckedValues(
        checkedItems?.filter((item) => item.groupId !== groupId),
      );
    } else if (isGroupOpen && !groupHasItem) {
      setCheckedValues([...checkedItems, ...feedItems]);
    } else if (!isGroupOpen && groupHasItem) {
      if (groupedCount === feedItems?.length) {
        setCheckedValues(
          checkedItems?.filter((item) => item.groupId !== groupId),
        );
      } else {
        toggleActiveGroups(groupId);
        const newSet = [...checkedItems, ...feedItems].filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.id === value.id && t.id === value.id),
        );

        setCheckedValues(newSet);
      }
    } else if (!isGroupOpen && !groupHasItem) {
      toggleActiveGroups(groupId);
      setCheckedValues([...checkedItems, ...feedItems]);
    }
  };

  const toggleSelectAll = () => {
    if (!allItemsSelected) {
      const allGroupedFeeds = [
        ...groupsOfFeeds
          .map((group) =>
            group.feeds?.map((feed) => ({
              id: feed.id,
              groupId: feed.groupId,
            })),
          )
          .flat(),
        ...nonGroupedFeeds.map((feed) => ({ id: feed.id, groupId: null })),
      ];

      setCheckedValues(allGroupedFeeds);
    } else {
      setCheckedValues([]);
    }
  };

  const handleSendNow = async () => {
    if (checkedItems?.length === 0) {
      return;
    }
    ampli.sendFleetMessage();
    const contentId = cuid();
    const listOfIds = checkedItems?.map((item) => item.id);
    setSending(() => true);

    const payload = {
      workspaceId,
      contentId,
      feedIds: listOfIds,
      inputText: scheduledText,
    };

    try {
      await publishBroadcast(payload);
    } catch (error) {
      setError(true);
      setSending(() => false);
      return Promise.reject(error);
    }

    resetAll();
    setSelectedChannels([]);
    setFleetMessageModalOpen(false);
    setStatus({
      message: "Your fleet message was sent.",
      severity: "success",
    });
    setActiveGroups(() => []);
    setSending(() => false);
    setCheckedValues([]);
    setTimeout(() => {
      setStatus(null);
    }, 5000);
  };

  const groupCount = (arrayOfObject: CheckedItem[], id: string) => {
    const count = arrayOfObject?.filter((item) => item?.groupId === id)?.length;
    return count > 0 ? <span style={{ color: "#888" }}>({count})</span> : "";
  };

  const totalGroupedFeedsLength = filteredGroupsOfFeeds
    ?.map((group) => group.feeds?.flat())
    .flat()?.length;

  const nonGroupedFeedsLength = nonGroupedFeeds?.length;
  const checkedItemsLength = checkedItems?.length;
  const maxSelectCount = totalGroupedFeedsLength + nonGroupedFeedsLength;
  const allItemsSelected = maxSelectCount === checkedItemsLength;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mb: 1, position: "relative" }}>
        {searchField?.length > 0 && (
          <Box
            sx={{
              position: "absolute",
              right: "0.5rem",
              top: "0.5rem",
              zIndex: 5,
            }}
          >
            <IconButton
              aria-label="Clear search query"
              onClick={() => setSearchField(() => "")}
              sx={{ p: 0 }}
            >
              <Icons.Close />
            </IconButton>
          </Box>
        )}
        <FullInput
          id="channel-name"
          placeholder="Search channels"
          value={searchField}
          callback={(e) => setSearchField(e.target.value)}
          disabled={sending}
          icon={
            <Box
              sx={{
                position: "absolute",
                top: "0.7rem",
                left: "0.5rem",
                zIndex: 12,
              }}
            >
              <Search
                sx={{
                  fontSize: "1.25rem",
                }}
              />
            </Box>
          }
          aria-label={Locator.feed.input.fleetMessage.modal.search}
        />
      </Box>
      <Box sx={{ mb: 1 }}>
        <Box sx={{ px: "1.25rem" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              {allItemsSelected ? "Deselect All" : "Select All"}
            </Box>
            <Box>
              <ListItemButton
                sx={{ p: 0 }}
                aria-label={Locator.feed.input.fleetMessage.modal.selectAll}
                disabled={sending}
                onClick={() => toggleSelectAll()}
              >
                <Checkbox checked={allItemsSelected} />
              </ListItemButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "260px",
          borderRadius: 2,
          border: `1.5px solid ${theme.palette.secondary.light}`,
          boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
          position: "relative",
          background: theme.palette.secondary.dark,
          overflow: "auto",
          mb: 2,
        }}
      >
        <Box sx={{ width: "440px" }}>
          {filteredGroupsOfFeeds?.length > 0 && (
            <Box
              sx={{
                px: 2,
                pt: 1,
                fontSize: "12px",
                textTransform: "uppercase",
                color: theme.palette.neutral.main,
              }}
            >
              Groups:
            </Box>
          )}
          {filteredGroupsOfFeeds?.map((group) => (
            <Box key={group.id}>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <ChannelGroupButton
                  active={activeGroups.includes(group.id)}
                  toggleActiveState={() => toggleActiveGroups(group.id)}
                >
                  {group.name} {groupCount(checkedItems, group.id)}
                </ChannelGroupButton>
                <Box>
                  <Checkbox
                    id={`channel-group-checkbox-${group.id}`}
                    onClick={() => toggleGroupedItem(group.id)}
                    checked={
                      checkedItems.filter((item) => item.groupId === group.id)
                        ?.length === group.feeds?.length
                    }
                  />
                </Box>
              </Box>
              {group?.feeds?.length > 0 && (
                <Collapse
                  in={activeGroups.includes(group.id)}
                  timeout="auto"
                  sx={{}}
                >
                  {group?.feeds?.map((channel) => (
                    <ListItemButton
                      sx={{ py: 0, pl: 6, pr: 0 }}
                      key={channel.id}
                      onClick={() =>
                        toggleSingleFeed({ id: channel.id, groupId: group.id })
                      }
                    >
                      <Box sx={{ flexGrow: 1 }}>{channel.title}</Box>
                      <Box sx={{ flex: "0 1 auto" }}>
                        <Checkbox
                          checked={arrayHasItem(checkedItems, channel.id, "id")}
                          inputProps={{
                            "aria-labelledby": channel?.id,
                            "data-testid":
                              Locator.feed.input.fleetMessage.modal.testId(
                                channel?.id,
                              ),
                          }}
                        />
                      </Box>
                    </ListItemButton>
                  ))}
                </Collapse>
              )}
            </Box>
          ))}
          {filteredNonGroupedFeeds?.length > 0 && (
            <Box
              sx={{
                px: 2,
                fontSize: "12px",
                textTransform: "uppercase",
                color: theme.palette.neutral.main,
              }}
            >
              Channels:
            </Box>
          )}
          {filteredNonGroupedFeeds?.map((feed) => (
            <Box key={feed.id}>
              <ListItemButton
                sx={{ py: 0, pl: 6, pr: 0 }}
                key={feed.id}
                onClick={() => toggleSingleFeed({ id: feed.id, groupId: null })}
              >
                <Box sx={{ flexGrow: 1 }}>{feed.title}</Box>
                <Box sx={{ flex: "0 1 auto" }}>
                  <Checkbox
                    checked={arrayHasItem(checkedItems, feed.id, "id")}
                    inputProps={{
                      "aria-labelledby": feed?.id,
                      "data-testid":
                        Locator.feed.input.fleetMessage.modal.testId(feed?.id),
                    }}
                  />
                </Box>
              </ListItemButton>
            </Box>
          ))}
        </Box>
      </Box>
      <Stack
        sx={{
          width: "100%",
          gap: 2,
        }}
      >
        {error ? (
          <Typography
            sx={{
              fontWeight: 500,
              color: theme.palette.error.main,
              textAlign: "center",
            }}
          >
            Error: Something went wrong while sending your message.
          </Typography>
        ) : null}
        <LoadingButton
          loading={sending}
          variant="contained"
          sx={{ order: { xs: 1, sm: 0 } }}
          onClick={handleSendNow}
          disabled={checkedItems?.length === 0}
          startIcon={<Send role="presentation" />}
          aria-label={Locator.feed.input.fleetMessage.modal.send}
        >
          Send
        </LoadingButton>
      </Stack>
    </Box>
  );
}
