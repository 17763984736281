import { useElectric } from "@/electric/ElectricWrapper";
import ModalForm from "@/elements/ModalForm";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { UxContext } from "@/models/UxStateProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Typography } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from "react-router-dom";
import { FullInput } from "../Utils";

export default function WorkspaceModal() {
  const [workspaceName, setWorkspaceName] = useState("");
  const [saving, setSaving] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const { db } = useElectric();
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const navigate = useNavigate();

  const {
    workspaceModalOpen,
    setWorkspaceModalOpen,
    editWorkspaceSettings,
    setEditWorkspaceSettings,
  } = useContext(UxContext);
  const { createWorkspace, updateWorkspace } = useContext(ActionContext);

  const handleClose = useCallback(() => {
    setEditWorkspaceSettings(false);
    setWorkspaceModalOpen(false);
  }, [setWorkspaceModalOpen, setEditWorkspaceSettings]);

  const { results: workspace } = useLiveQuery(() => {
    if (!workspaceId) return;
    return db.workspace.liveUnique({
      where: {
        id: workspaceId,
      },
    });
  }, [workspaceId]);

  const handleClick = useCallback(async () => {
    try {
      if (workspaceName) {
        setSaving(true);
        if (editWorkspaceSettings) {
          await updateWorkspace(workspaceId, workspaceName);
        } else {
          const workspace = await createWorkspace(workspaceName);
          // wait for the workspace to be created and appSynced before navigating -> This prevents double redirects to the wrong workspace
          await new Promise((resolve) => setTimeout(resolve, 350));
          navigate(`/workspaces/${workspace.id}`);
        }
      }
    } catch (e) {
      showBoundary(e);
    } finally {
      setSaving(false);
      setWorkspaceModalOpen(false);
    }
  }, [workspaceName, createWorkspace, navigate]);

  useEffect(() => {
    if (workspace?.name && editWorkspaceSettings) {
      setWorkspaceName(workspace.name);
    }
  }, [workspace, editWorkspaceSettings]);

  return (
    <>
      <ModalForm
        open={workspaceModalOpen}
        onClose={handleClose}
        disableClose={saving}
      >
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              fontWeight: 500,
              gap: 1.5,
            }}
          >
            <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
              Workspace {editWorkspaceSettings && " Settings"}
            </Typography>
            {!editWorkspaceSettings && (
              <Typography>
                A place for your team to have discussions, receive updates via
                audio, and share information internally.
              </Typography>
            )}
          </Box>
          <FullInput
            id="workspace-name"
            label={Locator.workspaceNav.modal.name}
            aria-label={Locator.workspaceNav.modal.name}
            disabled={saving}
            placeholder="E.g. Company name"
            value={workspaceName}
            callback={(e) => setWorkspaceName(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2.5,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleClose()}
              disabled={saving}
              aria-label={
                !editWorkspaceSettings
                  ? Locator.workspaceNav.modal.cancelCreate
                  : Locator.workspaceNav.modal.cancelEdit
              }
              sx={{
                width: { xs: "100%", sm: "auto" },
                order: { xs: 1, sm: 0 },
                flexGrow: 1,
                flexBasis: "100%",
              }}
            >
              CANCEL
            </Button>
            <LoadingButton
              disabled={!workspaceName || saving}
              loading={saving}
              variant="contained"
              color="primary"
              onClick={handleClick}
              aria-label={
                !editWorkspaceSettings
                  ? Locator.workspaceNav.modal.continueCreate
                  : Locator.workspaceNav.modal.continueEdit
              }
              sx={{
                width: { xs: "100%", sm: "auto" },
                order: { xs: 0, sm: 1 },
                flexGrow: 1,
                flexBasis: "100%",
              }}
            >
              {!editWorkspaceSettings ? "Continue" : "Save"}
            </LoadingButton>
          </Box>
        </>
      </ModalForm>
    </>
  );
}
