import { Box, Button, Typography, useTheme } from "@mui/material";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import ModalForm from "@/elements/ModalForm";
import React from "react";

export default function BrowserErrorRefresh({
  children,
  open,
  setOpen,
}: {
  children?: React.ReactNode;
  open: boolean;
  setOpen: (value: boolean) => void;
}) {
  const theme = useTheme();
  const reloadPage = () => {
    const newUrl = new URL(window.location.href);
    // ensure that the hash #id123 is gone for true page refresh
    window.location.href = newUrl.pathname;
  };
  return (
    <ModalForm
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      allowClose={true}
      maxWidth="380px"
      maxHeight="160px"
      noPadding
      sx={{ p: 2, flexDirection: "" }}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ mr: 2, display: "flex", alignItems: "start" }}>
          <Button
            aria-label="Refresh Page"
            onClick={reloadPage}
            sx={{
              p: 1,
              borderRadius: "0.5rem",
              display: "flex",
              alignItems: "center",
              background: theme.palette.secondary.main,
            }}
          >
            <SyncProblemIcon />
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {children ? (
            children
          ) : (
            <>
              <Typography sx={{ mb: 1 }}>
                Your browser is having issues.
              </Typography>
              <Typography>Please reload the page to continue.</Typography>
            </>
          )}
        </Box>
      </Box>
    </ModalForm>
  );
}
