import BasicButtonStyles from "@/theme/BasicButtonStyles";
import ModalForm from "@/elements/ModalForm";
import React, { useContext, useState } from "react";
import { AccountInfo } from "@/models/accountInfo";
import * as Icons from "@mui/icons-material";
import { Feed } from "@/generated/client";
import { Box, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ActionContext } from "@/models/ActionsProvider";
export default function MuteUnmuteMember({
  account,
  feed,
  closeContext,
}: {
  account: AccountInfo;
  feed: Feed;
  closeContext: () => void;
}) {
  const { muteUnmute } = useContext(ActionContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [muteMember, setMuteMember] = useState<boolean>(false);
  const [unMuteMember, setUnMuteMember] = useState<boolean>(false);
  const { normalButtonStyles, normalIconStyles } = BasicButtonStyles();

  const handleAction = async (action: string) => {
    setDisabled(() => true);
    if (action === "mute") {
      await muteUnmute(feed.workspaceId, feed.id, account.id, "mute");
    } else {
      await muteUnmute(feed.workspaceId, feed.id, account.id, "un-mute");
    }
    setDisabled(() => false);
    closeContext();
  };

  return (
    <>
      <ModalForm
        id={`${account.id}-un-mute-member`}
        open={unMuteMember}
        onClose={() => {
          setUnMuteMember(() => false);
          closeContext();
        }}
      >
        <Box>
          <Typography sx={{ fontSize: 24 }}>Unmute member</Typography>
          <Typography sx={{ fontSize: 16, mb: 2 }}>
            This member will be able to add new messages and uploads to the
            Channel.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2.5,
            }}
          >
            <Button
              disabled={disabled}
              variant="outlined"
              color="primary"
              onClick={() => closeContext()}
            >
              Cancel
            </Button>
            <Button
              disabled={disabled}
              variant="contained"
              sx={{ borderRadius: 6 }}
              onClick={() => handleAction("un-mute")}
              aria-label="Confirm Remove Member"
            >
              Unmute Member
            </Button>
          </Box>
        </Box>
      </ModalForm>

      <ModalForm
        id={`${account.id}-mute-member`}
        open={muteMember}
        onClose={() => {
          setMuteMember(() => false);
          closeContext();
        }}
      >
        <Box>
          <Typography sx={{ fontSize: 24 }}>Mute member</Typography>
          <Typography sx={{ fontSize: 16, mb: 2 }}>
            This member will no longer be able to add new messages or uploads to
            the Channel. This action is temporary and can be undone.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: 2.5,
            }}
          >
            <Button
              disabled={disabled}
              variant="outlined"
              color="primary"
              onClick={() => closeContext()}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={disabled}
              variant="contained"
              color="error"
              sx={{ borderRadius: 6 }}
              onClick={() => handleAction("mute")}
              aria-label="Confirm Remove Member"
            >
              Mute Member
            </LoadingButton>
          </Box>
        </Box>
      </ModalForm>
      {/* Mute/Unmute a member */}
      {/* {account?.isMuted ? (
        <Button
          sx={normalButtonStyles}
          onClick={() => setUnMuteMember(() => true)}
        >
          <Box sx={normalIconStyles}>
            <Icons.VolumeUp role="img" />
          </Box>
          <Box>Un-Mute this Member</Box>
        </Button>
      ) : (
        <Button
          sx={normalButtonStyles}
          onClick={() => setMuteMember(() => true)}
        >
          <Box sx={normalIconStyles}>
            <Icons.VolumeOff role="img" />
          </Box>
          <Box>Mute this Member</Box>
        </Button>
      )} */}
    </>
  );
}
