import AvatarWithStatus from "@/components/AvatarWithStatus";
import UseContextMenu from "@/components/ContextMenus/UseContextMenu";
import DriverPanel from "@/components/DriverPanel";
import CrownIcon from "@/components/Icons/Crown";
import DemoteOrPromoteMember from "@/components/MemberDrawerActions/DemoteOrPromoteMember";
import MuteUnmuteMember from "@/components/MemberDrawerActions/MuteUnmuteMember";
import RemoveMemberFromChannel from "@/components/MemberDrawerActions/RemoveMemberFromChannel";
import SendDirectMessage from "@/components/MemberDrawerActions/SendDirectMessage";
import { useElectric } from "@/electric/ElectricWrapper";
import Locator from "@/locator";
import { DataContext } from "@/models/DataProvider";
import type { AccountInfo } from "@/models/accountInfo";
import * as Icons from "@mui/icons-material";
import { Box, Collapse, ListItem, ListItemIcon, useTheme } from "@mui/material";
import { useLiveQuery } from "electric-sql/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

export default function MemberDrawerListItem({
  account,
  myAccount,
  isCurrentFeedAdmin,
  isDm,
  setDropdownState,
  isOpen,
}: {
  account: AccountInfo;
  myAccount: AccountInfo;
  isCurrentFeedAdmin: boolean;
  isDm?: boolean;
  setDropdownState?: (value: { id: string; value: boolean }) => void;
  isOpen?: boolean;
}) {
  const { isWorkspaceAdmin, isWorkspaceLimitedMember, currentFeed } =
    useContext(DataContext);
  const { handleContextMenu, handleClose, contextMenuItem } = UseContextMenu();
  const { directMessage } = useFlags();
  const theme = useTheme();
  const { db } = useElectric();
  const params = useParams();
  const { showDriverPanelIfHandsFree } = useFlags();
  const { feedId } = params;
  const activeDropdown = isOpen;
  const [killApiKey, setKillApiKey] = useState<boolean>(false);
  const isMe = account.id === myAccount?.id;
  const mine = account?.mine === 1;
  const hasWritePermissions: boolean = !!account?.permissions?.find(
    (perm) => perm?.name === "write",
  );

  const workspaceAdmin = isWorkspaceAdmin(params.workspaceId, myAccount?.id);
  const limitedMember = isWorkspaceLimitedMember(
    params.workspaceId,
    myAccount?.id,
  );

  const toggleActiveDropdown = () => {
    if (!handsFreeStatus) {
      return;
    }
    setKillApiKey(() => !activeDropdown);
    setDropdownState({ id: account.id, value: !activeDropdown });
  };

  const { results: workspaceMemberHandsFreeStatus } = useLiveQuery(
    db.ws_hands_free_status.liveFirst({
      where: {
        id: account?.workspaceMembership?.id,
      },
    }),
  );

  const handsFreeStatus = workspaceMemberHandsFreeStatus?.enabled === 1;

  const triggerDropdown =
    handsFreeStatus && showDriverPanelIfHandsFree && !limitedMember;
  const showSendDirectMessageButton =
    !limitedMember && directMessage && !mine && currentFeed?.isDm === 0;
  const showRemoveMemberFromChannelButton =
    !limitedMember && currentFeed?.isDm === 0 && isCurrentFeedAdmin;
  const showMuteUnmuteMemberButton = isCurrentFeedAdmin && hasWritePermissions;
  const showDemoteOrPromoteMemberButton = isCurrentFeedAdmin || workspaceAdmin;
  const showContextMenu =
    !currentFeed?.isDm &&
    (showSendDirectMessageButton ||
      showRemoveMemberFromChannelButton ||
      showMuteUnmuteMemberButton ||
      showDemoteOrPromoteMemberButton ||
      workspaceAdmin);

  return (
    <ListItem
      className="menu-options-list"
      aria-label={Locator.feed.members.list.item}
      data-testid={Locator.feed.members.list.itemId(
        account.email || account.phoneNumber,
      )}
      sx={{
        position: "relative",
        padding: 0,
        display: "block",
        width: "100%",
        background:
          triggerDropdown && activeDropdown ? theme.palette.secondary.main : "",
        borderRadius: "0.5rem",
        boxShadow: isMe ? `0 0 0 1px ${theme.palette.brand.primary.main}` : "",
        cursor: "context-menu",
        "&:hover": {
          background:
            ((!isDm && isCurrentFeedAdmin) || triggerDropdown) &&
            theme.palette.secondary.main,
        },
      }}
      onContextMenu={handleContextMenu}
    >
      <Box
        sx={{
          cursor: triggerDropdown ? "pointer" : "default",
          display: "flex",
          width: "100%",
          position: "relative",
          p: 1,
          alignItems: "center",
        }}
        onClick={toggleActiveDropdown}
      >
        <Box
          sx={{
            cursor: triggerDropdown ? "pointer" : "default",
            display: "flex",
            width: "100%",
            position: "relative",
            p: 1,
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "40px", mr: 1 }}>
            <ListItemIcon sx={{ position: "relative", zIndex: 1 }}>
              <AvatarWithStatus accountId={account?.id} key={account?.id} />
            </ListItemIcon>
          </Box>
          <Box>
            <Box>{account.name || account.id}</Box>
            <Box sx={{ display: "flex", alignItems: "center", color: "#ccc" }}>
              {account.isAdmin && (
                <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                  <CrownIcon
                    name="Feed Admin"
                    aria-label={Locator.feed.members.list.isAdmin}
                    fill="#ccc"
                    height={16}
                    width={16}
                  />
                </Box>
              )}
              {account.isMuted && (
                <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                  <Icons.VolumeOff
                    fontSize="small"
                    role="img"
                    aria-label={Locator.feed.members.list.isMuted}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {triggerDropdown && (
          <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
            {activeDropdown ? (
              <Icons.KeyboardArrowUp role="img" />
            ) : (
              <Icons.KeyboardArrowDown role="img" />
            )}
          </Box>
        )}
      </Box>

      {handsFreeStatus && showDriverPanelIfHandsFree && (
        <Collapse in={activeDropdown}>
          <DriverPanel killKey={killApiKey} account={account} />
        </Collapse>
      )}

      {showContextMenu && (
        <Box>
          {contextMenuItem(
            <Box>
              {showDemoteOrPromoteMemberButton && (
                <DemoteOrPromoteMember
                  feed={currentFeed}
                  account={account}
                  closeContext={() => handleClose()}
                />
              )}
              {showMuteUnmuteMemberButton && (
                <MuteUnmuteMember
                  feed={currentFeed}
                  account={account}
                  closeContext={() => handleClose()}
                />
              )}
              {showRemoveMemberFromChannelButton && (
                <RemoveMemberFromChannel
                  feed={currentFeed}
                  account={account}
                  closeContext={() => handleClose()}
                />
              )}
              {showSendDirectMessageButton && (
                <SendDirectMessage
                  feed={currentFeed}
                  account={account}
                  closeContext={() => handleClose()}
                />
              )}
            </Box>,
          )}
        </Box>
      )}
    </ListItem>
  );
}
