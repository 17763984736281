/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ActivityRequest, ActivityResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Activity<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name ActivityCreate
   * @summary activity
   * @request POST:/activity
   */
  activityCreate = (body: ActivityRequest, params: RequestParams = {}) =>
    this.request<ActivityResponse, any>({
      path: `/activity`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
