import { UxContext } from "@/models/UxStateProvider";
import { Box, Link, SxProps, Typography, useTheme } from "@mui/material";
import React, { ReactNode, useContext } from "react";
import { drawerOpenStyles } from "./SBAppBar";

export default function ContextualFooter({
  children,
  sx,
  containerSx,
}: { children: ReactNode | ReactNode[]; sx?: SxProps; containerSx?: SxProps }) {
  const theme = useTheme();
  const { isSmUp, leftNavOpen, rightNavOpen } = useContext(UxContext);
  const _drawerOpenStyles = {
    ...drawerOpenStyles({
      isSmUp,
      leftNavOpen,
      rightNavOpen,
      theme,
    }),
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          bottom: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "85px",
          zIndex: 1100,
          ..._drawerOpenStyles,
          ...containerSx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
            background: theme.palette.secondary.main,
            boxShadow: "0px 2px 8px 0px rgba(4, 7, 5, 0.25)",
            borderRadius: "16px",
            px: 3,
            py: 0.5,
            minHeight: 34,
            width: "100%",
            maxWidth: "300px",
            ...sx,
          }}
        >
          <Typography
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              fontSize: "12px",
              fontWeight: 500,
            }}
          >
            {children}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
