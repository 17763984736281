import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button/Button";
import IconButton from "@mui/material/IconButton/IconButton";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { useTheme } from "@mui/material";
import { useRef, Fragment } from "react";
import { pwaInfo } from "virtual:pwa-info";
import { useRegisterSW } from "virtual:pwa-register/react";

// eslint-disable-next-line no-console
if (pwaInfo) {
  console.log(pwaInfo);
}

export default function ReloadPrompt() {
  const swRef = useRef(true);
  const theme = useTheme();
  // replaced dynamically
  const buildDate = "__DATE__";
  // replaced dynamically
  const reloadSW = "__RELOAD_SW__";
  // 60 seconds per update on prod, 10 seconds in dev
  const intervalMS = (import.meta.env.PROD ? 90 : 10) * 1000;

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swUrl, r) {
      try {
        // eslint-disable-next-line no-console
        console.log(`Service Worker at: ${swUrl}`);
        // @ts-expect-error just ignore
        if (reloadSW === "true") {
          // eslint-disable-next-line chai-friendly/no-unused-expressions
          r &&
            setInterval(async () => {
              // eslint-disable-next-line no-console
              console.log("Checking for sw update");
              if (!(!r.installing && navigator)) return;

              if ("connection" in navigator && !navigator.onLine) return;

              if (!swRef.current) {
                r?.update();
                return;
              }

              const resp = await fetchPlus(swUrl, {
                cache: "no-store",
                headers: {
                  cache: "no-store",
                  "cache-control": "no-cache",
                },
              });

              if (resp?.ok) {
                swRef.current = false;
                await r?.update();
              } else {
                console.log(
                  "SW error: Promise resolved but HTTP status failed",
                );
              }
            }, intervalMS); /* 10s check */
        } else {
          // eslint-disable-next-line prefer-template,no-console
          console.log("SW Registered: " + r);
        }
      } catch (_) {
        console.log(`SW error: ${r}`);
      }
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log("SW registration error", error);
    },
  });

  const fetchPlus = async (url, options = {}, retries = 10) => {
    return await fetch(url, options)
      .then((res) => {
        if (res.ok) {
          return res;
        }
        if (retries > 0) {
          return fetchPlus(url, options, retries - 1);
        }
      })
      .catch((error) => console.error(error.message));
  };

  const refresh = () => {
    updateServiceWorker(true);
  };

  const close = () => {
    setNeedRefresh(false);
  };

  const action = (
    <Fragment>
      {needRefresh && (
        <Button color="inherit" size="small" onClick={() => refresh()}>
          Reload
        </Button>
      )}
      <IconButton
        aria-label="close"
        color="primary"
        sx={{ p: 0.5 }}
        onClick={() => close()}
      >
        <CloseIcon role="img" />
      </IconButton>
    </Fragment>
  );

  const message = (
    <Fragment>
      <span>New version available, click reload button to update.</span>
    </Fragment>
  );

  const messageProps = {
    sx: {
      backgroundColor: theme.palette.brand.primary.dark,
      color: theme.palette.primary.main,
    },
  };

  return (
    <Fragment>
      <Snackbar
        open={needRefresh}
        action={action}
        message={message}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ bottom: { xs: "148px" } }}
        ContentProps={messageProps}
        onClose={() => close()}
      />
    </Fragment>
  );
}
