/**
 * @TODO combine with focus outline in src/assets/index.css
 */
export const defaultFocusStyles = {
  outline: 'solid 2px #fafafa',
  outlineOffset: '2px',
  boxShadow: '0 0 0 4px #8600FF'
};

export const menuItemFocusStyles = {
  margin: '0 8px',
  boxSizing: 'border-box'
};

export const menuItemFocusSX = {
  '& .MuiMenuItem-root': {
    ...menuItemFocusStyles
  }
};
