import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "@/models/DataProvider";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { Typography } from "@mui/material";
import ModalForm from "@/elements/ModalForm";

export default function InvalidUser({ validRoles }: { validRoles: string[] }) {
  const navigate = useNavigate();
  const { db } = useElectric();
  const { myAccount } = useContext(DataContext);
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const [invalidUser, setInvalidUser] = useState<boolean>(false);

  const { results: workspaceMembership } = useLiveQuery(() => {
    if (!myAccount) return;
    return db.workspace_membership.liveFirst({
      where: {
        workspaceId: workspaceId,
        accountId: myAccount?.id,
        status: "active",
      },
    });
  }, [myAccount, workspaceId]);

  useEffect(() => {
    if (!myAccount || !workspaceMembership?.role) {
      return;
    }
    if (!validRoles.includes(workspaceMembership.role)) {
      setInvalidUser(() => true);
    } else {
      setInvalidUser(() => false);
    }
  }, [workspaceMembership?.role, myAccount?.id, validRoles.join()]);

  const closeModalAndRedirect = () => {
    navigate(`/workspaces/${workspaceId}`);
  };

  if (invalidUser) {
    return (
      <ModalForm open={invalidUser} onClose={() => closeModalAndRedirect()}>
        <div>
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 2, fontWeight: 700 }}
          >
            You do not have rights to view this content
          </Typography>

          <div>
            Please contact your Storyboard administrator for more information
          </div>
        </div>
      </ModalForm>
    );
  }

  return false;
}
