import {
	EnFlag,
	EsFlag,
	EsMXFlag,
	FrFlag,
	NoneFlag,
	PtFlag,
	ZhFlag,
	PlFlag,
	SrFlag,
} from "@/components/Icons/Flags";
import { HTMLAttributes } from "react";
export interface FlagProps extends HTMLAttributes<HTMLElement> {
	locale: string;
	title?: string;
	width: number;
}

export default function Flag(props: FlagProps) {
	const { locale /*, title, width = 30*/ } = props;

	const flags = {
		en: EnFlag,
		es: EsFlag,
		"es-MX": EsMXFlag,
		pt: PtFlag,
		"zh-TW": ZhFlag,
		fr: FrFlag,
		pl: PlFlag,
		sr: SrFlag,
		none: NoneFlag,
	};

	const FlagComp = locale in flags ? flags[locale] : flags.none;

	/**
	 * @NOTE - do not use `role="presentation" on SVG
	 *  - https://www.unimelb.edu.au/accessibility/techniques/accessible-svgs
	 */
	return <FlagComp {...props} aria-hidden="true" />;
}
