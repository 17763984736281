import { SVGProps } from "react";
export default function TrashOutlined({ ...props }: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="17"
			height="18"
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				id="Vector"
				d="M5.56006 0V1H0.560059V3H1.56006V16C1.56006 16.5304 1.77077 17.0391 2.14584 17.4142C2.52092 17.7893 3.02963 18 3.56006 18H13.5601C14.0905 18 14.5992 17.7893 14.9743 17.4142C15.3493 17.0391 15.5601 16.5304 15.5601 16V3H16.5601V1H11.5601V0H5.56006ZM3.56006 3H13.5601V16H3.56006V3ZM5.56006 5V14H7.56006V5H5.56006ZM9.56006 5V14H11.5601V5H9.56006Z"
				fill="white"
			/>
		</svg>
	);
}
