import { Electric, WorkspaceMembership } from '@/generated/client';
import { Feed } from '@prisma/client';
import { LiveResultContext } from 'electric-sql/dist/client/model/model';
import { ResultData } from 'electric-sql/dist/frameworks/react/hooks/useLiveQuery';
import { DependencyList } from 'react';

export type ChannelWithPermission = {
    id: string;
    title: string;
    workspaceId: string;
    feedType: string;
    readOnly: number;
    isPrivate: number;
    isDm: number;
    loadedFirstPage: number;
    loadedLastPage: number;
    loadedEvents: number;
    loadedPermissions: number;
    createdAt: string;
    updatedAt: string;
    latestActivity: string;
    lastOpened: string;
    permission_name: string;
    wsmid: string;
    name?: string;
    email?: string;
}

type queryType<Res> = (
  runQueryFn: () => LiveResultContext<Res>,
  dependencies: DependencyList
) => ResultData<Res>;

export function getChannelsWithPermissions<Res>(
  useLiveQuery: queryType<Res>,
  db: Electric['db'],
  { workspaceId, myMembership}: {  workspaceId: string, myMembership: WorkspaceMembership }
) {
 const { results: channelsWithPermissions, error } = useLiveQuery(() => {
    if (!workspaceId || !myMembership?.workspaceId) {
      return;
    }
    return db.liveRaw({
      sql: `
	SELECT
    feed.*,
    permission.name as permission_name,
    permission.workspace_membershipId as wsmid,
    account.name,
    account.email,
    IFNULL(feed.title, 'DM: ' || account.name) as title
	FROM
	    feed
	JOIN permission ON permission.feedId = feed.id
	JOIN workspace_membership ON workspace_membership.id = permission.workspace_membershipId
  JOIN account ON account.id = workspace_membership.accountId
	WHERE
	    feed.workspaceId = ?
				AND
      permission.name = 'write'
				AND
      permission.enabled = 1
        AND
      permission.workspace_membershipId = ?
	GROUP BY feed.id
	ORDER BY
      feed.isDm ASC,
	    feed.title ASC
			`,
      args: [workspaceId, myMembership?.id],
    });
  }, [workspaceId, myMembership]);

  return { channelsWithPermissions, error } as {
    channelsWithPermissions: ChannelWithPermission[];
    error: any;
  };
}
