import { Close, PictureAsPdfOutlined } from "@mui/icons-material";
import {
	Box,
	BoxProps,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { IconForFile } from "@/components/FileIcons";

interface FilePreviewProps extends BoxProps {
	index?: string | number;
	url: string;
	type: string;
	name: string;
	disabledRemove?: boolean;
	removeFileCallback?: Function;
}

export default function FilePreview({
	index,
	url,
	type,
	name,
	disabledRemove,
	removeFileCallback,
	...props
}: FilePreviewProps) {
	const theme = useTheme();
	const docTypes = ["application", "text"];
	const splitType = type?.split("/");
	const baseType = splitType?.[0] || "";
	const fileType = splitType?.[1] || "";
	const fileIcon = IconForFile({ mimeType: type });

	return (
		<Box
			sx={{
				position: "relative",
				display: "flex",
			}}
			{...props}
		>
			{baseType === "image" ? (
				<img
					src={url}
					style={{
						width: 64,
						height: 64,
						objectFit: "cover",
						borderRadius: "0.5rem",
					}}
					alt={`file preview of ${name}`}
				/>
			) : null}

			{docTypes.includes(baseType) ? (
				<Stack
					sx={{
						flexDirection: "row",
						width: 250,
						background: theme.palette.neutral.dark,
						borderRadius: 2,
						padding: 1,
						gap: 1,
					}}
				>
					<Box>{fileIcon}</Box>
					<Box>
						<Typography className="text-ellipsis" sx={{ fontWeight: 500 }}>
							{name}
						</Typography>
						<Typography sx={{ fontWeight: 500 }}>{fileType}</Typography>
					</Box>
				</Stack>
			) : null}

			{removeFileCallback ? (
				<IconButton
					disabled={disabledRemove}
					onClick={() => removeFileCallback(index)}
					sx={{
						position: "absolute",
						top: -10,
						right: -10,
						borderRadius: "50%",
						padding: 0.5,
						background: theme.palette.secondary.dark,
						border: `1px solid ${theme.palette.secondary.main}`,
					}}
				>
					<Close role="presentation" sx={{ fontSize: "0.875rem" }} />
				</IconButton>
			) : null}
		</Box>
	);
}
