import { Box, Typography, useTheme } from "@mui/material";

interface Step {
	active: boolean;
	step: number;
}

export type StepperProps = {
	steps: Array<Step>;
	currentActiveStep: Number;
};

export default function Stepper({ steps, currentActiveStep }: StepperProps) {
	const theme = useTheme();

	return (
		<>
			<Box
				sx={{
					display: "flex",
					mb: 1,
				}}
			>
				{steps.map((item, key) => (
					<Box
						key={`active-step-${key}`}
						sx={{
							width: "80px",
							background: item.active
								? theme.palette.brand.primary.light
								: theme.palette.secondary.main,
							height: "5px",
							marginRight: key + 1 === steps.length ? "" : "4px",
						}}
					/>
				))}
			</Box>
			<Box
				sx={{
					fontSize: "12px",
					fontWeight: "medium",
					display: "flex",
					alignItems: "center",
				}}
			>
				<Box sx={{ flexGrow: 1, pl: 1 }}>
					<Typography variant="body1" component="h1">
						{`Step ${currentActiveStep} of ${steps.length}`}
					</Typography>
				</Box>
			</Box>
		</>
	);
}
