import LoadingScreen from "@/components/LoadingScreen";
import React from "react";
import { useNavigate } from "react-router-dom";
export default function DirectAuth() {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/");
  }, [navigate]);

  return (
    <>
      <LoadingScreen />
    </>
  );
}
