import { styled } from "@mui/material";

export const DrawerHeader = styled("div", {
	shouldForwardProp: (prop?) => prop !== "position",
})<{
	position?: string;
}>(({ theme, position }) => ({
	display: "flex",
	alignItems: "center",
	position: "sticky",
	inset: "0 0 0 0",
	zIndex: 1100,
	padding: theme.spacing(0, 2),
	justifyContent: "flex-end",
	height: 80,
	background: theme.palette.primary.dark,
}));
